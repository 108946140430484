import { Stack, Typography } from '@mui/material';
import { AlertComponent } from '../alert/alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from 'react';
import { ConnectionType } from '../../connection/index';
import { useWeb3 } from 'src/hooks/useWeb3';
import { EXPLORER_BY_NETWORK, EXPLORER_BY_NETWORK_ADDRESS } from 'src/constants/explorers';

export const SuccessMessage = ({ message, hash, handleClose, name = '' }) => {
  const { chainId, walletType } = useWeb3();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(hash);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Stack sx={{ width: '100%', mb: 3 }}>
      <AlertComponent onClose={handleClose} waiting={false} severity="success">
        <Stack gap={1}>
          <Stack>
            <Typography variant="body2">{message}</Typography>
          </Stack>
          {name && hash ? (
            <>
              <Stack>
                <Typography variant="body2">Token name: {name}.</Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography variant="body2"> Address of your token: {hash}</Typography>
                <Stack>
                  {!isCopied && (
                    <ContentCopyIcon
                      onClick={copyToClipboard}
                      sx={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    />
                  )}
                  {isCopied && (
                    <DoneIcon sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                  )}
                </Stack>
              </Stack>
            </>
          ) : hash ? (
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="body2"> Hash of your transaction: {hash}</Typography>
              <Stack>
                {!isCopied && (
                  <ContentCopyIcon
                    onClick={copyToClipboard}
                    sx={{ width: '20px', height: '20px', cursor: 'pointer' }}
                  />
                )}
                {isCopied && <DoneIcon sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />}
              </Stack>
            </Stack>
          ) : null}
          {hash ? (
            <Stack>
              <Stack fontSize="15px">
                <Stack display="inline-block">
                  <Typography variant="body2">
                    {' '}
                    Check in explorer{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        name
                          ? `${EXPLORER_BY_NETWORK_ADDRESS[chainId]}${hash}${
                              walletType === ConnectionType.APTOS_PETRA ? '?network=mainnet' : ''
                            }}`
                          : `${EXPLORER_BY_NETWORK[chainId]}${hash}${
                              walletType === ConnectionType.APTOS_PETRA ? '?network=mainnet' : ''
                            }`
                      }
                      style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                      here
                    </a>
                    .
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </AlertComponent>
    </Stack>
  );
};
