import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { roles } from '../TeamTab';
import { useMemo } from 'react';

export const Row = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
  handleEditRow,
  handleCancelEditRow,
  handleSaveEditRow,
  data,
}) => {
  // const { account, chainId } = useWeb3React();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { t } = useTranslation('common');

  const initialValues = useMemo(
    () => ({
      id: row.id || null,
      name: row.name,
      second_name: row.second_name,
      email: row.email,
      role: row.role,
    }),
    [row],
  );

  const { setFieldValue, isValid, values, errors, touched, dirty, getFieldProps, resetForm } =
    useFormik({
      initialValues,
      validationSchema: Yup.object().shape({
        name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
        second_name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
        email: Yup.string()
          .email('Email must be a valid email address')
          .required('Email is required'),

        role: Yup.string().max(45, 'Maximum length 45 characters').required('Is required'),
      }),

      onSubmit: () => {},
    });

  const someItemIsEditing = data && data?.some(({ isEdit }) => isEdit);

  const handleSelectRow = (event) => !someItemIsEditing && handleClick(event, row, row.id);

  return (
    <>
      {row.isEdit ? (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{ height: '90px' }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={true}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <TextField
              required
              size="small"
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </TableCell>

          <TableCell align="left">
            <TextField
              required
              fullWidth
              size="small"
              label="Surname"
              {...getFieldProps('second_name')}
              error={Boolean(touched.second_name && errors.second_name)}
              helperText={touched.second_name && errors.second_name}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row">
            <TextField
              fullWidth
              size="small"
              label={t('Email')}
              required
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={Boolean(touched.email && errors.email) && errors.email}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" align="left">
            <FormControl sx={{ minWidth: '175px' }} size="small" fullWidth required={true}>
              <InputLabel size="small">Role</InputLabel>
              <Select
                name="role"
                label="Role"
                fullWidth
                size="small"
                value={values.role}
                onChange={(e) => {
                  setFieldValue('role', e.target.value);
                }}
              >
                {roles.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell align="left">
            <IconButton
              size="small"
              disabled={!isValid || !dirty}
              onClick={() => {
                handleSaveEditRow({ ...values }, row.isEdit, row.isNew);
                resetForm({
                  values: { ...values, id: row.id },
                  dirtyFields: false,
                });
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                handleCancelEditRow(row.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell onClick={handleSelectRow} padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={someItemIsEditing}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.second_name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.email || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">
              {roles.find((role) => role.id === row.role)?.name || '-'}
            </Typography>
          </TableCell>

          <TableCell align="left">
            <IconButton
              onClick={() => {
                handleEditRow(row.id);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
