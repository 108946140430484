import React from 'react';
import { Stack, Typography, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export const WalletInfo = ({ wallet }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField('currencyCoin');

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        Network: <br /> {wallet?.network}
      </Typography>
      <Typography variant="body2">
        Wallet address: <br /> {wallet?.wallet_address}
      </Typography>
      <Stack gap={1}>
        <Stack mt={2}>
          <Typography fontWeight={500}>Coin:</Typography>
        </Stack>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <TextField
            {...field}
            required
            name="currencyCoin"
            placeholder="Coin"
            size="small"
            onChange={({ target: { value } }) => setFieldValue('currencyCoin', value)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
