import * as yup from 'yup';
import * as Yup from 'yup';

export const validationSchema = yup.object({
  client: yup.object().nullable().required('Client is required'),
  paymentWallet: yup.object().required('Payment wallet is required'),
  customInvoiceNumber: yup.string().required('Invoice number is required'),
  currencyCoin: yup.string().required('Currency coin is required'),
  startDate: yup.date().required('Start date is required'),
  dueDate: yup
    .date()
    .required('Due date is required')
    .min(yup.ref('startDate'), 'Due date should be later than start date'),
  details: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string()
          .required('Description is required')
          .max(100, 'Maximum length 100 characters'),
        discount: Yup.number().min(0, 'Must be at least 0').max(100, 'Must be no more than 100'),
        unit_price: Yup.number()
          .min(0.0000000001, 'Must be at least 0.0000000001')
          .required('Price is required'),
        qty: Yup.number().min(0, 'Must be at least 0').required('Quantity is required'),
        tax: Yup.number().min(0, 'Must be at least 0').max(999, 'Must be no more than 999'),
        amount: Yup.string(),
        amount_with_tax: Yup.string(),
        amount_total_tax: Yup.string(),
        amount_total: Yup.string(),
      }),
    )
    .min(1)
    .max(3),
});
