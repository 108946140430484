import { WalletAbstract } from './wallet-abstract';

import store from '../redux/store';
import { updateWalletState } from 'src/redux/wallet/actions';
import { ConnectionType } from '../connection/index';
import { hexValue } from '@ethersproject/bytes';
import { getChainInfo } from '../constants/chainInfo';
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from '../constants/chains';
import { RPC_URLS } from '../constants/networks';
import { WalletFactory } from './wallet-factory.js';
import { getAprosPetraProvider, getMetamaskProvider } from '../utils/providers';

export class WalletAptosPetra extends WalletAbstract {
  constructor() {
    super();
    this.type = ConnectionType.APTOS_PETRA;
  }

  checkConnection = async () => {
    await this.connectWallet();
    return this;
  };

  connect = async () => {
    await this.connectWallet();
    return this;
  };

  // export function getHasMetaMaskExtensionInstalled(): boolean {
  //   if (typeof window !== 'undefined' && 'ethereum' in window) {
  //     return window.ethereum?.isMetaMask ?? false;
  //   } else return false;
  // }

  connectWallet = async () => {
    if (typeof window !== 'undefined' && window.aptos) {
      try {
        const petraWallet = getAprosPetraProvider();

        // console.log('petraWallet', petraWallet);

        // if (!petraWallet) return;

        const account = await petraWallet.connect();

        let aptosNetwork = '';
        // const aptosNetwork = 'APTOS_MAINNET';

        const testNetwork = await window.aptos.network();

        switch (testNetwork) {
          case 'Mainnet':
            aptosNetwork = 'APTOS_MAINNET';
            break;
          case 'Devnet':
            aptosNetwork = 'APTOS_DEVNET';
            break;
          case 'Testnet':
            aptosNetwork = 'APTOS_TESTNET';
            break;

          default:
            aptosNetwork = '';
            break;
        }

        // console.log('testNetwork', testNetwork);

        if (!account || (account && !account.address)) {
          return;
        }
        this.address = account.address;
        this.connector = window.aptos;
        // console.log('this.provider', this.provider);

        store.dispatch(
          updateWalletState({
            walletAddress: this.address,
            walletType: this.type,
            isEvm: false,
            chainId: aptosNetwork,
            connector: this.connector,
            provider: this.provider,
          }),
        );
      } catch (err) {
        this.error = err.message;
        console.log('APTOS err', err);

        this.resetWallet();
      }
    } else {
      this.error = 'Please install Petra wallet';
    }
  };

  getChainId = async () => {
    const provider = this.getProvider();
    const response = await provider.network();
    // console.log('response getNetwork APTOS', response);

    return 'APTOS_MAINNET';
  };

  getProvider = () => {
    return getAprosPetraProvider();
  };

  getBalance = async () => {
    return 0;
  };

  switchNetwork = async (chainId) => {
    if (chainId === SupportedChainId.APTOS_MAINNET) {
      if (window.aptos) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.APTOS_PETRA);
      } else {
        alert('Download Petra Wallet wallet chrome extension');
      }
      return;
    }
    if (
      chainId === SupportedChainId.TRON_NILE_TESTNET ||
      chainId === SupportedChainId.TRON_GRID_MAINNET
    ) {
      if (window.tronWeb && window.tronLink.ready) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.TRONLINK);
      } else if (window.tronWeb && !window.tronLink.ready) {
        alert('Connect Tronlink wallet');
      } else {
        alert('Download Tronlink wallet chrome extension');
      }
      return;
    }
    const hexChainId = hexValue(chainId);
    if (typeof window !== 'undefined' && getMetamaskProvider()) {
      this.resetWallet();
      WalletFactory.getWallet(ConnectionType.METAMASK);
      try {
        await getMetamaskProvider().request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902 || switchError.code === -32603) {
          try {
            await getMetamaskProvider().request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: hexChainId,
                  chainName: CHAIN_IDS_TO_NAMES[chainId],
                  nativeCurrency: getChainInfo(chainId).nativeCurrency,
                  rpcUrls: RPC_URLS[chainId],
                  blockExplorerUrls: [getChainInfo(chainId).explorer],
                },
              ],
            });
          } catch (addError) {
            console.log('addError', addError);
          }
        }
      }
    }
  };
}
