export const formatNetworks = (str) => {
  if (!str) return str;
  if (str.includes('skale')) {
    const words = str.split('_').map((word) => {
      if (word === 'skale') {
        return word.toUpperCase();
      }
      if (word === 'europa') {
        return word[0].toUpperCase() + word.slice(1);
      }
      if (word === 'hub') {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    return words.join(' ');
  }
  const words = str.split('_').map((word) => {
    return word[0].toUpperCase() + word.slice(1);
  });
  return words.join(' ');
};

export const makeShortenWalletAddress = (address = '') =>
  address.slice(0, 9) + '...' + address.slice(-4);
