import { Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { ExportFile } from 'src/helpers/ExportFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import DeleteModal from '../DeleteModal';
import { useWeb3 } from 'src/hooks/useWeb3';

export const CustomTableToolbar = ({
  numSelected,
  data,
  setTableData,
  setSelectedRows,
  deleteHandler,
  exportFile = false,
  addRowTemplate = {
    name: '',
    second_name: '',
    position: '',
    network_id: '',
    wallet_id: '',
    amount: '',
    add_info: '',
  },
}) => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const { walletAddress: account } = useWeb3();

  const closeDeleteModal = () => setIsWarningOpen((prev) => !prev);

  const dispatch = useDispatch();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        border: '1px solid #e0e0e0',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottom: 'none',
      }}
    >
      <Typography variant="body2" sx={{ flex: '1 1 100%', ml: 1 }} color="inherit" component="div">
        {numSelected} rows selected
      </Typography>

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={closeDeleteModal}>
            <DeleteIcon sx={{ width: '30px', height: '30px' }} />
          </IconButton>
        </Tooltip>
      )}

      {exportFile && <ExportFile data={data} />}
      <Tooltip arrow title="You can add one more row to the table" placement="top">
        <Button
          sx={{
            ml: 2,
            display: 'flex',
            color: !account && 'white',
            background: !account && 'rgba(145, 158, 171, 0.24)',
          }}
          disabled={!account}
          onClick={() => {
            let maxId = Math.max(...data.map((obj) => obj.id));
            setTableData([
              {
                id: data.length > 0 ? maxId + 1 : 1,
                ...addRowTemplate,
                isEdit: true,
                isNew: true,
              },
              ...data,
            ]);
          }}
        >
          Add row
        </Button>
      </Tooltip>
      <DeleteModal
        open={isWarningOpen}
        close={() => {
          closeDeleteModal();
        }}
        handler={() => {
          deleteHandler();
          setSelectedRows([]);
          closeDeleteModal();
        }}
      />
    </Toolbar>
  );
};
