import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { InvoiceTotal } from './InvoiceTotal';
import { Grid } from "@mui/material";
import { getInvoicesTotalAmount, getInvoiceAmountWithoutTaxes, getInvoiceAmount, getInvoiceTaxes } from '../helpers';

export const TotalAmounts = ({ values }) => {
  const { setFieldValue } = useFormikContext();

  const [amountWithoutTax, setAmountWithoutTax] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const newAmountWithoutTax = getInvoicesTotalAmount(values.details, getInvoiceAmountWithoutTaxes);
    const newTotalTaxAmount = getInvoicesTotalAmount(values.details, getInvoiceTaxes);
    const newTotalAmount = getInvoicesTotalAmount(values.details, getInvoiceAmount);

    setAmountWithoutTax(newAmountWithoutTax);
    setTotalTaxAmount(newTotalTaxAmount);
    setTotalAmount(newTotalAmount);

    setFieldValue('amount_with_tax', newAmountWithoutTax);
    setFieldValue('amount_total_tax', newTotalTaxAmount);
    setFieldValue('amount_total', newTotalAmount);
  }, [values, setFieldValue]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
    >
      <InvoiceTotal
        label="Amount without tax"
        amount={amountWithoutTax}
        currencyCoin={values.currencyCoin}
      />
      <InvoiceTotal
        label="Total Tax amount"
        amount={totalTaxAmount}
        currencyCoin={values.currencyCoin}
      />
      <InvoiceTotal
        label="Total amount"
        amount={totalAmount}
        currencyCoin={values.currencyCoin}
      />
    </Grid>
  );
};
