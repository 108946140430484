import React, { useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import { Stack, TextField } from '@mui/material';
import { convertToFloat } from 'src/commonHelpers/commonHelpers';

export const QtyField = ({ label, name, gridArea, formikArrayName, index, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const qty = values[formikArrayName][index].qty;
  const unit_price = values[formikArrayName][index].unit_price;

  useEffect(() => {
    const net_amount = qty * unit_price;
    setFieldValue(`${formikArrayName}.${index}.amount`, convertToFloat(net_amount));
  }, [qty, unit_price, setFieldValue]);

  const formattedAmount = convertToFloat(qty * unit_price).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 10,
  });

  return (
    <Stack gridArea={gridArea}>
      <TextField
        size="small"
        label={label}
        name={name}
        fullWidth
        value={formattedAmount}
        disabled={disabled}
      />
    </Stack>
  );
};
