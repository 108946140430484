import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import { useDispatch } from 'react-redux';
import DeleteModal from 'src/components/DeleteModal';

// ----------------------------------------------------------------------

export default function MoreMenu({ id, user }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const closeDeleteModal = () => setIsWarningOpen((prev) => !prev);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={closeDeleteModal} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem
          component={RouterLink}
          to={`/application/customers/${id}/edit`}
          sx={{ color: 'text.secondary' }}
          onClick={() => localStorage.setItem('currentEmployee', JSON.stringify(user))}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
      <DeleteModal
        open={isWarningOpen}
        close={() => {
          setIsOpen(false);
          closeDeleteModal();
        }}
        handler={() => {
          dispatch({ type: 'DELETE_CLIENT_SAGA', id: id, org_id: currentUser.organization_id });
          setIsOpen(false);
          closeDeleteModal();
        }}
      />
    </>
  );
}
