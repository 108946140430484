// ----------------------------------------------------------------------

import { useMediaQuery } from '@mui/material';

export default function Table() {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: '#e0e0e0 !important',
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #e0e0e0',
          },
          '& .MuiDataGrid-toolbarContainer': {
            borderBottom: '1px solid #e0e0e0',
            padding: '10px 20px',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:hover': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:hover': {
            outline: 'none',
          },
        },
        row: {
          borderBottom: '1px solid #e0e0e0',
        },
        cell: {
          fontSize: isMobile ? 10 : 14,
          borderColor: '#e0e0e0',
        },
        columnHeaderTitle: {
          fontSize: isMobile ? 10 : 14,
          borderColor: '#e0e0e0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // border: 'none',
          // borderTop: '1px solid #e0e0e0',
          borderBottom: '1px solid #e0e0e0',
          fontSize: isMobile ? 10 : 14,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: '1px solid #e0e0e0',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'rgb(0, 172, 172)',
            color: 'white',
          },
          '&.Mui-selected': {
            background: '#007994',
            color: 'white',
            '&:hover': {
              color: 'white',
              background: 'rgb(0, 172, 172)',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
