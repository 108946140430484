import { Box, Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  maxWidth: '440px',
  width: '100%',
  p: 2,
};

const initialValues = {
  name: '',
  email: '',
  phone: '+380',
  address: '',
  tax_number: '',
  registration_number: '',
  contact_person: '',
  description: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  phone: Yup.string().test(
    'isValid',
    'Phone is not valid',
    (val) => val === '+' || val === '+380' || isValidPhoneNumber(val),
  ),
  address: Yup.string().max(45, 'Maximum length 45 characters'),
  tax_number: Yup.number('Must be a number').typeError('Must be a number'),
  registration_number: Yup.number('Must be a number').typeError('Must be a number'),
  contact_person: Yup.string().max(45, 'Maximum length 45 characters').required('Is required'),
  add_info: Yup.string().max(45, 'Maximum length 45 characters'),
});

export default function ClientModal({ open, close }) {
  const dispatch = useDispatch();
  const handleClose = () => close();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const { touched, errors, handleSubmit, getFieldProps, handleChange, values, isValid, dirty } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        handleClose();

        const payload = { ...values };
        payload.phone = payload.phone === '+' || payload.phone === '+380' ? '' : payload.phone;
        payload.description = payload.add_info ? payload.add_info : '';

        dispatch({
          type: 'ADD_CLIENT_SAGA',
          payload,
          flag: 'invoice',
          org_id: currentUser.organization_id,
        });
      },
    });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2} style={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <Stack mb={2}>
              <Typography variant="subtitle1">Who are you sending this invoice to?</Typography>
            </Stack>
            <Stack gap={2}>
              <TextField
                fullWidth
                size="small"
                label="Company name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                required
              />
              <MuiPhoneNumber
                size="small"
                fullWidth
                variant="outlined"
                label="Phone Number"
                defaultCountry="ua"
                name="phone"
                value={values.phone}
                onChange={handleChange('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
              <TextField
                size="small"
                fullWidth
                label="Address"
                name="address"
                {...getFieldProps('address')}
              />
              <TextField
                fullWidth
                size="small"
                label="Tax number"
                {...getFieldProps('tax_number')}
                error={Boolean(touched.tax_number && errors.tax_number)}
                helperText={touched.tax_number && errors.tax_number}
              />
              <TextField
                fullWidth
                size="small"
                label="Registration number"
                {...getFieldProps('registration_number')}
                error={Boolean(touched.registration_number && errors.registration_number)}
                helperText={touched.registration_number && errors.registration_number}
              />
              <TextField
                fullWidth
                size="small"
                label="Contact person"
                {...getFieldProps('contact_person')}
                error={Boolean(touched.registration_number && errors.registration_number)}
                helperText={touched.registration_number && errors.registration_number}
                required
              />
              <TextField
                size="small"
                fullWidth
                label="Email"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
                required
              />
            </Stack>
          </Grid>
        </Grid>
        <Button
          sx={{ marginTop: '10px' }}
          disabled={!(isValid && dirty)}
          variant="contained"
          onClick={handleSubmit}
        >
          Save customer
        </Button>
      </Box>
    </Modal>
  );
}
