import React from 'react';
import { connect, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik, Form, FormikProvider } from 'formik';

import { gapi } from 'gapi-script';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import GoogleIcon from '../../../assets/sign-in/google.svg';
import PoligonIdLogo from '../../../assets/sign-in/polygon-id-logo.svg';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.css';

// material
import {
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import QRCode from 'qrcode.react';
// component
import {
  loginUser,
  googleLoginAction,
  getSignInPolygonIdRequest,
  loginUserSuccess,
  loginUserPolygonId,
  getAuthStatusPolygonIdRequest,
  getSignInPolygonIdSuccess,
} from '../../../redux/actions';
import { GoogleLogin } from 'react-google-login';

import { HidePassIcon } from 'src/assets/sign-in/hide-pass';
import { ViewHidePassIcon } from 'src/assets/sign-in/view-hide';
import {
  selectPolygonIdAuthReq,
  polygonIdStatus,
  selectPolygonIdIsSuccess,
} from '../../../redux/auth/reducer';
import { delay } from 'lodash';
import PolygonIdDialog from './components/PolygonIdDialog';

const useStyles = makeStyles({
  // dialogContent: {
  //     width: '256px',
  //     backgroundColor: 'rgba(255, 242, 242, 0.13)',
  //     borderRadius: '24px',
  //     backdropFilter: 'blur(10px)',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     padding: '36px',
  // },
  // border: {
  //     padding: '15px',
  //     border: '2px solid black',
  //     borderRadius: '12px',
  //     width: '256px',
  //     boxSizing: 'content-box',
  // },
});

const GOOGLE_CLIENT_ID = '405150766512-pl33ad95bs7uqe9urolbaojgosticsae.apps.googleusercontent.com';

// ----------------------------------------------------------------------

function LoginForm({ loginUser, loginUserPolygonId }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const polygonIdAuthReq = useSelector(selectPolygonIdAuthReq);
  const polygonIdIsSuccess = useSelector(selectPolygonIdIsSuccess);
  const polygonIdStatusReq = useSelector(polygonIdStatus);

  const [isExploding, setIsExploding] = useState(false);
  const [openQRPol, setOpenQRPol] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: '',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  useEffect(() => {
    if (!polygonIdStatusReq.isAuth) return;
    setIsExploding(true);
    setOpenQRPol(false);
    if (polygonIdStatusReq.onboard) {
      loginUserPolygonId({ sessionId: polygonIdStatusReq.authData.sessionId, navigate });
    } else {
      // navigate(`/application/auth/${polygonIdStatusReq.identifier}`);
    }
  }, [polygonIdStatusReq]);

  useEffect(() => {
    if (!polygonIdAuthReq?.sessionId) return;
    const interval = setInterval(() => {
      dispatch(getAuthStatusPolygonIdRequest({ sessionId: polygonIdAuthReq.sessionId }));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, polygonIdAuthReq]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleClickOpenQR = () => {
    dispatch(getSignInPolygonIdRequest());
    setOpenQRPol(true);
  };

  const handleCloseQrCode = () => {
    setOpenQRPol(false);
    dispatch(getSignInPolygonIdSuccess(null));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      loginUser(value, navigate);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const onGoogleLoginSuccess = async (responce) => {
    if (responce.tokenId) {
      dispatch(googleLoginAction(responce.tokenId, navigate));
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack
      width="100%"
      sx={{
        fontFamily: 'MullerRegular',
      }}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack mb={2}>
            <FormControl variant="standard" error={touched.email && errors.email}>
              <InputLabel
                htmlFor="standard-adornment-email"
                sx={{
                  color: 'rgba(255, 255, 255, 0.49)',
                  fontSize: '12px !important',
                  fontWeight: 400,
                  fontFamily: 'MullerRegular',

                  '&.Mui-focused': { color: 'white' },
                }}
              >
                Email
              </InputLabel>
              <Input
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                id="standard-adornment-email"
                type="email"
                inputProps={{
                  autoComplete: 'off',
                }}
                sx={{
                  label: {
                    color: 'rgba(255, 255, 255, 0.49)',
                    fontSize: '12px !important',
                    fontWeight: 400,
                    fontFamily: 'MullerRegular',

                    '&.Mui-focused': { color: 'white' },
                  },
                  input: {
                    color: 'white',
                    fontSize: '12px !important',
                    fontFamily: 'MullerRegular',
                  },
                }}
                variant="standard"
              />
            </FormControl>
          </Stack>
          <Stack sx={{ marginBottom: '18px' }}>
            <FormControl variant="standard" error={touched.password && errors.password}>
              <InputLabel
                htmlFor="standard-adornment-password"
                sx={{
                  color: 'rgba(255, 255, 255, 0.49)',
                  fontSize: '12px !important',
                  fontWeight: 400,
                  fontFamily: 'MullerRegular',

                  '&.Mui-focused': { color: 'white' },
                }}
              >
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{
                  shrink: true,
                }}
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                sx={{
                  label: {
                    color: 'rgba(255, 255, 255, 0.49)',
                    fontSize: '12px !important',
                    fontWeight: 400,
                    fontFamily: 'MullerRegular',

                    '&.Mui-focused': { color: 'white' },
                  },
                  input: {
                    color: 'white',
                    fontSize: '12px !important',
                    fontFamily: 'MullerRegular',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Stack gap={1} justifyContent="center">
                      <a
                        href="/application/forgot"
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography
                          fontSize="9px !important"
                          fontWeight="400"
                          sx={{
                            fontFamily: 'MullerRegular',
                          }}
                          color="rgba(255, 255, 255, 0.49)"
                        >
                          Reset Password
                        </Typography>
                      </a>
                    </Stack>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <ViewHidePassIcon /> : <HidePassIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Button
                type="submit"
                sx={{
                  height: '26px',
                  border: '0.729947px solid #FFFFFF',
                  borderRadius: ' 37.2273px',
                  background: 'transparent',
                  padding: '6.5px 33.8476px',
                  fontSize: '10px !important',
                  fontFamily: 'MullerRegular',
                  width: { xs: '125px', md: '143px' },
                }}
              >
                Sign In
              </Button>

              <Button
                onClick={handleClickOpenQR}
                sx={{
                  height: '26px',
                  minWidth: '26px',
                  width: '26px',
                  border: '0.729947px solid  #FFFFFF',
                  borderRadius: '37.2273px',
                  background: 'transparent',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <img
                  src={PoligonIdLogo}
                  style={{
                    width: 12,
                    display: { xs: 'none', md: 'flex' },
                  }}
                />
              </Button>
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    sx={{
                      height: '26px',
                      minWidth: '26px',
                      width: '26px',
                      border: '0.729947px solid  #FFFFFF',
                      borderRadius: '37.2273px',
                      background: 'transparent',
                    }}
                  >
                    <img src={GoogleIcon} />
                  </Button>
                )}
                onSuccess={onGoogleLoginSuccess}
                onFailure={(err) => console.log(err)}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                buttonText={'Login with Google'}
                accessType={'offline'}
                autoLoad={false}
                prompt="consent"
              />
            </Stack>
            <Stack gap={1} textAlign="right" className="have_account">
              <Typography
                sx={{ fontFamily: 'MullerRegular' }}
                fontSize="9px !important"
                fontWeight="400"
                color="rgba(255, 255, 255, 0.49)"
              >
                Don`t you have an account? Sign up.
              </Typography>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
      <PolygonIdDialog
        polygonIdIsSuccess={polygonIdIsSuccess}
        open={openQRPol}
        handleClose={handleCloseQrCode}
        polygonIdAuthReq={polygonIdAuthReq}
      />
    </Stack>
  );
}

const mapStateToProps = (state) => ({ state });
const mapActionsToProps = { loginUser, loginUserPolygonId };

export default connect(mapStateToProps, mapActionsToProps)(LoginForm);
