import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/Table/Table';
import { Row } from './Row';

const headCells = [
  {
    id: 'wallet_name',
    numeric: false,
    disablePadding: false,
    label: 'Wallet Name',
  },
  {
    id: 'network',
    numeric: false,
    disablePadding: false,
    label: 'Network',
  },
  {
    id: 'wallet_address',
    numeric: false,
    disablePadding: false,
    label: 'Wallet address',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

export const WalletsTab = ({ open, handleCloseModal }) => {
  const walletList = useSelector(({ invoices: { wallets } }) => wallets);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(walletList);
  }, [walletList]);

  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const addRowTemplate = {
    wallet_name: '',
    network: '',
    wallet_address: '',
  };

  const handleSaveEditRow = (values, isEdit, isNew) => {
    if (isEdit && !isNew) {
      const currentItem = tableData.find(({ id }) => id === values.id);
      const result = tableData.filter(({ id }) => id !== currentItem.id);
      dispatch({
        type: 'EDIT_GENERAL_WALLET',
        payload: {
          id: currentItem?.id,
          network: values.network,
          wallet_address: values.wallet_address,
          wallet_name: values.wallet_name,
        },
        org_id: currentUser.organization_id,
      });
      setTableData(() => [...result, { ...currentItem, isEdit: false }]);
      return;
    }
    dispatch({
      type: 'ADD_GENERAL_WALLET',
      payload: {
        network: values.network,
        wallet_address: values.wallet_address,
        wallet_name: values.wallet_name,
      },
      org_id: currentUser.organization_id,
    });
  };

  const deleteDispatchRecipient = () =>
    dispatch({
      type: 'DELETE_GENERAL_WALLET',
      payload: { id: selectedRows.map(({ id }) => id) },
      org_id: currentUser.organization_id,
    });

  return (
    <Stack mt={1}>
      <EnhancedTable
        setTableData={setTableData}
        addRowTemplate={addRowTemplate}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        page={page}
        setPage={setPage}
        headCells={headCells}
        data={tableData}
        handleSaveEditRow={handleSaveEditRow}
        deleteHandler={deleteDispatchRecipient}
        exportFile={false}
        emptyStatusText="You haven't created a wallet yet."
        RowComponent={Row}
      />
    </Stack>
  );
};
