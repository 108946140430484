import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreMenuEmployees from '../pages/Employees/components/MoreMenu';
import MoreMenu from 'src/pages/Clients/components/MoreMenu';

export const ListComponent = ({ items, isEmployee, handleOpen, isPartner }) => {
  return (
    <Stack sx={{ width: '100%' }}>
      <List component="nav" aria-label="secondary mailbox folder">
        {items.length > 0 &&
          isEmployee &&
          items.map((item) => (
            <React.Fragment key={item?.id}>
              <ListItemButton>
                <ListItemText
                  primary={
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Stack flex={1}>
                        <Link
                          style={{ color: 'black', textDecoration: 'none' }}
                          to={`/application/recipients/${item?.id}/profile`}
                        >
                          <Stack>
                            <Stack>
                              <Typography variant="body2">
                                {item?.name} {item?.second_name}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography variant="body2">{item?.position} </Typography>
                            </Stack>
                          </Stack>
                        </Link>
                      </Stack>
                      <Stack>
                        <MoreMenuEmployees
                          handleOpen={handleOpen}
                          id={item?.id}
                          user={item}
                          isEmployee={isEmployee}
                          isPartner={isPartner}
                        />
                      </Stack>
                    </Stack>
                  }
                />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          ))}
        {items.length > 0 &&
          isPartner &&
          items.map((item) => (
            <React.Fragment key={item?.id}>
              <ListItemButton key={item?.id}>
                <ListItemText
                  primary={
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Link
                        style={{ color: 'black', textDecoration: 'none', flex: 1 }}
                        to={`/application/customers/${item?.id}/profile`}
                      >
                        <Stack>
                          <Stack>
                            <Stack>
                              <Typography variant="body2">{item?.name}</Typography>
                            </Stack>
                            <Stack>
                              <Typography variant="body2">{item?.position} </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Link>
                      <Stack>
                        <MoreMenu id={item?.id} user={item} />
                      </Stack>
                    </Stack>
                  }
                />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </Stack>
  );
};
