import React from 'react';
import PageWrapper from 'src/components/PageWrapper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Stack,
  Badge,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const mockData = {
  headers: [
    'Maximum Number of Employees',
    'Customer Support',
    'Functionality',
    'Limitations',
    'Data Retention',
    'Customizable options to meet your specific needs',
  ],
  plans: [
    {
      title: 'Free',
      duration: '/Lifetime',
      button: 'Choose Free Plan',
      details: [
        '10 Users',
        'Email support',
        '- Sending employee salaries in different currencies<br />- Basic tax deductions<br />- Basic analytics',
        '- No access to advanced settings<br />- No personalization',
        'Data retention for up to 12 months',
        '',
      ],
    },
    {
      title: '$10',
      duration: '/User',
      button: 'Choose Vip Plan',
      details: [
        'Up to 100 Users',
        'Email and phone support',
        '- All features from the Free plan<br />- Advanced tax deductions<br />- Additional currencies',
        'Some advanced analytical features might be restricted',
        'Data retention for up to 24 months',
        '',
      ],
    },
    {
      title: '$70',
      duration: '/Month',
      button: 'Contact Us for Premium Plan',
      details: [
        'Unlimited Users Add-ons on Demand',
        'Email, phone, 24/7 support',
        '- All features from the VIP plan<br />- Personalized analytics<br />- API integration with client systems',
        'Some advanced features might require additional setup and training',
        'Data retention for unlimited time',
        'icon-check',
      ],
    },
  ],
};

export const SubscriptionPage = () => {
  return (
    <PageWrapper title="Subscription">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                  width: '25%',
                  boxSizing: 'border-box',
                }}
              >
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography variant="h4">Compare plans</Typography>
                    <Button
                      disabled={true}
                      variant="outlined"
                      sx={{ borderRadius: 10, color: '#252430!important' }}
                    >
                      40% Off
                    </Button>
                  </Stack>
                  <Typography variant="body2" sx={{ color: '#858BA0' }}>
                    Choose your workspace plan according to your organisational plan
                  </Typography>
                </Stack>
              </TableCell>
              {mockData.plans.map((plan, index) => (
                <TableCell
                  key={index}
                  sx={{
                    width: '25%',
                    boxSizing: 'border-box',
                    borderRight:
                      index !== mockData.plans.length - 1
                        ? (theme) => `1px solid ${theme.palette.divider}`
                        : null,
                  }}
                >
                  <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="baseline"
                      spacing={1}
                    >
                      <Typography variant="h2">{plan.title}</Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: '#858BA0', fontWeight: 'normal' }}
                      >
                        {plan.duration}
                      </Typography>
                    </Stack>
                    <Button variant="contained" color="primary">
                      {plan.button}
                    </Button>
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mockData.headers.map((header, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell
                  sx={{
                    borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                    width: '25%',
                    boxSizing: 'border-box',
                  }}
                >
                  {header}
                </TableCell>
                {mockData.plans.map((plan, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      width: '25%',
                      boxSizing: 'border-box',
                      borderRight:
                        colIndex !== mockData.plans.length - 1
                          ? (theme) => `1px solid ${theme.palette.divider}`
                          : null,
                    }}
                  >
                    <Stack direction="column" alignItems="center">
                      {plan.details[rowIndex] === 'icon-check' ? (
                        <CheckCircleOutlineIcon color="primary" />
                      ) : (
                        <span dangerouslySetInnerHTML={{ __html: plan.details[rowIndex] || '' }} />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  );
};
