import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getReferralCode, setReferralCode } from 'src/redux/referral/actions';
import copy from 'clipboard-copy';
import { Done } from '@mui/icons-material';

export const Participate = () => {
  const [isCopied, setIsCopied] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentUserData = localStorage.getItem('currentUser');

    if (currentUserData) {
      const currentUser = JSON.parse(currentUserData);
      if (currentUser.referralCode) {
        dispatch(setReferralCode(currentUser.referralCode));
      }
    }
  }, [dispatch]);

  const { referralCode } = useSelector((state) => state.referral);

  const handleClickGet = () => {
    dispatch(getReferralCode());
  };

  const handleCopy = () => {
    copy(referralCode);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Stack spacing={1}>
      {referralCode ? (
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Your Referral Code
          </Typography>
          <Typography variant="body2" pb={1}>
            You can use the referral code below to invite others to join our program.
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              label="Your referral code"
              value={referralCode}
              variant="outlined"
              disabled
            />
            {!isCopied && (
              <IconButton onClick={handleCopy}>
                <FileCopyIcon />
              </IconButton>
            )}
            {isCopied && (
              <IconButton>
                <Done sx={{ cursor: 'pointer' }} />
              </IconButton>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Participate in our Referral Program
          </Typography>
          <Typography variant="body2" pb={1}>
            Click the button below to get your referral code.
          </Typography>
          <div style={{ display: 'inline-block' }}>
            <Button variant="contained" onClick={handleClickGet}>
              Get Referral Code
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
};
