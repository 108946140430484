import React, { useEffect } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

export const SectionRight = ({ invoiceNumber, children }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('customInvoiceNumber', invoiceNumber);
  }, [invoiceNumber, setFieldValue]);

  return (
    <Stack p={{ xs: 1, md: 3 }} spacing={3} alignItems="flex-end">
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography variant="body2">Invoice №</Typography>
        <TextField
          size="small"
          onChange={({ target: { value } }) => setFieldValue('customInvoiceNumber', value)}
          value={values.customInvoiceNumber || ''}
        />
      </Stack>
      {children}
    </Stack>
  );
};
