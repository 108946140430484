import { Stack, Typography, Grid, Tabs, Tab } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import styles from './login.module.scss';
import WalletConnect from '../assets/sign-in/networks/wallet_connect.svg';
import Metamask from '../assets/sign-in/networks/metamask.svg';
import Tron from '../assets/sign-in/networks/tron.png';
import UsersIcon from '../assets/sign-in/capp/users.svg';
import DocumentIcon from '../assets/sign-in/capp/document.svg';
import Logo from '../assets/sign-in/sign-in-logo-white.svg';
import { useState } from 'react';
import { LoginForm } from 'src/sections/authentication/login';
import { RegisterForm } from 'src/sections/authentication/register';
import { Onboard } from 'src/sections/authentication/onboard';
import { useSelector } from 'react-redux';
import TextComponent from 'src/components/collapsed-text/collapsedText';

function Login() {
  const [value, setValue] = useState(0);
  const auth = useSelector(({ AuthUser: { isAuth } }) => {
    return isAuth;
  });
  const localStorageAuth = localStorage.getItem('authorization_login');

  const { identity } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navbarItems = [
    {
      name: 'Features',
      link: 'https://coinsender.io/#features',
    },
    {
      name: 'How it works',
      link: 'https://coinsender.io/#how-it-works',
    },
    {
      name: 'Benefits',
      link: 'https://coinsender.io/#benefits',
    },
    {
      name: 'Roadmap',
      link: 'https://coinsender.io/#roadmap',
    },
    {
      name: 'GitBook',
      link: 'https://coinsender.gitbook.io/support',
    },
    {
      name: 'FAQ',
      link: 'https://coinsender.io/faq',
    },
    {
      name: 'Main',
      link: 'https://coinsender.io/',
    },
  ];

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__logo}>
          <a href="https://coinsender.io/">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <div className={`${styles.header__items} ${isMenuOpen ? styles.header__items_open : ''}`}>
          <ul>
            {navbarItems.map(({ name, link }) => (
              <li key={name} className={styles.item}>
                <a href={link} className={styles.itemMenu}>
                  {name}
                  {/*<Typography variant="body2">{name}</Typography>*/}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Stack
          sx={{ width: '1px', height: '37.8px', display: { xs: 'none', sm: 'block' } }}
        ></Stack>
        <div
          className={`${styles.burgerMenu} ${isMenuOpen ? styles.burgerMenu_open : ''}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.title}>
              Welcome to <span style={{ color: '#04f2f2' }}>CoinSender</span>
            </div>
            <Grid
              container
              spacing={{ xs: 2, md: 4, lg: 8 }}
              borderRadius={3}
              className={styles.common}
            >
              <Grid item lg={6} cointainer sx={{ flex: 1 }}>
                <Grid item className={styles.left}>
                  <Stack
                    sx={{ height: '100%' }}
                    className={styles.left__networks}
                    justifyContent="center"
                  >
                    <Stack className={styles.left__title}>
                      Go to Decentralized application
                    </Stack>
                    <Stack className={styles.left__cards}>
                      <a
                        href="https://dapp.coinsender.io/"
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Stack className={styles.left__card}>
                          <Stack>
                            <img src={Metamask} alt="Metamask Logo" />
                          </Stack>
                          <Stack className={styles.left__card_title}>
                            Metamask
                          </Stack>
                        </Stack>
                      </a>
                      <a href="https://dapp.coinsender.io/" style={{ textDecoration: 'none' }}>
                        <Stack className={styles.left__card}>
                          <Stack>
                            <img src={WalletConnect} alt="Wallet Connect Logo" />
                          </Stack>
                          <Stack className={styles.left__card_title}>
                            WalletConnect
                          </Stack>
                        </Stack>
                      </a>

                      <a
                        href="https://dapp.coinsender.io/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <Stack className={styles.left__card}>
                          <Stack>
                            <img src={Tron} alt="TronLink Logo" />
                          </Stack>
                          <Stack className={styles.left__card_title}>
                            TronLink
                          </Stack>
                        </Stack>
                      </a>
                    </Stack>
                  </Stack>
                </Grid>
                <TextComponent
                  type="DAPP"
                  text="DAPP, on the other hand, finds its stronghold among Web3 startups, blockchain systems, gaming guilds, DAOs, projects conducting airdrops, and blockchain enthusiasts. It offers features such as wallet authorization, batch transfers of tokens and NFTs across 30+ networks, scheduled and recurring payments, payment dispatch to email addresses, no-code token and NFT creation tools, test token faucets, and reward-based quests."
                />
              </Grid>
              <Grid item lg={6} cointainer sx={{ flex: 1 }}>
                {!localStorageAuth && !identity && (
                  <Grid item className={`${styles.right} ${isMenuOpen ? styles.right_open : ''}`}>
                    <div style={{ height: '100%' }} className={styles.right__content}>
                      <Stack color="white" className={styles.right__title} textAlign="center">
                        Go to Centralized application
                      </Stack>
                      <Tabs
                        sx={{
                          borderRadius: '31px',
                          border: ' 1px solid white',
                          padding: '3px',
                          minHeight: '25px',
                          marginBottom: { xs: '16px', md: '24px' },
                          '.MuiTabs-indicator': { display: 'none' },
                          fontFamily: 'MullerRegular',
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                            background: 'transparent',
                            color: 'rgba(255, 255, 255, 0.49)',
                            textTransform: 'capitalize',
                            minHeight: '33px',
                            height: '33px',
                            width: '62px',
                            fontFamily: 'MullerRegular',

                            '&.Mui-selected': {
                              borderRadius: '31px',
                              fontWeight: 400,
                              background: 'white',
                              color: 'black',
                            },
                          }}
                          label="Sign In"
                        />
                        <Tab
                          label="Sign Up"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                            background: 'transparent',
                            color: 'rgba(255, 255, 255, 0.49)',
                            textTransform: 'capitalize',
                            minHeight: '33px',
                            height: '33px',
                            width: '62px',
                            fontFamily: 'MullerRegular',

                            '&.Mui-selected': {
                              borderRadius: '31px',
                              fontWeight: 400,
                              background: 'white',
                              color: 'black',
                            },
                          }}
                        />
                      </Tabs>
                      {value === 0 && <LoginForm />}
                      {value === 1 && <RegisterForm setValue={setValue} />}
                    </div>
                  </Grid>
                )}
                {!localStorageAuth && identity && (
                  <Grid item className={`${styles.right} ${isMenuOpen ? styles.right_open : ''}`}>
                    <div style={{ height: '100%' }} className={styles.right__content}>
                      <Stack marginBottom="24px" color="white" fontSize="16px" textAlign="center">
                        Go to Centralized application
                      </Stack>
                      <Onboard identity={identity} />
                    </div>
                  </Grid>
                )}
                {localStorageAuth && (
                  <Grid item className={styles.left}>
                    <Stack
                      sx={{ height: '100%' }}
                      className={styles.left__networks}
                      justifyContent="center"
                    >
                      <Stack>
                        <Typography className={styles.left__title}>
                          Go to Centralized application
                        </Typography>
                      </Stack>
                      <Stack className={styles.left__cards}>
                        <a href="/application/invoice" style={{ textDecoration: 'none' }}>
                          <Stack className={styles.left__card}>
                            <Stack>
                              <img src={DocumentIcon} alt="Wallet Connect Logo" />
                            </Stack>
                            <Stack>
                              <Typography className={styles.left__card_title}>Invoices</Typography>
                            </Stack>
                          </Stack>
                        </a>
                        <a href="/application/invoice" style={{ textDecoration: 'none' }}>
                          <Stack className={styles.left__card}>
                            <Stack>
                              <img src={Metamask} alt="Metamask Logo" />
                            </Stack>
                            <Stack>
                              <Typography className={styles.left__card_title}>Metamask</Typography>
                            </Stack>
                          </Stack>
                        </a>
                        <a href="/application/invoice" style={{ textDecoration: 'none' }}>
                          <Stack className={styles.left__card}>
                            <Stack>
                              <img src={UsersIcon} alt="Ssers Icon" />
                            </Stack>
                            <Stack>
                              <Typography className={styles.left__card_title}>Employees</Typography>
                            </Stack>
                          </Stack>
                        </a>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
                <TextComponent
                  type="CAPP"
                  text="CAPP is predominantly favored within the enterprise sector, serving companies with rigorous accounting responsibilities and multifaceted roles such as accountants and managers. It provides functionalities including, but not limited to, databases for employees and customers, employee access level controls, and batch payments processing."
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login;
