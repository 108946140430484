import { Stack, Typography } from '@mui/material';
import Page from 'src/components/Page';
import { PageLayout } from 'src/layouts/PagesLayout';

export const InvoiceSettings = () => {
  return (
    <Page title="Dashboard | CoinSender">
      <PageLayout>
        <Stack justifyContent="center" alignItems="center" height="50vh">
          <Stack>
            <Typography variant="body1">Coming Soon</Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" textAlign="center">
              We are working on creating something amazing. It will be ready soon!
            </Typography>
          </Stack>
        </Stack>
      </PageLayout>
    </Page>
  );
};
