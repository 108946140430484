// ----------------------------------------------------------------------

import { useMediaQuery } from '@mui/material';

export default function Tabs(theme) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: theme.palette.primary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: isMobile ? 10 : 14,
          minHeight: isMobile ? 32 : 72,
          padding: isMobile ? '8px 16px' : '12px 16px',
          fontWeight: 500,
          color: '#637381',
          '&.Mui-selected': {
            color: '#007994',
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
    },
  };
}
