import { deepCopy } from '@ethersproject/properties';
import { StaticJsonRpcProvider } from '@ethersproject/providers';

import { CHAIN_IDS_TO_NAMES, SupportedEvmChainId } from './chains';
import { RPC_URLS } from './networks';

export const POLLING_INTERVAL = 12000;

function isPlainObject(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(obj);
  return prototype === null || prototype === Object.prototype;
}

export class AppJsonRpcProvider extends StaticJsonRpcProvider {
  _blockCache = new Map();
  get blockCache() {
    // If the blockCache has not yet been initialized this block, do so by
    // setting a listener to clear it on the next block.
    if (!this._blockCache.size) {
      this.once('block', () => this._blockCache.clear());
    }
    return this._blockCache;
  }

  constructor(chainId) {
    // Including networkish allows ethers to skip the initial detectNetwork call.
    super(RPC_URLS[chainId][0], /* networkish= */ { chainId, name: CHAIN_IDS_TO_NAMES[chainId] });

    // NB: Third-party providers (eg MetaMask) will have their own polling intervals,
    // which should be left as-is to allow operations (eg transaction confirmation) to resolve faster.
    // Network providers (eg AppJsonRpcProvider) need to update less frequently to be considered responsive.
    this.pollingInterval = POLLING_INTERVAL;
  }

  send(method, params) {
    // Only cache eth_call's.
    if (method !== 'eth_call') return super.send(method, params);

    // Only cache if params are serializable.
    if (!isPlainObject(params)) return super.send(method, params);

    const key = `call:${JSON.stringify(params)}`;
    const cached = this.blockCache.get(key);
    if (cached) {
      this.emit('debug', {
        action: 'request',
        request: deepCopy({ method, params, id: 'cache' }),
        provider: this,
      });
      return cached;
    }

    const result = super.send(method, params);
    this.blockCache.set(key, result);
    return result;
  }
}

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS = {
  [SupportedEvmChainId.MAINNET]: new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
  [SupportedEvmChainId.OPTIMISM]: new AppJsonRpcProvider(SupportedEvmChainId.OPTIMISM),
  // [SupportedEvmChainId.OPTIMISM_GOERLI]: new AppJsonRpcProvider(SupportedEvmChainId.OPTIMISM_GOERLI),
  [SupportedEvmChainId.ARBITRUM_ONE]: new AppJsonRpcProvider(SupportedEvmChainId.ARBITRUM_ONE),
  // [SupportedEvmChainId.ARBITRUM_RINKEBY]: new AppJsonRpcProvider(SupportedEvmChainId.ARBITRUM_RINKEBY),
  [SupportedEvmChainId.POLYGON]: new AppJsonRpcProvider(SupportedEvmChainId.POLYGON),
  // [SupportedEvmChainId.POLYGON_MUMBAI]: new AppJsonRpcProvider(SupportedEvmChainId.POLYGON_MUMBAI),
  [SupportedEvmChainId.CELO]: new AppJsonRpcProvider(SupportedEvmChainId.CELO),
  // [SupportedEvmChainId.CELO_ALFAJORES]: new AppJsonRpcProvider(SupportedEvmChainId.CELO_ALFAJORES),
  [SupportedEvmChainId.BSC]: new AppJsonRpcProvider(SupportedEvmChainId.BSC),
  [SupportedEvmChainId.BSC_TEST]: new AppJsonRpcProvider(SupportedEvmChainId.BSC_TEST),
  [SupportedEvmChainId.AVALANCHE]: new AppJsonRpcProvider(SupportedEvmChainId.AVALANCHE),
  [SupportedEvmChainId.GODWOKEN]: new AppJsonRpcProvider(SupportedEvmChainId.GODWOKEN),
  [SupportedEvmChainId.FANTOM]: new AppJsonRpcProvider(SupportedEvmChainId.FANTOM),
  [SupportedEvmChainId.GNOSIS]: new AppJsonRpcProvider(SupportedEvmChainId.GNOSIS),
  [SupportedEvmChainId.MOONBEAM]: new AppJsonRpcProvider(SupportedEvmChainId.MOONBEAM),
  [SupportedEvmChainId.OASIS_EMERALD]: new AppJsonRpcProvider(SupportedEvmChainId.OASIS_EMERALD),
  [SupportedEvmChainId.OASIS_SAPPHIRE]: new AppJsonRpcProvider(SupportedEvmChainId.OASIS_SAPPHIRE),
  [SupportedEvmChainId.AURORA]: new AppJsonRpcProvider(SupportedEvmChainId.AURORA),
  [SupportedEvmChainId.FUSE]: new AppJsonRpcProvider(SupportedEvmChainId.FUSE),
  [SupportedEvmChainId.ZKSYNC]: new AppJsonRpcProvider(SupportedEvmChainId.ZKSYNC),
  [SupportedEvmChainId.HARMONY]: new AppJsonRpcProvider(SupportedEvmChainId.HARMONY),
  [SupportedEvmChainId.MOONRIVER]: new AppJsonRpcProvider(SupportedEvmChainId.MOONRIVER),
  [SupportedEvmChainId.HECO]: new AppJsonRpcProvider(SupportedEvmChainId.HECO),
  [SupportedEvmChainId.OKXCHAIN]: new AppJsonRpcProvider(SupportedEvmChainId.OKXCHAIN),
  [SupportedEvmChainId.TELOS]: new AppJsonRpcProvider(SupportedEvmChainId.TELOS),
  [SupportedEvmChainId.ZETACHAIN_ATHENS_TESTNET]: new AppJsonRpcProvider(
    SupportedEvmChainId.ZETACHAIN_ATHENS_TESTNET,
  ),
  [SupportedEvmChainId.SCROLL_ALPHA_TESTNET]: new AppJsonRpcProvider(
    SupportedEvmChainId.SCROLL_ALPHA_TESTNET,
  ),
  [SupportedEvmChainId.CRONOS]: new AppJsonRpcProvider(SupportedEvmChainId.CRONOS),
  [SupportedEvmChainId.CRONOS_TESTNET]: new AppJsonRpcProvider(SupportedEvmChainId.CRONOS_TESTNET),
  [SupportedEvmChainId.SKALE_EUROPA]: new AppJsonRpcProvider(SupportedEvmChainId.SKALE_EUROPA),
  [SupportedEvmChainId.SKALE_EUROPA_TEST]: new AppJsonRpcProvider(
    SupportedEvmChainId.SKALE_EUROPA_TEST,
  ),
  // [SupportedEvmChainId.SKALE_TESTNET]: new AppJsonRpcProvider(SupportedEvmChainId.SKALE_TESTNET),
  [SupportedEvmChainId.IOTEX]: new AppJsonRpcProvider(SupportedEvmChainId.IOTEX),
  [SupportedEvmChainId.IOTEX_TESTNET]: new AppJsonRpcProvider(SupportedEvmChainId.IOTEX_TESTNET),
};
