// ----------------------------------------------------------------------

import { useMediaQuery } from '@mui/material';

export default function Autocomplete(theme) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          zIndex: '100 !important',
        },
        paper: {
          boxShadow: theme.customShadows.z20,
        },
        popper: {
          boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
          borderRadius: '8px',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
          fontSize: '36px',
        },
        option: {
          minHeight: isMobile ? '24px !important' : '48px !important',
          fontSize: isMobile ? 10 : 14,
        },
      },
    },
  };
}
