import { Box, Button, Divider, Grid, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useMemo } from 'react';

export const CompanyTab = () => {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useSelector(({ organisations: { organisation } }) => organisation);
  const { t } = useTranslation('common');

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    values,
    errors,
    touched,
    dirty,
    getFieldProps,
  } = useFormik({
    initialValues: {
      company_name: organization?.company_name,
      email: organization?.email,
      phone: organization?.phone ? organization?.phone : '+380',
      address: organization?.address || '',
      tax_number: organization?.tax_number || '',
      registration_number: organization?.registration_number || '',
      contact_person: organization?.contact_person,
    },
    validationSchema: Yup.object().shape({
      company_name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      email: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
      phone: Yup.string().test('isValid', 'Phone is not valid', (val) =>
        val ? val === '+' || val === '+380' || isValidPhoneNumber(val.toString()) : '',
      ),
      address: Yup.string().max(45, 'Maximum length 45 characters'),
      tax_number: Yup.number('Must be a number').typeError('Must be a number'),
      registration_number: Yup.number('Must be a number').typeError('Must be a number'),
      contact_person: Yup.string().max(45, 'Maximum length 45 characters').required('Is required'),
    }),

    onSubmit: (values, { resetForm }) => {
      dispatch({
        type: 'EDIT_ORGANISATION',
        payload: {
          ...values,
          phone: values.phone === '+' || values.phone === '+380' ? '' : values.phone,
          organization_id: user?.organization_id,
        },
      });
      resetForm({
        values: {
          ...values,
          phone: values.phone === '+' || values.phone === '+380' ? '' : values.phone,
          organization_id: user?.organization_id,
        },
      });
    },
  });

  useEffect(() => {
    dispatch({ type: 'GET_ORGANISATION_BY_ID', payload: user?.organization_id });
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: { xs: 1, md: 3 },
              width: '100%',
              boxShadow:
                'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            }}
            noValidate
            autoComplete="off"
          >
            <Stack direction="row" gap="20px">
              <Stack width="100%" gap="16px">
                <TextField
                  fullWidth
                  size="small"
                  label="Company name"
                  error={Boolean(touched.company_name && errors.company_name)}
                  helperText={touched.company_name && errors.company_name}
                  required
                  {...getFieldProps('company_name')}
                />

                <MuiPhoneNumber
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Phone Number"
                  data-cy="user-phone"
                  defaultCountry="ua"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />

                <TextField size="small" fullWidth label="Address" {...getFieldProps('address')} />
                <TextField
                  fullWidth
                  size="small"
                  label="Tax number"
                  {...getFieldProps('tax_number')}
                  error={Boolean(touched.tax_number && errors.tax_number)}
                  helperText={touched.tax_number && errors.tax_number}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Registration number"
                  {...getFieldProps('registration_number')}
                  error={Boolean(touched.registration_number && errors.registration_number)}
                  helperText={touched.registration_number && errors.registration_number}
                />
                <TextField
                  fullWidth
                  size="small"
                  required
                  label="Contact person"
                  {...getFieldProps('contact_person')}
                  error={Boolean(touched.contact_person && errors.contact_person)}
                  helperText={touched.contact_person && errors.contact_person}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={t('Email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  {...getFieldProps('email')}
                  required
                />
              </Stack>
            </Stack>
            <Stack mt={2} spacing={2}>
              <Stack direction="row" gap={2}>
                <Button type="submit" disabled={!(isValid && dirty)} variant="contained">
                  {t('Save')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
