// sagas.js
import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
    GET_REFERRAL_CODE_REQUEST,
    GET_REFERRAL_CODE_SUCCESS,
    GET_REFERRAL_CODE_FAILURE,
    SEND_REFERRAL_CODE_SUCCESS,
    SEND_REFERRAL_CODE_FAILURE,
    GET_REFERRALS_REQUEST,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILURE,
    SEND_REFERRAL_CODE_REQUEST,
    GET_REFERRAL_LEVELS_COUNT_SUCCESS,
    GET_REFERRAL_LEVELS_COUNT_FAILURE,
    GET_REFERRAL_LEVELS_COUNT_REQUEST
} from '../../constants/actions';
import { getReferralCode, joinReferralProgram, getReferrals, getReferralLevelsCount } from "../../services/referral";
import { toast } from "react-toastify";
import { updateDataInLocalstorage } from "../auth/helper";

function* fetchReferralCode() {
    try {
        const data = yield call(getReferralCode);
        yield put({ type: GET_REFERRAL_CODE_SUCCESS, payload: data });
        yield call(updateDataInLocalstorage, 'currentUser', { referralCode: data.referralCode });
    } catch (error) {
        yield put({ type: GET_REFERRAL_CODE_FAILURE, payload: error.message });
        toast.error(error.response.data.message);
    }
}

function* sendReferralCode(action) {
    try {
        const data = yield call(joinReferralProgram, action.payload);
        yield put({ type: SEND_REFERRAL_CODE_SUCCESS, payload: data });
        yield call(updateDataInLocalstorage, 'currentUser', {
            referrer: {
                fullName: data.fullName,
                referralJoinDate: data.referralJoinDate,
            }
        });
        yield call(updateDataInLocalstorage, 'currentUser', { referralPathIds: data.referralPathIds });
    } catch (error) {
        yield put({ type: SEND_REFERRAL_CODE_FAILURE, payload: error.message });
        toast.error(error.response.data.message);
    }
}

function* fetchReferrals() {
    try {
        const data = yield call(getReferrals);
        yield put({ type: GET_REFERRALS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_REFERRALS_FAILURE, payload: error.message });
        toast.error(error.response.data.message);
    }
}

function* fetchReferralLevelsCount() {
    try {
        const data = yield call(getReferralLevelsCount);
        yield put({ type: GET_REFERRAL_LEVELS_COUNT_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_REFERRAL_LEVELS_COUNT_FAILURE, payload: error.message });
        toast.error(error.response.data.message);
    }
}

export function* watchFetchReferrals() {
    yield takeLatest(GET_REFERRALS_REQUEST, fetchReferrals);
}

export function* watchSendReferralCode() {
    yield takeLatest(SEND_REFERRAL_CODE_REQUEST, sendReferralCode);
}

export function* watchFetchReferralCode() {
    yield takeLatest(GET_REFERRAL_CODE_REQUEST, fetchReferralCode);
}

export function* watchFetchReferralLevelsCount() {
    yield takeLatest(GET_REFERRAL_LEVELS_COUNT_REQUEST, fetchReferralLevelsCount);
}

export default function* rootSaga() {
    yield all([
        watchFetchReferralCode(),
        watchSendReferralCode(),
        watchFetchReferrals(),
        watchFetchReferralLevelsCount(),
    ]);
}