export const ConnectionType = {
  // INJECTED = 'INJECTED',
  METAMASK: 'METAMASK',
  // COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT: 'WALLET_CONNECT',
  NETWORK: 'NETWORK',
  OKX: 'OKX',
  TRONLINK: 'TRONLINK',
  APTOS_PETRA: 'APTOS_PETRA',
  // UNPREDICTABLE_GAS_LIMIT = 'UNPREDICTABLE_GAS_LIMIT',
};

let metaMaskErrorHandler;
export function setMetMaskErrorHandler(errorHandler) {
  metaMaskErrorHandler = errorHandler;
}

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

function onMetaMaskError(error) {
  onError(error);
  metaMaskErrorHandler?.(error);
}
