import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { useState } from 'react';
import { isAddress } from 'ethers/lib/utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWeb3 } from 'src/hooks/useWeb3';
import { isCurrentNetworkAddress } from 'src/utils';

export const Row = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
  handleEditRow,
  handleCancelEditRow,
  handleSaveEditRow,
  data,
}) => {
  const { walletAddress: account, chainId, walletType, isEvm, provider } = useWeb3();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    values,
    errors,
    touched,
    dirty,
    getFieldProps,
  } = useFormik({
    initialValues: {
      network: row.network,
      wallet_address: row.wallet_address,
      wallet_name: row.wallet_name,
    },
    validationSchema: Yup.object().shape({
      wallet_name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      wallet_address: Yup.string()
        .label('Wallet address')
        .required('Is required')
        .test('Is address', 'Please enter correct wallet address', (value) =>
          isCurrentNetworkAddress(value, isEvm, walletType, provider),
        ),
      network: Yup.string().label('Network').required('Is required'),
    }),

    onSubmit: (values) => {},
  });

  const someItemIsEditing = data && data?.some(({ isEdit }) => isEdit);

  const handleSelectRow = (event) => !someItemIsEditing && handleClick(event, row, row.id);

  return (
    <>
      {row.isEdit ? (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{ height: '90px' }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={true}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <TextField
              required
              size="small"
              fullWidth
              label="Wallet name"
              {...getFieldProps('wallet_name')}
              error={Boolean(touched.wallet_name && errors.wallet_name)}
              helperText={touched.wallet_name && errors.wallet_name}
            />
          </TableCell>

          <TableCell align="left">
            <TextField
              required
              fullWidth
              size="small"
              label="Network"
              {...getFieldProps('network')}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row">
            <TextField
              fullWidth
              size="small"
              label="Wallet address"
              {...getFieldProps('wallet_address')}
              error={Boolean(touched.wallet_address && errors.wallet_address)}
              helperText={touched.wallet_address && errors.wallet_address}
              required
            />
          </TableCell>
          <TableCell align="left">
            <IconButton
              size="small"
              disabled={!(isValid && dirty)}
              onClick={() => handleSaveEditRow({ ...values, id: row.id }, row.isEdit, row.isNew)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                handleCancelEditRow(row.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell onClick={handleSelectRow} padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={someItemIsEditing}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.wallet_name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.network || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.wallet_address || '-'}</Typography>
          </TableCell>
          <TableCell align="left">
            <IconButton
              onClick={() => {
                handleEditRow(row.id);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
