import { instance } from './index';

export const getReferralCode = async () => {
    const { data } = await instance.post('/referral');
    return data;
}

export const joinReferralProgram = async (code) => {
    const { data } = await instance.post('/referral/join', { referralCode: code });
    return data;
}

export const getReferrals = async () => {
    const { data } = await instance.get('/referral/children-fl');
    return data;
}

export const getReferralLevelsCount = async () => {
    const { data } = await instance.get('/referral/children-count');
    return data;
}