import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  IS_AUTH,
  IS_AUTH_SUCCESS,
  IS_AUTH_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  GET_BALANCE,
  GET_QRCODE_LINK_REQUEST,
  GET_QRCODE_LINK_SUCCESS,
  GET_QRCODE_LINK_FAILURE,
  GET_SIGN_IN_POLYGON_ID_SUCCESS,
  GET_SIGN_IN_POLYGON_ID_FAILURE,
  GET_SIGN_IN_POLYGON_ID_REQUEST,
  SET_AUTH_STATUS_POLYGON_ID, LOGIN_USER_POLYGON_ID,

} from '../../constants/actions';

let SESSION_USER;
try {
  SESSION_USER = JSON.parse(localStorage.getItem('session_user'));
} catch (e) {
  SESSION_USER = {};
}

const INITIAL_STATE = {
  user: SESSION_USER,
  isAuth: false,
  loading: false,
  commonLoading: false,
  errorMessage: '',
  balance: 0,
  qrCodeLink: {
    issuer: {},
    linkDetail: {
      schemaType: '',
    },
    qrCode: null,
  },
  polygonIdAuthReq: null,
  polygonIdIsSuccess: false,
  polygonIdStatus: {
    identifier: null,
    isAuth: false,
    onboard: false,
    authData: {}
  },
};

export const AuthUser = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_POLYGON_ID:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: payload, isAuth: true };

    case LOGIN_USER_ERROR:
      return { ...state, loading: false };

    case IS_AUTH:
      return { ...state, commonLoading: true };

    case IS_AUTH_SUCCESS:
      return { ...state, commonLoading: false };

    case IS_AUTH_ERROR:
      return { ...state, commonLoading: false };

    case REGISTER_USER:
      return { ...state, loading: true };

    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: payload };
    case GET_BALANCE:
      return { ...state, balance: payload };

    case 'LOGOUT_USER_SUCCESS':
      return { ...state, user: {}, isAuth: false };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: payload };

    case GET_QRCODE_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_QRCODE_LINK_SUCCESS:
      console.log('payload', payload);
      return {
        ...state,
        loading: false,
        qrCodeLink: payload.data,
      };
    case GET_QRCODE_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload,
      };
    case GET_SIGN_IN_POLYGON_ID_REQUEST:
      return { ...state, loading: true };
    case GET_SIGN_IN_POLYGON_ID_SUCCESS:
      return {
        ...state,
        polygonIdAuthReq: payload,
        polygonIdIsSuccess: true
      };
    case GET_SIGN_IN_POLYGON_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        polygonIdIsSuccess: false
      };
    case SET_AUTH_STATUS_POLYGON_ID:
      return {
        ...state,
        polygonIdStatus: payload,
      }
    default:
      return { ...state };
  }
};

export const selectPolygonIdAuthReq = state => state.AuthUser.polygonIdAuthReq;
export const selectPolygonIdIsSuccess = state => state.AuthUser.polygonIdIsSuccess;
export const polygonIdStatus = state => state.AuthUser.polygonIdStatus;

