import React from 'react';
import { Stack, Typography } from '@mui/material';

export const IdField = ({ index }) => (
  <Stack
    gridArea="id"
    height="100%"
    justifyContent="flex-start"
    flexDirection="row"
    alignItems="center"
  >
    <Typography
      sx={{ display: { xs: 'block', md: 'none' } }}
      variant="body2"
    >
      Detail №{index + 1}.
    </Typography>
    <Typography
      sx={{ display: { xs: 'none', md: 'block' } }}
      variant="body2"
    >
      {index + 1}.
    </Typography>
  </Stack>
);
