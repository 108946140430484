// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.border {
  padding: 15px;
  border: 2px solid black;
  border-radius: 12px;
  width: 256px;
  border-radius: 15px;
  box-sizing: content-box;
}

.have_account {
  display: block;
}

@media screen and (max-width: 280px) {
  .have_account {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/sections/authentication/login/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 6rem;\n  min-height: 100vh;\n}\n\n.border {\n  padding: 15px;\n  border: 2px solid black;\n  border-radius: 12px;\n  width: 256px;\n  border-radius: 15px;\n  box-sizing: content-box;\n}\n\n.have_account {\n  display: block;\n}\n\n@media screen and (max-width: 280px) {\n  .have_account {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
