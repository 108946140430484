// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WrongNetwork_wrong__g2CKn {
  color: var(--theme-text-white);
  text-align: center;
  padding: 30px 30px 40px;
}
.WrongNetwork_wrong__g2CKn .WrongNetwork_statusIcon__XFSJ2 {
  display: block;
  margin: -30px auto -50px;
  width: 219px;
  height: 187px;
}
.WrongNetwork_wrong__g2CKn h5 {
  color: var(--thame-text-dark-gray);
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.003em;
  margin-bottom: 30px;
}
.WrongNetwork_wrong__g2CKn p {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.003em;
  color: var(--theme-text-secondary);
  margin: 0 0 30px;
}
.WrongNetwork_wrong__g2CKn .WrongNetwork_btnGroup__GUGKn {
  width: 100%;
}
.WrongNetwork_wrong__g2CKn .WrongNetwork_btnGroup__GUGKn button {
  left: calc((100% - 200px) / 2);
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Wallet/WrongNetwork/WrongNetwork.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,uBAAA;AACF;AACE;EACE,cAAA;EACA,wBAAA;EACA,YAAA;EACA,aAAA;AACJ;AAEE;EACE,kCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kCAAA;EACA,gBAAA;AADJ;AAIE;EACE,WAAA;AAFJ;AAII;EACE,8BAAA;EACA,gBAAA;AAFN","sourcesContent":[".wrong {\n  color: var(--theme-text-white);\n  text-align: center;\n  padding: 30px 30px 40px;\n\n  .statusIcon {\n    display: block;\n    margin: -30px auto -50px;\n    width: 219px;\n    height: 187px;\n  }\n\n  h5 {\n    color: var(--thame-text-dark-gray);\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 20px;\n    letter-spacing: 0.003em;\n    margin-bottom: 30px;\n  }\n\n  p {\n    font-size: 16px;\n    font-weight: normal;\n    line-height: 24px;\n    letter-spacing: 0.003em;\n    color: var(--theme-text-secondary);\n    margin: 0 0 30px;\n  }\n\n  .btnGroup {\n    width: 100%;\n\n    button {\n      left: calc((100% - 200px) / 2);\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrong": `WrongNetwork_wrong__g2CKn`,
	"statusIcon": `WrongNetwork_statusIcon__XFSJ2`,
	"btnGroup": `WrongNetwork_btnGroup__GUGKn`
};
export default ___CSS_LOADER_EXPORT___;
