import React from 'react';
import { Stack, Button } from '@mui/material';

const SubmitButton = ({ isValid, text }) => {
  return (
    <Stack p={{ xs: 1, md: 3 }} direction="row" justifyContent="flex-start" alignItems="center">
      <Stack sx={{ marginBottom: '100px' }}>
        <Button variant="contained" type="submit" disabled={!isValid}>
          {text}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SubmitButton;
