import React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { CustomToolbar } from './CustomToolbar';

export const CustomDataGrid = ({
  props,
  handleEditRow,
  handleViewRow,
  handleSendRow,
  handleDeleteRow,
  handleAddRow,
  handleExport,
}) => {
  const columnsWithAction = [...props.columns];

  if (handleEditRow || handleViewRow) {
    columnsWithAction.push({
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => (
        <Stack direction="row">
          {handleEditRow && (
            <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditRow(id)} />
          )}
          {handleViewRow && (
            <GridActionsCellItem
              icon={<ReceiptIcon />}
              label="Delete"
              onClick={handleViewRow(id)}
            />
          )}
          {handleSendRow && (
            <GridActionsCellItem icon={<SendIcon />} label="Send" onClick={handleSendRow(id)} />
          )}
          {handleDeleteRow && (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteRow(id)}
            />
          )}
        </Stack>
      ),
    });
  }

  return (
    <DataGrid
      {...props}
      rowHeight={68}
      columns={columnsWithAction}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{
        toolbar: {
          selectedRows: props.selectedRows,
          onDelete: handleDeleteRow,
          onAddRow: handleAddRow,
          onExport: handleExport,
        },
      }}
    />
  );
};
