import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { WALLETS } from '../../../utils/const';
import { network, injected, connectorsByName } from '../../../utils/connectors';
import { delay, getChainNameById, getHumanValue } from '../../../utils/index';

import { WrongNetwork, InitializingWallet } from '../index';

import styles from './WalletConnect.module.scss';

import metaLogo from '../../../assets/images/wallet-icons/metamask.svg';
import petraLogo from '../../../assets/images/wallet-icons/petra-wallet-img.jpeg';
import tronLogo from '../../../assets/images/wallet-icons/tron-icon.svg';
import walletConnectLogo from '../../../assets/images/wallet-icons/wallet-connect.svg';

import MenuPopover from '../../MenuPopover';
import { Box, Button, Divider, Stack, Typography, Modal } from '@mui/material';
import { formatEther } from 'ethers/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setUserBalance } from 'src/redux/actions';

// import { tokenSymbol } from 'src/utils';
import { ConnectionType } from 'src/connection';
import { WalletFactory } from 'src/wallets/wallet-factory';
import { useWeb3 } from 'src/hooks/useWeb3';
import { formatNetworks, makeShortenWalletAddress } from 'src/helpers/stringUtils';
import { isSupportedChain, NATIVE_TOKENS_NAMES } from 'src/constants/chains';

/**
 * Used for resetting account selection in Metamask
 * @param connector InjectedConnector
 */
async function resetPermissions(connector) {
  // Runs only they are brand new, or have hit the disconnect button
  const provider = await connector.getProvider();
  await provider.request({
    method: 'wallet_requestPermissions',
    params: [
      {
        eth_accounts: {},
      },
    ],
  });
}

const networks = [
  {
    name: 'Godwoken',
    id: 71402,
  },
  {
    name: 'Ethereum',
    id: 1,
  },
  {
    name: 'Binance',
    id: 56,
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  padding: '48px 24px 64px',
};

const WalletConnectComponent = ({ type, isWalletModalVisiable }) => {
  const [isInitializingVisible, setIsInitializingVisible] = useState(false);
  const [pendingWallet, setPendingWallet] = useState();
  const [pendingError, setPendingError] = useState();
  const [currentWallet, setCurrentWallet] = useState();
  const [isWrongNetworkVisible, setIsWrongNetworkVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPopup, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    walletAddress: account,
    walletType,
    chainId,
    wallet,
    balance,
    connector,
    provider,
  } = useWeb3();

  const tokenSymbol =
    walletType === ConnectionType.TRONLINK
      ? 'TRX'
      : walletType === ConnectionType.APTOS_PETRA
      ? 'APT'
      : NATIVE_TOKENS_NAMES[chainId];

  const getCurrentWallet = (currentConnector) => {
    const wallet = WALLETS.find(({ walletConnector }) => currentConnector === walletConnector);
    setCurrentWallet(wallet);
  };

  const handleCloseOnLogin = async () => {
    await delay(2000);
    setIsInitializingVisible(false);
  };

  useEffect(() => {
    if (account) {
    }
  }, [account]);

  const [userMenuDropdown, setUserMenuDropdown] = useState(false);

  const userMenuRef = useRef();

  const handlerOutsideClick = (e) => {
    if (!e.path.includes(userMenuRef.current)) {
      setUserMenuDropdown(false);
    }
  };

  useEffect(() => {
    if (userMenuDropdown) {
      document.body.addEventListener('click', handlerOutsideClick);
    }
    return () => {
      document.body.removeEventListener('click', handlerOutsideClick);
    };
  }, [userMenuDropdown, handlerOutsideClick]);

  const handleWalletConnect = async (type) => {
    const wallet = WalletFactory.getWallet(type);
    if (!wallet) return;

    try {
      await wallet.connect();

      setOpen(false);
      setPopupOpen(false);
    } catch (error) {
      wallet.resetWallet();
      setOpen(false);
      setPopupOpen(false);
    }
  };

  const disconnectHandler = useCallback(async () => {
    if (walletType === ConnectionType.WALLET_CONNECT) {
      connector.disconnect();
    }

    wallet.resetWallet();

    setOpen(false);
  }, [wallet, connector, provider]);

  return (
    <>
      {account ? (
        <div style={{ color: 'black', display: type === 'second' ? 'none' : 'block' }}>
          <Stack>
            <Button
              sx={{ width: { xs: '105px', md: '120px' } }}
              variant="contained"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);

                setPopupOpen(true);
              }}
            >
              {makeShortenWalletAddress(account)}
            </Button>
          </Stack>
          <MenuPopover
            anchorEl={anchorEl}
            open={openPopup}
            onClose={() => {
              setPopupOpen(false);
              setAnchorEl(null);
            }}
            sx={{ width: 'auto', mt: 1.5, ml: 0.5, overflow: 'inherit', padding: { xs: 2, md: 3 } }}
          >
            <Stack gap={1}>
              <Stack>
                <Typography variant="body2">
                  Network:{' '}
                  {chainId &&
                    isSupportedChain(chainId) &&
                    formatNetworks(getChainNameById(chainId))}
                </Typography>
              </Stack>
              <Divider />
              <Stack>
                <Typography variant="body2">
                  Wallet Address: {account.slice(0, 9) + '...' + account.slice(-3) || ''}
                </Typography>
              </Stack>
              <Divider />
              <Stack>
                <Typography variant="body2">
                  Balance:{' '}
                  {balance
                    ? getHumanValue(
                        String(balance),
                        walletType === ConnectionType.TRONLINK
                          ? 6
                          : walletType === ConnectionType.APTOS_PETRA
                          ? 8
                          : 18,
                      ).toString() +
                      ' ' +
                      tokenSymbol
                    : 0 + ' ' + tokenSymbol}
                </Typography>
              </Stack>
              <Stack>
                <Button onClick={() => disconnectHandler()}>Disconnect</Button>
              </Stack>
            </Stack>
          </MenuPopover>
        </div>
      ) : (
        <Button
          sx={{
            display: type === 'second' ? 'none' : 'block',
            fontSize: { xs: '8px', md: '12px' },
            padding: { xs: '6px', md: '6px 16px' },
            width: { xs: '105px', md: '120px' },
          }}
          variant="contained"
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
            setPopupOpen(false);

            return;
          }}
        >
          Connect wallet
        </Button>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 1200 }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form">
          <Stack spacing={2}>
            <Stack textAlign="center">
              <Typography mb={2} fontSize="24px" color="#242424" fontWeight={500}>
                Connect your wallet
              </Typography>
              <Typography mb={3} fontSize="14px" color="#242424">
                In order for you to use all the advantages of our service, connect your wallet to
                your account
              </Typography>
              <div className={styles.walletList}>
                <Button
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                    justifyContent: 'start',
                    gap: 2,
                  }}
                  size="large"
                  variant="outlined"
                  onClick={() => handleWalletConnect(ConnectionType.METAMASK)}
                >
                  <img src={metaLogo} alt="Metamask" /> Connect Metamask
                </Button>
                <Button
                  sx={{
                    display: {
                      md: 'flex',
                    },
                    justifyContent: 'start',
                    gap: 2,
                  }}
                  size="large"
                  variant="outlined"
                  onClick={() => handleWalletConnect(ConnectionType.WALLET_CONNECT)}
                >
                  <img src={walletConnectLogo} alt="Metamask" /> Connect WalletConnect
                </Button>
                <Button
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                    justifyContent: 'start',
                    gap: 2,
                  }}
                  size="large"
                  variant="outlined"
                  onClick={() => handleWalletConnect(ConnectionType.TRONLINK)}
                >
                  <img
                    style={{ width: '32px', height: '32px' }}
                    src={tronLogo}
                    alt="TronLink Wallet"
                  />{' '}
                  Connect TronLink
                </Button>
                <Button
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                    justifyContent: 'start',
                    gap: 2,
                  }}
                  size="large"
                  variant="outlined"
                  onClick={() => handleWalletConnect(ConnectionType.APTOS_PETRA)}
                >
                  <img
                    style={{ width: '32px', height: '32px' }}
                    src={petraLogo}
                    alt="Petra Wallet"
                  />{' '}
                  Connect Petra Wallet
                </Button>
                {/* {WALLETS.map(({ name, icon, walletConnector }) => {
                  return (
                    <li key={name}>
                      <Button
                        sx={{ width: '100%' }}
                        onClick={() => activateWallet(walletConnector)}
                        block
                      >
                        {name === 'Metamask' && <img src={metaLogo} alt="icon" />}
                        {name === 'WalletConnect' && <img src={walletConnectLogo} alt="icon" />}
                        Connect {name}
                      </Button>
                    </li>
                  );
                })} */}
              </div>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      {/* <Modal
        open={isInitializingVisible}
        onClose={() => setIsInitializingVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form">
          <Stack spacing={2}>
            <InitializingWallet
              connector={pendingWallet}
              error={pendingError}
              activateWallet={activateWallet}
              setPendingError={setPendingError}
            />
          </Stack>
        </Box>
      </Modal> */}

      <Modal
        open={isWrongNetworkVisible}
        onClose={() => {
          setIsWrongNetworkVisible(false);
          setIsInitializingVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form">
          <Stack spacing={2}>
            <WrongNetwork>
              <Button
                variant="contained"
                onClick={() => {
                  setIsWrongNetworkVisible(false);
                  setIsInitializingVisible(false);
                }}
              >
                Okay
              </Button>
            </WrongNetwork>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default memo(WalletConnectComponent);
