import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, RadioGroup, FormControl, FormControlLabel, Radio, Button, TextField, FormLabel, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { pascalStrToSpacedWord } from './helper'

function FormPolygonID({ fieldInfo, passBackResults }) {
    const [canSubmitForm, setCanSubmitForm] = useState(false)
    const {
        handleSubmit,
        register,
        getValues,
        control,
        formState: { errors, isSubmitting },
    } = useForm()

    const FormInfoLabel = ({ name, info }) => {
        const spacedName = pascalStrToSpacedWord(name)
        return (
            <FormLabel htmlFor={name}>
                {spacedName}{' '}
                {info && (
                    <Tooltip title={info}>
                        <InfoOutlinedIcon />
                    </Tooltip>
                )}
            </FormLabel>
        )
    }

    return (
        <form
            onSubmit={handleSubmit(passBackResults)}
            onChange={() => {
                const resultsAreValid = Object.values(getValues()).every(
                    (i) => typeof i === 'string' && i.length > 0
                )
                setCanSubmitForm(resultsAreValid)
            }}
        >
            <FormControl>
                {fieldInfo.map((field) => {
                    const { name, type, description } = field
                    return (
                        <Box key={name} m={4}>
                            <FormInfoLabel name={name} info={description} />

                            {type === 'date' && (
                                <TextField
                                    id={name}
                                    type="date"
                                    {...register(name, {
                                        required: 'This is required',
                                    })}
                                />
                            )}

                            {type === 'number' && (
                                <TextField
                                    id={name}
                                    type="number"
                                    placeholder={name}
                                    {...register(name, {
                                        required: 'This is required',
                                    })}
                                />
                            )}
                            {type === 'boolean' && (
                                <Controller
                                    name={name}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <RadioGroup onChange={onChange} value={value} row>
                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                />
                            )}
                        </Box>
                    )
                })}
            </FormControl>
            <Box m={4}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!canSubmitForm}
                    endIcon={<OpenInNewIcon />}
                    type="submit"
                >
                    Claim
                </Button>
            </Box>
        </form>
    )
}

export default FormPolygonID
