import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { isAddress } from 'ethers/lib/utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWeb3 } from 'src/hooks/useWeb3';
import { isCurrentNetworkAddress } from 'src/utils';

export const Row = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
  handleEditRow,
  handleCancelEditRow,
  handleSaveEditRow,
  data,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { walletType, isEvm, provider, walletAddress: account } = useWeb3();

  const initialValues = useMemo(
    () => ({
      name: row?.name,
      surname: row?.surname,
      position: row?.position,
      wallet: row?.wallet,
      network: row?.network,
      amount: row?.amount,
      notes: row?.notes,
    }),
    [row],
  );

  const { isValid, values, errors, touched, dirty, getFieldProps, resetForm } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      surname: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      wallet: Yup.string()
        .label('Wallet address')
        .required('Is required')
        .test(
          'Duplicate wallet',
          'This wallet already exists',
          (value) => !data.some((item) => item.wallet === value && row && row.wallet !== value),
        )
        .test('Is address', 'Please enter correct wallet address', (value) =>
          isCurrentNetworkAddress(value, isEvm, walletType, provider),
        ),

      network: Yup.string().label('Network').required('Is required'),
      amount: Yup.number().min(0, 'Must be at least 0').required('Is required'),
      notes: Yup.string().max(45, 'Maximum length 45 characters'),
    }),

    onSubmit: (values) => {},
  });

  const someItemIsEditing = data && data?.some(({ isEdit }) => isEdit);

  const handleSelectRow = (event) => !someItemIsEditing && handleClick(event, row, row.id);
  return (
    <>
      {row.isEdit ? (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{ height: '90px' }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={true}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <TextField
              required
              size="small"
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row">
            <TextField
              fullWidth
              size="small"
              label={t('surname')}
              {...getFieldProps('surname')}
              error={Boolean(touched.surname && errors.surname)}
              helperText={touched.surname && errors.surname}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label={t('Position')}
              {...getFieldProps('position')}
            />
          </TableCell>

          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label="Network"
              {...getFieldProps('network')}
              error={Boolean(touched.network && errors.network)}
              helperText={touched.network && errors.network}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label={t('wallet.id')}
              error={Boolean(touched.wallet && errors.wallet)}
              helperText={touched.wallet && errors.wallet}
              {...getFieldProps('wallet')}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              type="number"
              size="small"
              fullWidth
              label="Amount"
              {...getFieldProps('amount')}
              InputProps={{ inputProps: { min: 0 } }}
              required
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </TableCell>

          <TableCell align="left">
            <TextField fullWidth size="small" label="Notes" {...getFieldProps('notes')} />
          </TableCell>
          <TableCell align="left">
            <IconButton
              size="small"
              disabled={!(isValid && dirty)}
              onClick={() => {
                handleSaveEditRow({ ...values, id: row.id }, row.isEdit, row.isNew);
                resetForm({
                  values: { ...values, id: row.id },
                  dirtyFields: false,
                });
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                handleCancelEditRow(row.id);
                resetForm({
                  values: { ...initialValues, id: row.id },
                  dirtyFields: false,
                });
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell onClick={handleSelectRow} padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={someItemIsEditing}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.surname || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.position || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.network}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{`${row.wallet.substring(
              0,
              4 + 2,
            )}...${row.wallet.substring(42 - 4)}`}</Typography>{' '}
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.amount || 0}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.notes || '-'}</Typography>
          </TableCell>
          <TableCell align="left">
            <IconButton
              disabled={!account}
              onClick={() => {
                handleEditRow(row.id);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
