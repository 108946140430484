import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';

export const SelectInput = ({
  name,
  gridArea,
  label,
  value,
  required = false,
  options = [],
  setFieldValue,
  setFieldTouched,
}) => (
  <Stack gridArea={gridArea}>
    <FormControl size="small" fullWidth required={required}>
      <InputLabel size="small">{label}</InputLabel>
      <Select
        name={name}
        label={label}
        fullWidth
        size="small"
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
          setFieldTouched(name, true, false);
        }}
      >
        {options.map(({ value }) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
);
