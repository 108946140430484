import React, { useEffect } from 'react';
import { getReferralLevelsCount } from "src/redux/referral/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

export const ReferralLevelsCountTable = () => {
  const dispatch = useDispatch();
  const { referralCode, referralLevelsCount } = useSelector(state => state.referral);

  useEffect(() => {
    if (referralCode) {
      dispatch(getReferralLevelsCount());
    }
  }, [dispatch, referralCode]);

  if (!referralCode) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }} pb={1}>My Referrals</Typography>
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Level 1</TableCell>
                <TableCell align="center" style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Level 2</TableCell>
                <TableCell align="center" style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Level 3</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{referralLevelsCount.level1}</TableCell>
                <TableCell align="center">{referralLevelsCount.level2}</TableCell>
                <TableCell align="center">{referralLevelsCount.level3}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
