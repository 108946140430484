import { WalletAbstract } from './wallet-abstract';
import { ethers } from 'ethers';
import { JsonRpcSigner, JsonRpcProvider, Web3Provider } from '@ethersproject/providers';

import store from '../redux/store';
import { updateWalletState } from 'src/redux/wallet/actions';
import { ConnectionType } from '../connection/index';
import { hexValue } from '@ethersproject/bytes';
import { getChainInfo } from '../constants/chainInfo';
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from '../constants/chains';
import { RPC_URLS } from '../constants/networks';
import { WalletFactory } from './wallet-factory.js';
import { getMetamaskProvider } from '../utils/providers';

export class WalletMetamask extends WalletAbstract {
  constructor() {
    super();
    this.type = ConnectionType.METAMASK;
  }

  checkConnection = async () => {
    await this.connectWallet('eth_accounts');
    return this;
  };

  connect = async () => {
    await this.connectWallet('eth_requestAccounts');
    return this;
  };

  // export function getHasMetaMaskExtensionInstalled(): boolean {
  //   if (typeof window !== 'undefined' && 'ethereum' in window) {
  //     return window.ethereum?.isMetaMask ?? false;
  //   } else return false;
  // }

  connectWallet = async (method) => {
    if (typeof window !== 'undefined' && window.ethereum && window.ethereum.isMetaMask) {
      try {
        // this.resetWallet();
        const addressArray = await getMetamaskProvider().request({ method });
        const provider = new Web3Provider(getMetamaskProvider());

        if (!addressArray.length) {
          return;
        }
        this.address = addressArray[0];

        const chainIdHex = await getMetamaskProvider().request({ method: 'eth_chainId' });
        const chainIdNumber = parseInt(chainIdHex, 16);

        store.dispatch(
          updateWalletState({
            walletAddress: this.address,
            walletType: this.type,
            isEvm: true,
            provider,
            chainId: chainIdNumber,
          }),
        );
      } catch (err) {
        this.error = err.message;
        this.resetWallet();
      }
    } else {
      this.error = 'Please install Metamask';
    }
  };

  getChainId = async () => {
    const chainIdHex = await getMetamaskProvider().request({ method: 'eth_chainId' });
    const chainIdNumber = parseInt(chainIdHex, 16);
    return chainIdNumber;
  };

  getProvider = () => {
    return new Web3Provider(getMetamaskProvider());
  };

  getBalance = async () => {
    if (typeof window !== 'undefined' && getMetamaskProvider()) {
      try {
        const provider = await this.getProvider();
        const address = this.address;
        const balance = await provider.getBalance(address);

        return balance.toString();
      } catch (err) {
        console.error('Failed to retrieve balance:', err);
      }
    } else {
      console.warn('Metamask is not available');
    }
  };

  switchNetwork = async (chainId) => {
    if (chainId === SupportedChainId.APTOS_MAINNET) {
      if (window.aptos) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.APTOS_PETRA);
      } else {
        alert('Download Petra Wallet wallet chrome extension');
      }
      return;
    }
    if (
      chainId === SupportedChainId.TRON_NILE_TESTNET ||
      chainId === SupportedChainId.TRON_GRID_MAINNET
    ) {
      if (window.tronWeb && window.tronLink.ready) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.TRONLINK);
      } else if (window.tronWeb && !window.tronLink.ready) {
        alert('Connect Tronlink wallet');
      } else {
        alert('Download Tronlink wallet chrome extension');
      }
      return;
    }
    const hexChainId = hexValue(chainId);

    if (typeof window !== 'undefined' && getMetamaskProvider()) {
      try {
        await getMetamaskProvider().request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902 || switchError.code === -32603) {
          try {
            await getMetamaskProvider().request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: hexChainId,
                  chainName: CHAIN_IDS_TO_NAMES[chainId],
                  nativeCurrency: getChainInfo(chainId).nativeCurrency,
                  rpcUrls: RPC_URLS[chainId],
                  blockExplorerUrls: [getChainInfo(chainId).explorer],
                },
              ],
            });
          } catch (addError) {
            console.log('addError', addError);
          }
        }
      }
    }
  };
}
