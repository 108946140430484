import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminInvoices } from '../../redux/invoices/actions';
import { INVOICE_CREATE } from '../../constants/routes';
import InvoiceTable from './components/InvoicesTable';
import PageWrapper from '../../components/PageWrapper';

export const Invoice = () => {
  const dispatch = useDispatch();
  const { invoices, loading } = useSelector(({ invoices: { adminInvoices }, loading }) => ({
    invoices: adminInvoices,
    loading,
  }));

  useEffect(() => {
    dispatch(getAdminInvoices());
  }, [dispatch]);

  return (
    <PageWrapper
      title={`Invoices | ${invoices?.length || 0}`}
      button_name={'New invoice'}
      button_route={INVOICE_CREATE}
    >
      <InvoiceTable data={invoices} loading={loading} />
    </PageWrapper>
  );
};
