import { Stack, Grid } from '@mui/material';
import styles from './forgot.module.scss';

import Logo from '../assets/sign-in/sign-in-logo-white.svg';
import { useState } from 'react';

import ChangePasswordForm from 'src/sections/authentication/change-password/ChangePasswordForm';

function ForgotPass() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navbarItems = [
    {
      name: 'Features',
      link: 'https://coinsender.io/#features',
    },
    {
      name: 'How it works',
      link: 'https://coinsender.io/#how-it-works',
    },
    {
      name: 'Benefits',
      link: 'https://coinsender.io/#benefits',
    },
    {
      name: 'Roadmap',
      link: 'https://coinsender.io/#roadmap',
    },
    {
      name: 'GitBook',
      link: 'https://coinsender.gitbook.io/support',
    },
    {
      name: 'FAQ',
      link: 'https://coinsender.io/faq',
    },
    {
      name: 'Main',
      link: 'https://coinsender.io/',
    },
  ];

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__logo}>
          <a href="https://coinsender.io/">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <div className={`${styles.header__items} ${isMenuOpen ? styles.header__items_open : ''}`}>
          <ul>
            {navbarItems.map(({ name, link }) => (
              <li key={name} className={styles.item}>
                <a href={link} className={styles.itemMenu}>
                  {name}
                  {/*<Typography variant="body2">{name}</Typography>*/}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Stack
          sx={{ width: '1px', height: '37.8px', display: { xs: 'none', sm: 'block' } }}
        ></Stack>
        <div
          className={`${styles.burgerMenu} ${isMenuOpen ? styles.burgerMenu_open : ''}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <Grid container borderRadius={3} className={styles.common}>
              <Grid item xs={12} md={6} lg={5} cointainer sx={{ width: '100%', margin: '0 auto' }}>
                <Grid item className={`${styles.right} ${isMenuOpen ? styles.right_open : ''}`}>
                  <div style={{ height: '100%' }} className={styles.right__content}>
                    <Stack color="white" className={styles.right__title} textAlign="center">
                      Change password
                    </Stack>
                    <ChangePasswordForm />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </>
  );
}

export default ForgotPass;
