export const SupportedChainId = {
  MAINNET: 1,

  ARBITRUM_ONE: 42161,
  // ARBITRUM_RINKEBY : 421611,

  OPTIMISM: 10,
  // OPTIMISM_GOERLI : 420,

  POLYGON: 137,
  // POLYGON_MUMBAI : 80001,

  CELO: 42220,
  // CELO_ALFAJORES : 44787,

  BSC: 56,
  BSC_TEST: 97,

  AVALANCHE: 43114,

  GODWOKEN: 71402,

  FANTOM: 250,

  GNOSIS: 100,

  MOONBEAM: 1284,

  OASIS_EMERALD: 42262,
  OASIS_SAPPHIRE: 23294,

  AURORA: 1313161554,

  FUSE: 122,

  ZKSYNC: 324,

  HARMONY: 1666600000,

  MOONRIVER: 1285,

  HECO: 128,

  OKXCHAIN: 66,

  TELOS: 40,

  ZETACHAIN_ATHENS_TESTNET: 7001,

  SCROLL_ALPHA_TESTNET: 534353,

  CRONOS: 25,

  CRONOS_TESTNET: 338,

  SKALE_EUROPA: 2046399126,
  SKALE_EUROPA_TEST: 476158412,

  IOTEX: 4689,

  IOTEX_TESTNET: 4690,

  TRON_GRID_MAINNET: 'TRON_GRID_MAINNET',

  TRON_NILE_TESTNET: 'TRON_NILE_TESTNET',

  APTOS_MAINNET: 'APTOS_MAINNET',
};

export const SupportedEvmChainId = {
  MAINNET: 1,

  ARBITRUM_ONE: 42161,
  // ARBITRUM_RINKEBY = 421611,

  OPTIMISM: 10,
  // OPTIMISM_GOERLI = 420,

  POLYGON: 137,
  // POLYGON_MUMBAI = 80001,

  CELO: 42220,
  // CELO_ALFAJORES = 44787,

  BSC: 56,
  BSC_TEST: 97,

  AVALANCHE: 43114,

  GODWOKEN: 71402,

  FANTOM: 250,

  GNOSIS: 100,

  MOONBEAM: 1284,

  OASIS_EMERALD: 42262,
  OASIS_SAPPHIRE: 23294,

  AURORA: 1313161554,

  FUSE: 122,

  ZKSYNC: 324,

  HARMONY: 1666600000,

  MOONRIVER: 1285,

  HECO: 128,

  OKXCHAIN: 66,

  TELOS: 40,

  ZETACHAIN_ATHENS_TESTNET: 7001,

  SCROLL_ALPHA_TESTNET: 534353,

  CRONOS: 25,

  CRONOS_TESTNET: 338,

  // SKALE_TESTNET = 1351057110,

  SKALE_EUROPA: 2046399126,
  SKALE_EUROPA_TEST: 476158412,

  IOTEX: 4689,

  IOTEX_TESTNET: 4690,
};

export const DEFAULT_CHAIN_ID = 56;

export const isSupportedChain = (chainId) => {
  if (!chainId) {
    return false;
  }

  const chains = Object.values(SupportedChainId);

  return chains.includes(chainId);
};

// export function isSupportedChain(chainId) {
//   console.log('isSupportedChain chainId', chainId);
//   return !!chainId && !!SupportedChainId[chainId];
// }

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'ethereum',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.CELO]: 'celo',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.BSC]: 'binance',
  [SupportedChainId.BSC_TEST]: 'testnet_binance',
  [SupportedChainId.AVALANCHE]: 'avalanche',
  [SupportedChainId.GODWOKEN]: 'godwoken',
  [SupportedChainId.FANTOM]: 'fantom',
  [SupportedChainId.GNOSIS]: 'gnosis',
  [SupportedChainId.MOONBEAM]: 'moonbeam',
  [SupportedChainId.OASIS_EMERALD]: 'oasis_emerald',
  [SupportedChainId.OASIS_SAPPHIRE]: 'oasis_sapphire',
  [SupportedChainId.AURORA]: 'aurora',
  [SupportedChainId.FUSE]: 'fuse',
  [SupportedChainId.ZKSYNC]: 'zkSync',
  [SupportedChainId.HARMONY]: 'harmony',
  [SupportedChainId.MOONRIVER]: 'moonriver',
  [SupportedChainId.HECO]: 'heco',
  [SupportedChainId.OKXCHAIN]: 'okx_chain',
  [SupportedChainId.TELOS]: 'telos',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: 'testnet_zetachain_athens',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: 'tesnet_scroll_alpha',
  [SupportedChainId.CRONOS]: 'cronos',
  [SupportedChainId.CRONOS_TESTNET]: 'tesnet_cronos',
  [SupportedChainId.SKALE_EUROPA_TEST]: 'skale_europa_testnet',
  [SupportedChainId.SKALE_EUROPA]: 'skale_europa_hub',
  // [SupportedChainId.SKALE_TESTNET]: 'skale_chaos',
  [SupportedChainId.IOTEX]: 'iotex',
  [SupportedChainId.IOTEX_TESTNET]: 'testnet_iotex',
  [SupportedChainId.TRON_GRID_MAINNET]: 'tron_grid',
  // [SupportedChainId.TRON_NILE_TESTNET]: 'tron_nile',
  [SupportedChainId.APTOS_MAINNET]: 'aptos_mainnet',
};

export const NATIVE_TOKENS_NAMES = {
  [SupportedChainId.MAINNET]: 'ETH',
  [SupportedChainId.POLYGON]: 'MATIC',
  [SupportedChainId.CELO]: 'CELO',
  [SupportedChainId.ARBITRUM_ONE]: 'ETH',
  [SupportedChainId.OPTIMISM]: 'ETH',
  [SupportedChainId.BSC]: 'BNB',
  [SupportedChainId.BSC_TEST]: 'BNB',
  [SupportedChainId.AVALANCHE]: 'AVAX',
  [SupportedChainId.GODWOKEN]: 'CKB',
  [SupportedChainId.FANTOM]: 'FTM',
  [SupportedChainId.GNOSIS]: 'XDAI',
  [SupportedChainId.AURORA]: 'ETH',
  [SupportedChainId.MOONBEAM]: 'GLMR',
  [SupportedChainId.OASIS_EMERALD]: 'ROSE',
  [SupportedChainId.OASIS_SAPPHIRE]: 'ROSE',
  [SupportedChainId.AURORA]: 'ETH',
  [SupportedChainId.FUSE]: 'FUSE',
  [SupportedChainId.HARMONY]: 'ONE',
  [SupportedChainId.MOONRIVER]: 'MOVR',
  [SupportedChainId.HECO]: 'HT',
  [SupportedChainId.OKXCHAIN]: 'OKT',
  [SupportedChainId.TELOS]: 'TLOS',
  [SupportedChainId.ZKSYNC]: 'ETH',
  [SupportedChainId.IOTEX]: 'IOTX',
  [SupportedChainId.IOTEX_TESTNET]: 'IOTX',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: 'aZETA',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: 'ETH',
  [SupportedChainId.CRONOS]: 'CRO',
  [SupportedChainId.CRONOS_TESTNET]: 'CRO',
  [SupportedChainId.SKALE_EUROPA_TEST]: 'sFUEL',
  [SupportedChainId.SKALE_EUROPA]: 'sFUEL',
};
