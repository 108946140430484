export const MULTISEND_DIFF_ETH = 'MULTISEND_DIFF_ETH';
export const MULTISEND_DIFF_TOKEN = 'MULTISEND_DIFF_TOKEN';
export const NAME_QUERY_KEY = 'NAME_QUERY_KEY';
export const APPROVE_MUTATION_KEY = 'APPROVE_MUTATION_KEY';
export const APPROVE_SIGNED_MUTATION_KEY = 'APPROVE_SIGNED_MUTATION_KEY';
export const SYMBOL_QUERY_KEY = 'SYMBOL_QUERY_KEY';
export const BALANCE_OF_QUERY_KEY = 'BALANCE_OF_QUERY_KEY';
export const DECIMALS_QUERY_KEY = 'DECIMALS_QUERY_KEY';
export const ALLOWANCE_KEY = 'ALLOWANCE_KEY';
export const TOTAL_AMAOUNT_KEY = 'TOTAL_AMAOUNT_KEY';
export const SUSHI_PAIRS_KEY = 'SUSHI_PAIRS_KEY';
export const SUSHI_FACTORY_PAIRS_KEY = 'SUSHI_FACTORY_PAIRS_KEY';
export const SUSHI_PAGINATION_PAIRS_KEY = 'SUSHI_PAGINATION_PAIRS_KEY';
export const SWAP_ETH_TO_TOKEN_KEY = 'SWAP_ETH_TO_TOKEN_KEY';
export const SWAP_TOKEN_TO_TOKEN_KEY = 'SWAP_TOKEN_TO_TOKEN_KEY';
export const SWAP_TOKEN_TO_ETH_KEY = 'SWAP_TOKEN_TO_ETH_KEY';
export const AMOUNT_OUT_KEY = 'AMOUNT_OUT_KEY';
export const FEE_PERCENTAGE_KEY = 'FEE_PERCENTAGE_KEY';
export const SUSHI_TOKENS_KEY = 'SUSHI_TOKENS_KEY';
export const RECIPIENT_BALANCE_KEY = 'RECIPIENT_BALANCE_KEY';
export const CLAIM_TOKENS_KEY = 'CLAIM_TOKENS_KEY';
export const CANCEL_CLAIM_TOKENS_KEY = 'CANCEL_CLAIM_TOKENS_KEY';
export const MIN_FEE_KEY = 'MIN_FEE_KEY';
export const SEND_ERC721_KEY = 'SEND_ERC721_KEY';
export const SEND_ERC1155_KEY = 'SEND_ERC1155_KEY';
export const SEND_NFTS_CLAIM_KEY = 'SEND_NFTS_CLAIM_KEY';
export const IS_APPROVED_FOR_ALL_QUERY_KEY = 'IS_APPROVED_FOR_ALL_QUERY_KEY';
export const APPROVE_ERC721_MUTATION_KEY = 'APPROVE_ERC721_MUTATION_KEY';
export const APPROVE_ERC1155_MUTATION_KEY = 'APPROVE_ERC1155_MUTATION_KEY';
export const DEPLOY_NEW_TOKEN_KEY = 'DEPLOY_NEW_TOKEN_KEY';
export const GET_USER_COLLECTIONS = 'GET_USER_COLLECTIONS';
export const GET_CONTRACT_URI_ERC721 = 'GET_CONTRACT_URI_ERC721';
export const GET_CONTRACT_URI_ERC1155 = 'GET_CONTRACT_URI_ERC1155';
export const MINT_ERC721_TOKEN_KEY = 'MINT_ERC721_TOKEN_KEY';
export const MINT_ERC1155_TOKEN_KEY = 'MINT_ERC1155_TOKEN_KEY';
