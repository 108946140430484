import {
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  EDIT_TEAM_REQUEST,
  EDIT_TEAM_SUCCESS,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
} from '../../constants/actions';

const initialState = {
  teams: [],
  isLoading: false,
  error: null,
};

export const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS_REQUEST:
    case ADD_TEAM_REQUEST:
    case DELETE_TEAM_REQUEST:
    case EDIT_TEAM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        isLoading: false,
      };
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.payload],
        isLoading: false,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== action.payload),
        isLoading: false,
      };
    case EDIT_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.map((team) => (team.id === action.payload.id ? action.payload : team)),
        isLoading: false,
      };
    default:
      return state;
  }
};
