import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/Table/Table';
import { Row } from './TeamTab/Row';
import { useWeb3 } from 'src/hooks/useWeb3';
import {
  addTeamAction,
  deleteTeamAction,
  editTeamAction,
  getTeamsAction,
} from '../../../redux/teams/actions';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'second_name',
    numeric: false,
    disablePadding: false,
    label: 'Surname',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

export const roles = [
  {
    name: 'Guest',
    id: 'guest',
  },
  {
    name: 'Accountant',
    id: 'accountant',
  },
  {
    name: 'Admin',
    id: 'admin',
  },
];

export const TeamTab = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const teams = useSelector((state) => state.teamsReducer.teams);

  // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamsAction());
  }, [dispatch]);

  useEffect(() => {
    setTableData(teams);
  }, [teams]);

  const deleteMember = () => {
    dispatch(deleteTeamAction(selectedRows.map(({ id }) => id)));
  };

  const handleSaveEditRow = (values, isEdit, isNew) => {
    if (isEdit && !isNew) {
      const currentItem = tableData.find(({ id }) => id === values.id);
      const result = tableData.filter(({ id }) => id !== currentItem.id);
      dispatch(editTeamAction(values));
      setTableData(() => [...result, { ...currentItem, isEdit: false, isNew: false }]);
      return;
    }
    dispatch(addTeamAction(values));
  };

  return (
    <Stack mt={1}>
      <EnhancedTable
        setTableData={setTableData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        page={page}
        setPage={setPage}
        headCells={headCells}
        data={tableData}
        handleSaveEditRow={handleSaveEditRow}
        deleteHandler={deleteMember}
        exportFile={false}
        emptyStatusText="You haven't created a team member yet."
        RowComponent={Row}
        addRowTemplate={{
          id: null,
          name: '',
          second_name: '',
          email: '',
          role: '',
        }}
      />
    </Stack>
  );
};
