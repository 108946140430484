import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Autocomplete,
  CircularProgress,
  Stack,
  Button,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { setUserBalance } from 'src/redux/actions';
import { formatEther, parseUnits } from 'ethers/lib/utils';
import { useMultiSendContract, usePoapLinksSignContract } from 'src/hooks/useContract';
import { useMutation, useQuery } from 'react-query';
import { buildQuery, getCommissionFee } from 'src/utils/index';
import {
  calculateCommissionFee,
  getNonHumanValue,
  getNonHumanValueSumm,
  getTokensByChainId,
  calculateDecimalsPlaces,
  getHumanValue,
} from 'src/utils';
import { isSupportedChain, SupportedChainId } from 'src/constants/chains';
import { TOKENS, ZERO_ADDRESS } from 'src/constants/tokens';
import { isAddress } from '@ethersproject/address';
import EnhancedTable from 'src/components/Table/Table';
import { Row } from './RowComponent';
import { ImportRecipientsModal } from './ImportRecipientsModal';
import { DownloadExampleModal } from './DownloadExampleModal';
import { convertToFloat } from 'src/commonHelpers/commonHelpers';
import { useWeb3 } from 'src/hooks/useWeb3';
import useTokenData from 'src/hooks/useTokenData';
import { MULTI_SEND_CONTRACTS, MULTI_SEND_CONTRACTS_TRON } from 'src/constants/addresses';
import useTokenDataTron from 'src/hooks/useTokenDataTron';
import { ConnectionType } from 'src/connection';
import { Provider as AptopProvider, Network } from 'aptos';
import { MaxUint256 } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import MULTI_SEND_ABI from '../../../web3/abi/MultiSend.json';
import * as sapphire from '@oasisprotocol/sapphire-paratime';
import { getAprosPetraProvider } from '../../../utils/providers';
import {
  MULTISEND_DIFF_ETH,
  MULTISEND_DIFF_TOKEN,
  TOTAL_AMAOUNT_KEY,
} from '../../../constants/queryKeys';
import Modal from '@mui/material/Modal';
import { useDebounceFunction } from 'src/hooks/useDebounce';
import { AlertComponent } from 'src/components/alert/alert';
import { SuccessMessage } from 'src/components/SuccessMessage';
import { DownloadWallets } from 'src/helpers/DownloadWallets';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  width: '400px',
  zIndex: 10000,
};

export default function EmployeesTable({ tableHead, data, wallet, userBalance, employees }) {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [addressType, setAddressType] = useState(true);
  const [isNativeToken, setIsNativeToken] = useState(true);
  const [nativeTokenDecimals, setNativeTokenDecimals] = useState(18);
  const [tokens, setTokens] = useState(null);
  const [isNativeTokenSelected, setIsNativeTokenSelected] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [customAddress, setCustomAddress] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [isDecimalsError, setIsDecimalsError] = useState(false);
  const [aptosSymbol, setAptosSymbol] = useState('APT');
  const [notRegistredWallets, setNotRegistredWallets] = useState([]);
  const [aptosTokenDecimals, setAptosTokenDecimals] = useState(8);
  const [loader, setLoader] = useState({
    isLoading: false,
    text: '',
  });
  const [error, setError] = useState('');
  const [transactionSuccessMessage, setTransactionSuccessMessage] = useState('');
  const [transcationStatus, setTransacationStatus] = useState({ isOpen: false, hash: '' });

  const [unsupportedAmounts, setUnsupportedAmounts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [tableData, setTableData] = useState([]);

  const someIsEdit =
    tableData && tableData.length && tableData?.some((item) => item?.isEdit || item?.isNew);

  useEffect(() => {
    const sortedData = data.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true }),
    );
    setTableData(sortedData);
  }, [data]);

  const dispatch = useDispatch();

  const { t } = useTranslation('common');
  const [importSelected, setImportSelected] = useState([]);

  const {
    walletAddress: account,
    wallet: library,
    chainId,
    balance: walletBalance,
    isEvm,
    provider,
    walletType,
  } = useWeb3();

  console.log('provider', provider);

  const networkWithoutTotalAmount = [
    1666600000,
    128,
    1285,
    66,
    40,
    'TRON_GRID_MAINNET',
    97,
  ].includes(chainId);

  const isLoading = useSelector(({ employees: { isLoading } }) => isLoading);

  const addRowTemplate = {
    name: '',
    surname: '',
    position: '',
    network: '',
    wallet: '',
    amount: '',
    notes: '',
  };

  const {
    approve,
    approveSigned,
    isAllowed,
    refetchAllowance,
    tokenDecimals,
    tokenBalance,
    tokenAllowance,
    isExist,
    tokenNameLoading,
    tokenSymbolLoading,
    tokenDecimalsLoading,
    tokenSymbol: tokenSymbolData,
  } = useTokenData(isEvm ? tokenAddress : ZERO_ADDRESS, MULTI_SEND_CONTRACTS);

  const {
    approve: approveTron,
    isAllowed: isAllowedTron,
    refetchAllowance: refetchAllowanceTron,
    tokenDecimals: tokenDecimalsTron,
    tokenBalance: tokenBalanceTron,
    tokenAllowance: tokenAllowanceTron,
    isExist: isExistTron,
    tokenNameLoading: tokenNameLoadingTron,
    tokenSymbolLoading: tokenSymbolLoadingTron,
    tokenDecimalsLoading: tokenDecimalsLoadingTron,
    tokenSymbol: tokenSymbolDataTron,
  } = useTokenDataTron(!isEvm ? tokenAddress : ZERO_ADDRESS, MULTI_SEND_CONTRACTS_TRON);

  useEffect(() => {
    if (account && chainId) {
      if (!isSupportedChain(chainId)) {
        setTokens(null);
        setError('Network not supported');
        return;
      }
      findCoinSymbol('native');
      setTokens(getTokensByChainId(TOKENS, chainId));
    }
  }, [chainId, account]);

  const {
    multiSendDiffToken: multiSendDiffTokenQuery,
    multiSendDiffEth: multiSendDiffEthQuery,
    calculateTotalAmountTaxes: calculateTotalAmountTaxesQuery,
    // owner: ownerQuery,
    estimateGas: {
      multiSendDiffToken: multiSendDiffTokenEstimate,
      multiSendDiffEth: multiSendDiffEthEstimate,
    },
  } = useMultiSendContract();

  const { mutateAsync: multiSendDiffEth } = useMutation(
    `${MULTISEND_DIFF_ETH}`,
    ({ employeesWallets, employeesParsedAmounts, value, gasLimit }) =>
      buildQuery(
        multiSendDiffEthQuery,
        [employeesWallets, employeesParsedAmounts],
        gasLimit || chainId === SupportedChainId.OASIS_SAPPHIRE ? null : multiSendDiffEthEstimate,
        gasLimit
          ? {
              value,
              gasLimit,
            }
          : {
              value,
            },
      ),
    {
      onError: (err) => console.log(err, `${MULTISEND_DIFF_ETH}`),
    },
  );

  const { mutateAsync: multiSendDiffToken } = useMutation(
    `${MULTISEND_DIFF_TOKEN}_${tokenAddress}`,
    ({ employeesWallets, employeesParsedAmounts, gasLimit }) =>
      buildQuery(
        multiSendDiffTokenQuery,
        [employeesWallets, employeesParsedAmounts, tokenAddress],
        gasLimit || chainId === SupportedChainId.OASIS_SAPPHIRE ? null : multiSendDiffTokenEstimate,
        gasLimit && { gasLimit },
      ),
    {
      onError: (err) => console.log(err, `${MULTISEND_DIFF_TOKEN}_${tokenAddress}`),
    },
  );

  const getAptosTokenDecimals = async () => {
    const provider = new AptopProvider(Network.MAINNET);

    const accountResources = await provider.getAccountCoinsData(account);

    console.log('current accountResources', accountResources);

    let coinDecimals = 8;

    if (accountResources?.current_coin_balances && accountResources?.current_coin_balances.length) {
      const currentDecimals = accountResources?.current_coin_balances.find(
        (item) => item.coin_type === tokenAddress,
      );

      if (currentDecimals && currentDecimals.coin_info) {
        coinDecimals = currentDecimals.coin_info.decimals;
      }
    }

    setAptosTokenDecimals(coinDecimals);
  };

  useEffect(() => {
    if (walletType === ConnectionType.APTOS_PETRA) {
      getAptosTokenDecimals();
    }
  }, [walletType, account, tokenAddress]);

  useEffect(() => {
    if (tokens && tokens.length) {
      if (tokens[0].address === 'native') {
        setIsNativeToken(true);
        setIsNativeTokenSelected(true);
        setTokenAddress('');
      } else {
        setIsNativeTokenSelected(false);
        setTokenAddress(tokens[0].address);
        setIsNativeToken(false);
      }
      setTokenSymbol(tokens[0].symbol);
    }
  }, [tokens]);

  const handleSaveEditRow = (values, isEdit, isNew) => {
    if (isEdit && !isNew) {
      const currentItem = tableData.find(({ id }) => id === values.id);
      const filteredData = tableData.filter(({ id }) => id !== currentItem.id);

      const payload = {
        ...values,
        amount: convertToFloat(values.amount),
        notes: values.notes,
        id: currentItem.id,
      };
      dispatch({ type: 'EDIT_TRANSFER_SAGA', payload, flag: 'edit' });

      setTableData([...filteredData, { ...currentItem, isEdit: false }]);
      return;
    }

    dispatch({
      type: 'ADD_TRANSFER_SAGA',
      payload: { ...values, amount: convertToFloat(values.amount) },
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = employees.map(({ id }) => id);
      setImportSelected(newSelecteds);
      return;
    }
    setImportSelected([]);
  };

  const checkedHandler = (event) => {
    if (!event?.target.checked) {
      setIsNativeToken(false);
      setIsNativeTokenSelected(false);
    } else {
      if (tokens && tokens.length) {
        const token = tokens.find(({ address }) => 'native' === address);
        if (token && token.symbol) {
          setTokenSymbol(token?.symbol);
        }
      }
      setIsNativeToken(true);
      setIsNativeTokenSelected(true);
    }

    setTokenAddress('');
    setAddressType(event?.target.checked);
  };

  const handleCustomAddress = async () => {
    if (isAddress(customAddress)) {
      setIsNativeTokenSelected(false);
      setTokenAddress(customAddress);
      setIsNativeToken(false);
    } else {
      // dispatch(updateConnectionError({ connectionType, error: 'not-valid-address' }));
    }
  };

  // const getAccountInfo = async () => {
  //   if (!account) {
  //     toast.error('Connect metamask!');
  //     return;
  //   }
  //   if (walletBalance === 0 || walletBalance < totalAmount) {
  //     toast.error('Not enough funds!');
  //     return;
  //   }
  //   const employeesWallets = selectedRows.map(({ wallet }) => wallet);
  //   const employeesParsedAmounts = selectedRows.map(({ amount }) =>
  //     ethers.utils.parseUnits(amount.toString(), 18),
  //   );
  //   const employeesTotalAmounts = selectedRows
  //     .map(({ amount }) => +amount)
  //     .reduce(function (a, b) {
  //       return a + b;
  //     })
  //     .toString();

  //   const value = parseUnits(employeesTotalAmounts, 18);

  //   const tx = await multiSendDiffEth({ employeesWallets, employeesParsedAmounts, value });
  //   const receipt = await tx.wait();
  //   dispatch({
  //     type: 'ADD_PAYMENT',
  //     payload: receipt.transactionHash,
  //     transfers: selectedRows.map(({ id }) => id),
  //     wallet,
  //   });
  //   setTimeout(async () => {
  //     const balance = await library.getBalance(account);
  //     dispatch(setUserBalance(formatEther(balance.toString())));
  //   }, 20000);
  // };

  useEffect(() => {
    if (isSupportedChain(chainId) && !tokenAddress && tokens) {
      const currentToken = tokens.find((item) => item.address === 'native');

      if (currentToken?.decimals) {
        setNativeTokenDecimals(currentToken?.decimals);
      }
    }
  }, [chainId, tokens, tokenAddress]);

  const totalAmount =
    selectedRows.length > 0 && account
      ? convertToFloat(
          selectedRows
            .map(({ amount }) => +amount)
            .reduce(function (a = 0, b = 0) {
              return a + b;
            })
            .toString(),
        )
      : 0;

  const amounts = useMemo(() => {
    setIsDecimalsError(false);
    if ((isEvm && nativeTokenDecimals) || (isEvm && tokenDecimals)) {
      return selectedRows.map(({ amount }) =>
        getNonHumanValue(amount, isNativeToken ? nativeTokenDecimals : tokenDecimals).toString(),
      );
    } else {
      if (walletType === ConnectionType.TRONLINK) {
        const unsupportedAmounts = [];

        for (let i = 0; i < selectedRows.length; i++) {
          const amount = selectedRows[i].amount;
          const wallet = selectedRows[i].wallets;
          const isUnsupported = calculateDecimalsPlaces(
            String(amount),
            isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
          );

          console.log('isUnsupported', isUnsupported);

          if (isUnsupported) {
            unsupportedAmounts.push({ wallet });
          }
        }

        console.log('unsupportedAmounts', unsupportedAmounts);

        if (unsupportedAmounts.length) {
          console.log('unsupportedAmounts.length', unsupportedAmounts.length);
          setError('Max decimals value for this token');
          setIsDecimalsError(true);
          return 0;
        }
        console.log('AFTER unsupportedAmounts');
        return selectedRows.map(({ amount }) =>
          getNonHumanValue(
            amount,
            isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
          ).toString(),
        );
        //     // return provider.toSun(amounts);
      }
      if (walletType === ConnectionType.APTOS_PETRA) {
        const unsupportedAmounts = [];

        for (let i = 0; i < selectedRows.length; i++) {
          const amount = selectedRows[i].amount;
          const wallet = selectedRows[i].wallets;
          const isUnsupported = calculateDecimalsPlaces(String(amount), aptosTokenDecimals);

          if (isUnsupported) {
            unsupportedAmounts.push({ wallet });
          }
        }

        if (unsupportedAmounts.length) {
          setError('Max decimals value for this token');
          setIsDecimalsError(true);
          return 0;
        }

        return selectedRows.map(({ amount }) =>
          getNonHumanValue(amount, aptosTokenDecimals).toString(),
        );
      }
    }
  }, [
    selectedRows,
    isNativeToken,
    nativeTokenDecimals,
    tokenDecimals,
    tokenDecimalsTron,
    isEvm,
    walletType,
    provider,
    aptosTokenDecimals,
  ]);

  const amountsSumm = useMemo(() => {
    if (amounts && amounts.length) {
      return getNonHumanValueSumm(amounts).toString();
    } else return '0';
  }, [amounts, isNativeToken, nativeTokenDecimals, tokenDecimals, isEvm, walletType, provider]);

  const commissionFromAmount = useMemo(() => {
    const commission = getCommissionFee(amountsSumm).toString();
    return commission;
  }, [isNativeToken, tokenDecimals, amountsSumm, nativeTokenDecimals, chainId]);

  const totalAmountWithTaxes = useMemo(() => {
    if (!commissionFromAmount || !totalAmount) return '0';
    let totalAmountNonHuman = '0';

    if (walletType === ConnectionType.APTOS_PETRA) {
      const isUnsupported = calculateDecimalsPlaces(String(totalAmount), aptosTokenDecimals);

      totalAmountNonHuman = getNonHumanValue(
        String(totalAmount).includes('e')
          ? String(Number(totalAmount).toFixed(0))
          : isUnsupported
          ? String(Number(totalAmount).toFixed(aptosTokenDecimals))
          : String(totalAmount),
        aptosTokenDecimals,
      ).toString();

      return getHumanValue(
        getNonHumanValueSumm([commissionFromAmount, totalAmountNonHuman]).toString(),
        aptosTokenDecimals,
      ).toString();
    } else if (walletType === ConnectionType.TRONLINK) {
      const isUnsupported = calculateDecimalsPlaces(
        String(totalAmount),
        isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
      );

      console.log('totalAmountWithTaxes isUnsupported', isUnsupported);

      console.log(
        'isNativeToken ? nativeTokenDecimals : tokenDecimalsTron',
        isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
      );

      totalAmountNonHuman = getNonHumanValue(
        String(totalAmount).includes('e')
          ? String(Number(totalAmount).toFixed(0))
          : isUnsupported
          ? String(
              Number(totalAmount).toFixed(isNativeToken ? nativeTokenDecimals : tokenDecimalsTron),
            )
          : String(totalAmount),
        isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
      ).toString();

      return getHumanValue(
        getNonHumanValueSumm([commissionFromAmount, totalAmountNonHuman]).toString(),
        isNativeToken ? nativeTokenDecimals : tokenDecimalsTron,
      ).toString();
    } else {
      const isUnsupported = calculateDecimalsPlaces(
        String(totalAmount),
        isNativeToken ? nativeTokenDecimals : tokenDecimals,
      );

      // console.log('totalAmountWithTaxes isUnsupported', isUnsupported);

      // console.log(
      //   'isNativeToken ? nativeTokenDecimals : tokenDecimals',
      //   isNativeToken ? nativeTokenDecimals : tokenDecimals,
      // );

      totalAmountNonHuman = getNonHumanValue(
        String(totalAmount).includes('e')
          ? String(Number(totalAmount).toFixed(0))
          : isUnsupported
          ? String(Number(totalAmount).toFixed(isNativeToken ? nativeTokenDecimals : tokenDecimals))
          : String(totalAmount),
        isNativeToken ? nativeTokenDecimals : tokenDecimals,
      ).toString();

      return getHumanValue(
        getNonHumanValueSumm([commissionFromAmount, totalAmountNonHuman]).toString(),
        isNativeToken ? nativeTokenDecimals : tokenDecimals,
      ).toString();
    }
  }, [
    commissionFromAmount,
    totalAmount,
    isNativeToken,
    tokenDecimals,
    nativeTokenDecimals,
    aptosTokenDecimals,
    walletType,
    tokenDecimalsTron,
  ]);

  // const totalAmountWithTaxes = useMemo(() => {
  //   if (!commissionFromAmount || !totalAmount) return '0';

  //   const totalAmountNonHuman = getNonHumanValue(
  //     String(totalAmount).includes('e')
  //       ? String(Number(totalAmount).toFixed(5))
  //       : String(totalAmount),
  //     isNativeToken ? nativeTokenDecimals : tokenDecimals,
  //   ).toString();
  //   return getHumanValue(
  //     getNonHumanValueSumm([commissionFromAmount, totalAmountNonHuman]).toString(),
  //     isNativeToken ? nativeTokenDecimals : tokenDecimals,
  //   ).toString();
  // }, [commissionFromAmount, totalAmount, isNativeToken, tokenDecimals, nativeTokenDecimals]);

  // const commissionFromAmount = useMemo(() => {
  //   const commission = getHumanValue(
  //     getCommissionFee(amountsSumm).toString(),
  //     isNativeToken ? nativeTokenDecimals : tokenDecimals,
  //   ).toString();

  //   return commission;
  // }, [isNativeToken, tokenDecimals, amountsSumm, nativeTokenDecimals, chainId]);

  const isApprovedEnough = useMemo(() => {
    if (isEvm) {
      if (tokenAllowance && amountsSumm) {
        return !isNativeToken && isAllowed && +tokenAllowance.toString() >= +amountsSumm;
      } else return null;
    } else {
      if (walletType === ConnectionType.TRONLINK && tokenAllowanceTron && amountsSumm) {
        return !isNativeToken && isAllowedTron && +tokenAllowanceTron.toString() >= +amountsSumm;
      } else return null;
    }
  }, [
    amountsSumm,
    isAllowed,
    isAllowedTron,
    isNativeToken,
    tokenAllowance,
    tokenAllowanceTron,
    isEvm,
    walletType,
  ]);

  const { data: totalAmountTaxes, refetch: refetchTotalAmountTaxes } = useQuery(
    `${TOTAL_AMAOUNT_KEY}_${tokenAddress}_${account}`,
    () =>
      buildQuery(calculateTotalAmountTaxesQuery, [
        selectedRows.map(({ wallet }) => wallet),
        selectedRows.map(({ amount }) => amount),
      ]),
    {
      enabled:
        !!account && !!tokenAddress && !!selectedRows.map(({ wallet }) => wallet).length && !!isEvm,
      onError: (err) => {
        setError('User rejected transaction');
        console.log(err, `${TOTAL_AMAOUNT_KEY}_${tokenAddress}_${account}`);
      },
    },
  );

  const throttleTotalAmountQuery = useDebounceFunction(refetchTotalAmountTaxes, 300);

  useEffect(() => {
    if (selectedRows && selectedRows.length) {
      throttleTotalAmountQuery();
    } else {
      refetchTotalAmountTaxes();
    }
  }, [selectedRows]);

  let totalSumm = '0';
  if (totalAmountTaxes && totalAmountTaxes.length && isEvm) {
    totalSumm = getHumanValue(
      getNonHumanValueSumm(totalAmountTaxes).toString(),
      isNativeToken ? nativeTokenDecimals : tokenDecimals,
    ).toString();
  }

  const selectImportUser = (id) => {
    if (importSelected.includes(id)) {
      const filteredItems = importSelected.filter((item) => item !== id);
      setImportSelected(filteredItems);
      return;
    }
    setImportSelected((prev) => [...prev, id]);
  };

  const setTokenAddressHandler = (address) => {
    if (address === 'native') {
      setIsNativeToken(true);
      setIsNativeTokenSelected(true);
      setTokenAddress('');
    } else {
      setIsNativeTokenSelected(false);
      setTokenAddress(address);
      setIsNativeToken(false);
    }
  };

  const findCoinSymbol = (value) => {
    if (value && tokens) {
      const token = tokens.find(({ address }) => value === address);

      if (token) {
        setTokenSymbol(token?.symbol);
        return;
      }
    }
    return;
  };

  const deleteDispatchTransfers = () =>
    dispatch({
      type: 'DELETE_TRANSFER_MULTI',
      payload: selectedRows.map((item) => item.id),
    });

  const handleWalletModal = useCallback(() => {
    setOpenWalletModal((prev) => !prev);
  }, []);

  const approveHandler = async () => {
    if (isEvm) {
      handleWalletModal();
    }
    setLoader({ isLoading: true, text: 'Token approval' });

    if (!account) {
      alert('wallet not connected');
      setLoader({ isLoading: false, text: '' });
      return;
    }

    if (isEvm) {
      if (+tokenBalance.toString() === 0) {
        setLoader({ isLoading: false, text: '' });
        setError('Insufficient funds');
        return;
      }
    } else {
      if (+tokenBalanceTron === 0) {
        setLoader({ isLoading: false, text: '' });
        setError('Insufficient funds');
        return;
      }
    }

    try {
      if (chainId === SupportedChainId.OASIS_SAPPHIRE) {
        await approveSigned();
      } else {
        if (isEvm) {
          await approve(MaxUint256);
          refetchAllowance();
        } else {
          if (walletType === ConnectionType.TRONLINK) {
            // await approveTron('100000');
            await approveTron(MaxUint256);
            refetchAllowanceTron();
          }
        }
      }

      setLoader({ isLoading: false, text: '' });
      setSelectedRows([]);
    } catch (error) {
      console.log(`Token ${tokenAddress} approve error: `, error);
      setLoader({ isLoading: false, text: '' });
      setSelectedRows([]);
    }
  };

  const sendTransfer = async () => {
    if (!account) {
      alert('wallet not connected');
      return;
    }

    let receipt;

    setLoader({ isLoading: true, text: 'Transaction in progress' });

    const employeesWallets = selectedRows.map(({ wallet }) => wallet);

    if (isNativeToken) {
      let tx;
      if (isEvm) {
        const employeesParsedAmounts = selectedRows.map(({ amount }) =>
          getNonHumanValue(amount, nativeTokenDecimals).toString(),
        );

        const value = calculateCommissionFee(
          getNonHumanValueSumm(employeesParsedAmounts),
        ).toString();

        if (provider) {
          const balance = (await provider.getBalance(account)).toString();

          if (+balance === 0 || +value > +balance) {
            setLoader({ isLoading: false, text: '' });
            setError('Insufficient funds');
            return;
          }
        }

        if (SupportedChainId.OASIS_SAPPHIRE === chainId) {
          if (provider) {
            const contractMultiSend = new Contract(
              MULTI_SEND_CONTRACTS[chainId],
              MULTI_SEND_ABI,
              sapphire.wrap(provider.getSigner()),
            );
            tx = await buildQuery(
              contractMultiSend.multiSendDiffEth,
              [employeesWallets, employeesParsedAmounts],
              null,
              {
                value,
              },
            );
          }
        } else {
          tx = await multiSendDiffEth({
            employeesWallets: employeesWallets,
            employeesParsedAmounts,
            value,
          });
        }

        if (tx?.wait) {
          receipt = await tx.wait();
          if (receipt) {
            setLoader({ isLoading: false, text: '' });

            setSelectedRows([]);
            dispatch({
              type: 'ADD_PAYMENT_TRANSACTION',
              payload: {
                transactionHash: receipt.transactionHash,
                chainId,
                transfers: selectedRows.map(({ id }) => id),
                wallet: account,
              },
              // transfers: selectedRows.map((transfer) => ({
              //   name: transfer.name,
              //   surname: transfer.surname,
              //   position: transfer.position,
              //   network: transfer.network,
              //   wallet: transfer.wallet,
              //   amount: transfer.amount,
              //   notes: transfer.notes,
              // })),
              // transfers: selectedRows.map(({ id }) => id),
              // wallet: account,
            });

            setTransactionSuccessMessage('Transaction success');
            setTransacationStatus({ isOpen: true, hash: receipt.transactionHash });
          }

          if (provider) {
            const _ = (await provider.getBalance(account)).toString();
            // successTransactionDate();
          }
        } else {
          setLoader({ isLoading: false, text: '' });
          setError(tx.messager ? tx.messager : 'User rejected transaction');
          setSelectedRows([]);
        }
      } else {
        if (walletType === ConnectionType.TRONLINK) {
          const employeesParsedAmounts = selectedRows.map(({ amount }) => provider.toSun(amount));

          const value = calculateCommissionFee(
            getNonHumanValueSumm(employeesParsedAmounts),
          ).toString();

          if (walletBalance !== null) {
            if (+walletBalance === 0 || +value > +walletBalance) {
              setLoader({ isLoading: false, text: '' });
              setError('Insufficient funds');
              return;
            }
          }

          let transactionHashTron = null;

          window.addEventListener('message', function (e) {
            if (e.data.message && e.data.message.action == 'tabReply') {
              if (e.data.message.data.success) {
                transactionHashTron = e.data.message.data.data.txID;
              }
            }
          });

          try {
            const contract = await provider.contract(
              MULTI_SEND_ABI,
              MULTI_SEND_CONTRACTS_TRON[chainId],
            );

            tx = await contract.multiSendDiffEth(employeesWallets, employeesParsedAmounts).send({
              callValue: value,
              shouldPollResponse: true,
            });

            if (tx) {
              setLoader({ isLoading: false, text: '' });

              setSelectedRows([]);
              setTransactionSuccessMessage('Transaction success');
              if (transactionHashTron) {
                dispatch({
                  type: 'ADD_PAYMENT_TRANSACTION',
                  payload: {
                    transactionHash: receipt.transactionHash,
                    chainId,
                    transfers: selectedRows.map(({ id }) => id),
                    wallet: account,
                  },
                });
                setTransacationStatus({ isOpen: true, hash: transactionHashTron });
              }

              // successTransactionDate();
            } else {
              setLoader({ isLoading: false, text: '' });
              setError('Insufficient funds');
              setSelectedRows([]);
            }
          } catch (error) {
            setLoader({ isLoading: false, text: '' });
            setError('Insufficient funds');
            setSelectedRows([]);
          }
        }
        if (walletType === ConnectionType.APTOS_PETRA) {
          try {
            setNotRegistredWallets([]);

            const provider = new AptopProvider(Network.MAINNET);

            const wallet = getAprosPetraProvider(); // see "Connecting"

            let isCoinRegistred = true;

            let unRegWallets = [];

            for (const userWallet of employeesWallets) {
              const userAccountResources = await provider.getAccountCoinsData(userWallet);
              if (
                userAccountResources.current_coin_balances &&
                userAccountResources.current_coin_balances.length
              ) {
                const isExistCoin = userAccountResources.current_coin_balances.find(
                  (item) => item.coin_type === '0x1::aptos_coin::AptosCoin',
                );
                if (!isExistCoin) {
                  isCoinRegistred = false;
                  unRegWallets.push(userWallet);
                }
              } else {
                unRegWallets.push(userWallet);
                isCoinRegistred = false;
              }
            }

            if (unRegWallets.length) {
              setNotRegistredWallets(unRegWallets);
            }

            if (!isCoinRegistred) {
              setLoader({ isLoading: false, text: '' });
              setSelectedRows([]);
              setError('Coin not registred in minimum one wallet.');
              return;
            }

            const accountResources = await provider.getAccountCoinsData(account);

            const employeesParsedAmounts = selectedRows.map(({ amount }) =>
              getNonHumanValue(amount, 8).toString(),
            );

            const transaction = {
              arguments: [employeesWallets, employeesParsedAmounts],
              function:
                '0x9e2c76d559f4b68ab913f3fd2d25b92acbcb5f6bba6e8e5f0f9c996cfa921e0d::sender::multisend',
              type: 'entry_function_payload',
              type_arguments: ['0x1::aptos_coin::AptosCoin'],
            };

            const pendingTransaction = await wallet.signAndSubmitTransaction(transaction);

            const tx = await provider.waitForTransactionWithResult(pendingTransaction.hash);
            console.log('tx', tx);
            if (tx && tx.version) {
              setLoader({ isLoading: false, text: '' });
              setSelectedRows([]);
              dispatch({
                type: 'ADD_PAYMENT_TRANSACTION',
                payload: {
                  transactionHash: receipt.transactionHash,
                  chainId,
                  transfers: selectedRows.map(({ id }) => id),
                  wallet: account,
                },
              });
              setTransactionSuccessMessage('Transaction success');
              setTransacationStatus({ isOpen: true, hash: tx.version });
            } else {
              setLoader({ isLoading: false, text: '' });
              setError('Insufficient funds');
              setSelectedRows([]);
            }
          } catch (error) {
            setLoader({ isLoading: false, text: '' });
            setError(error.name);
            setSelectedRows([]);
          }
        }
      }
    } else {
      if (isEvm) {
        const unsupportedAmounts = [];

        for (let i = 0; i < selectedRows.length; i++) {
          const amount = selectedRows[i].amount;
          const wallet = selectedRows[i].wallet;
          const isUnsupported = calculateDecimalsPlaces(String(amount), tokenDecimals);
          if (isUnsupported) {
            unsupportedAmounts.push({ wallet });
          }
        }

        if (unsupportedAmounts.length) {
          setUnsupportedAmounts(unsupportedAmounts);
          setLoader({ isLoading: false, text: '' });
          return;
        }

        const employeesParsedAmounts = selectedRows.map(({ amount }) =>
          getNonHumanValue(amount, tokenDecimals).toString(),
        );

        const amountsSumm = getNonHumanValueSumm(employeesParsedAmounts).toString();

        if (+tokenBalance.toString() === 0 || +amountsSumm > +tokenBalance.toString()) {
          console.log('BALANCE ERROR');
          setLoader({ isLoading: false, text: '' });
          setError('Insufficient funds');
          return;
        }

        let tx = await multiSendDiffToken({
          employeesWallets: employeesWallets,
          employeesParsedAmounts,
        });

        if (tx?.wait) {
          receipt = await tx.wait();

          if (receipt) {
            setLoader({ isLoading: false, text: '' });
            setSelectedRows([]);
            dispatch({
              type: 'ADD_PAYMENT_TRANSACTION',
              payload: {
                transactionHash: receipt.transactionHash,
                chainId,
                transfers: selectedRows.map(({ id }) => id),
                wallet: account,
              },
            });
            setTransactionSuccessMessage('Transaction success');
            setTransacationStatus({ isOpen: true, hash: receipt.transactionHash });
          }

          if (provider) {
            const _ = (await provider.getBalance(account)).toString();
            // successTransactionDate();
          }
        } else {
          setLoader({ isLoading: false, text: '' });
          setError(tx.message);
          setSelectedRows([]);
        }
      } else {
        if (walletType === ConnectionType.TRONLINK) {
          const employeesParsedAmounts = selectedRows.map(({ amount }) =>
            // provider.toSun(amount),
            getNonHumanValue(amount, tokenDecimalsTron).toString(),
          );

          const amountsSumm = getNonHumanValueSumm(employeesParsedAmounts).toString();

          let transactionHashTron = null;

          window.addEventListener('message', function (e) {
            if (e.data.message && e.data.message.action == 'tabReply') {
              if (e.data.message.data.success) {
                transactionHashTron = e.data.message.data.data.txID;
              }
            }
          });

          if (+tokenBalanceTron.toString() === 0 || +amountsSumm > +tokenBalanceTron.toString()) {
            setLoader({ isLoading: false, text: '' });
            setError('Insufficient funds');
            return;
          }

          let tx;

          try {
            const contract = await provider.contract(
              MULTI_SEND_ABI,
              MULTI_SEND_CONTRACTS_TRON[chainId],
            );

            tx = await contract
              .multiSendDiffToken(employeesWallets, employeesParsedAmounts, tokenAddress)
              .send({
                shouldPollResponse: true,
              });

            if (tx) {
              setLoader({ isLoading: false, text: '' });
              setSelectedRows([]);
              setTransactionSuccessMessage('Transaction success');
              if (transactionHashTron) {
                dispatch({
                  type: 'ADD_PAYMENT_TRANSACTION',
                  payload: {
                    transactionHash: receipt.transactionHash,
                    chainId,
                    transfers: selectedRows.map(({ id }) => id),
                    wallet: account,
                  },
                });
                setTransacationStatus({ isOpen: true, hash: transactionHashTron });
              }

              // successTransactionDate();
            } else {
              setLoader({ isLoading: false, text: '' });
              setError(tx.message);
              setSelectedRows([]);
            }
          } catch (error) {
            console.log('multiSendDiffToken tron error', error);

            setLoader({ isLoading: false, text: '' });
            setError(error);
            setSelectedRows([]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (walletType === ConnectionType.APTOS_PETRA) {
      const provider = new AptopProvider(Network.MAINNET);

      provider.getAccountCoinsData(account).then((accountResources) => {
        if (
          accountResources.current_coin_balances &&
          accountResources.current_coin_balances.length
        ) {
          const currentToken = accountResources.current_coin_balances.find(
            (item) => item.coin_type === tokenAddress,
          );

          if (currentToken && currentToken.coin_info && currentToken.coin_info.symbol) {
            setAptosSymbol(currentToken.coin_info.symbol);
          } else {
            setAptosSymbol('APT');
          }
        } else {
          setAptosSymbol('APT');
        }
      });
    }
  }, [walletType, account, tokenAddress]);

  const getTokenSymbol = useMemo(() => {
    if (isEvm) {
      if (!addressType && !isNativeToken) {
        return tokenSymbolData ? tokenSymbolData : '';
      } else {
        return tokenSymbol;
      }
    } else {
      if (walletType === ConnectionType.TRONLINK) {
        if (!addressType && !isNativeToken) {
          return tokenSymbolDataTron ? tokenSymbolDataTron : '';
        } else {
          return tokenSymbol;
        }
      }
      if (walletType === ConnectionType.APTOS_PETRA) {
        return aptosSymbol;
      }
    }
  }, [
    addressType,
    isNativeToken,
    tokenSymbolData,
    tokenSymbol,
    tokenSymbolDataTron,
    isEvm,
    walletType,
    aptosSymbol,
  ]);

  const handleSuccessAlert = useCallback(() => {
    setTransactionSuccessMessage('');
    setTransacationStatus({ isOpen: false, hash: '' });
  }, []);

  const handleCloseErrorAlert = () => {
    setError('');
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <DownloadExampleModal open={open} setOpen={setOpen} />
      {loader.isLoading && (
        <Stack sx={{ width: '100%' }} mb={{ xs: 2, md: 3 }}>
          <AlertComponent icon={false} severity="info">
            <Stack direction="row" alignItems="center" gap={2}>
              <CircularProgress size="17px" />
              <Typography variant="body2">{loader.text}</Typography>
            </Stack>
          </AlertComponent>
        </Stack>
      )}
      {error ? (
        <Stack mb={{ xs: 2, md: 3 }} sx={{ width: '100%' }}>
          <AlertComponent onClose={() => handleCloseErrorAlert()} severity="error">
            <Stack sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Typography variant="body2">{error}</Typography>

              {notRegistredWallets.length ? (
                <DownloadWallets wallets={notRegistredWallets} name="Not_registred_wallets" />
              ) : null}
            </Stack>
          </AlertComponent>
        </Stack>
      ) : null}
      {transactionSuccessMessage && (
        <SuccessMessage
          handleClose={handleSuccessAlert}
          message={transactionSuccessMessage}
          hash={transcationStatus.hash}
        />
      )}
      <Stack
        sx={{
          width: '100%',
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: {
            xs: '1fr 1fr',
            sm: '1fr 1fr 1fr ',
            md: addressType ? `1fr 0.6fr 1fr 1fr 1.6fr` : `1fr 1fr 1fr 1fr 1fr`,
          },
          gridTemplateRows: '1fr',
          gap: 1,
          mb: { xs: 2, md: 3 },
          gridTemplateAreas: {
            xs: addressType
              ? `"import add"
              "switch upload"
               "make make"
               "coins total"`
              : `"import add"
              "make upload"
              "address address"
              "switch load"
              "total total"`,
            sm: addressType
              ? `"import add ."
              "switch upload  make"
                 "coins coins coins"
                 "total total total"`
              : `"import add ."
              "upload  make load"
              "switch address address"
              "total total total"`,
            md: addressType
              ? `"import add . . ."
                "upload switch coins make total"`
              : `"import . . . . . ." 
                "upload switch address address load make total"`,
          },
        }}
      >
        <Stack gridArea={'upload'}>
          <Tooltip
            arrow
            title="You can upload the file to avoid manual fulfilling of the table"
            placement="top"
          >
            <Button variant="contained" component="label" onClick={() => setOpen(true)}>
              Upload File
            </Button>
          </Tooltip>
        </Stack>
        <Stack gridArea={'import'}>
          <Tooltip
            arrow
            title="You can upload and select from a list of recipients, eliminating the need for manual table filling"
            placement="top"
          >
            <Button variant="contained" onClick={() => setIsImportOpen(true)} component="label">
              Import from Recipients
            </Button>
          </Tooltip>
        </Stack>
        <Stack gridArea={'switch'}>
          <Tooltip
            arrow
            title={addressType ? 'Switch to custom token' : 'Switch to token list'}
            placement="top"
          >
            <span>
              <FormControlLabel
                sx={{
                  fontSize: {
                    xs: '10px',
                    md: '10px',
                    display: 'flex',
                    width: 'fit-content',
                    flexDirection: 'row',
                  },
                }}
                labelPlacement="top"
                control={
                  <Switch
                    sx={{ fontSize: { xs: '10px', md: '10px' } }}
                    size="small"
                    checked={addressType}
                    onChange={(event) => {
                      checkedHandler(event);
                      setUnsupportedAmounts([]);
                    }}
                  />
                }
                label={
                  addressType ? (
                    <Typography variant="body2">Token list</Typography>
                  ) : (
                    <Typography variant="body2">Custom token</Typography>
                  )
                }
              />
            </span>
          </Tooltip>
        </Stack>
        {addressType ? (
          <>
            <Stack gridArea={'coins'}>
              <FormControl fullWidth size="small">
                <InputLabel
                  size="small"
                  id="demo-simple-select-label"
                  style={{ color: 'rgba(0, 0, 0, 0.38)' }}
                >
                  Coins
                </InputLabel>

                {!chainId ? (
                  <Tooltip title={t('alerts:please-connect-wallet')} placement="top">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Coins"
                      placeholder="Coins"
                      size="small"
                      value={tokenAddress}
                      disabled={!isSupportedChain(chainId)}
                      onChange={(event) => {
                        setTokenAddress(event.target.value);
                        findCoinSymbol(event.target.value);
                      }}
                    >
                      {tokens?.map((token, i) => (
                        <MenuItem key={`token-${i}`} value={token.address}>
                          {token.symbol}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                ) : (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Coins"
                    size="small"
                    placeholder="Coins"
                    value={tokenAddress ? tokenAddress : 'native'}
                    disabled={!isSupportedChain(chainId) || !tokens || isLoading}
                    onChange={(event) => {
                      setTokenAddressHandler(event.target.value);
                      findCoinSymbol(event.target.value);
                      setUnsupportedAmounts([]);
                    }}
                  >
                    {tokens?.map((token, i) => (
                      <MenuItem key={`token-${i}`} value={token.address}>
                        {token.symbol}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Stack>
            <Stack gridArea={'make'}>
              <Tooltip
                placement="top"
                arrow
                title={
                  isApprovedEnough
                    ? 'Select at least one transaction from the list below if you want to make a transfer.'
                    : 'Here you need to make an approve.'
                }
              >
                <span>
                  <Button
                    fullWidth
                    sx={{ color: 'white', textTransform: 'none' }}
                    variant="contained"
                    disabled={
                      // !(
                      //   (isSupportedChain(chainId) && tokenAddress) ||
                      //   (isSupportedChain(chainId) && isNativeTokenSelected)
                      // ) ||
                      // loader.isLoading ||
                      !account ||
                      someIsEdit ||
                      (isApprovedEnough && !selectedRows.length && !isNativeToken) ||
                      isDecimalsError ||
                      // (isNativeToken && !selectedRows.length) ||
                      !selectedRows.length
                        ? true
                        : false
                    }
                    onClick={
                      isApprovedEnough || isNativeToken
                        ? sendTransfer
                        : isEvm
                        ? handleWalletModal
                        : approveHandler
                    }
                  >
                    {isApprovedEnough || isNativeToken ? 'Make a transfer' : 'Approve token'}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
            {addressType && (
              <Stack gridArea="total">
                <div style={{ width: 'auto' }}>
                  <Typography
                    variant="body2"
                    sx={{ whiteSpace: { sm: 'nowrap' } }}
                    textAlign="right"
                  >
                    {'Total amount with fee'}:{' '}
                    {/* {Number(convertToFloat(+totalAmount + +commissionFromAmount)) +
                      ' ' +
                      (getTokenSymbol || '')} */}
                    {/* {totalAmountWithTaxes + ' ' + getTokenSymbol} */}
                    {networkWithoutTotalAmount
                      ? totalAmountWithTaxes + ' ' + getTokenSymbol
                      : totalSumm.includes('e')
                      ? Number(totalSumm).toFixed(10)
                      : totalSumm + ' ' + getTokenSymbol}
                  </Typography>
                </div>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Stack gridArea={'address'}>
              <FormControl fullWidth size="small">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t('common:address')}
                  size="small"
                  value={customAddress}
                  onChange={(e) => setCustomAddress(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack gridArea={'load'}>
              <Button
                disabled={isLoading}
                fullWidth
                onClick={handleCustomAddress}
                variant="contained"
              >
                Load
              </Button>
            </Stack>

            <Stack gridArea={'make'}>
              <Tooltip
                placement="top"
                arrow
                title={
                  isApprovedEnough
                    ? 'Select at least one transaction from the list below if you want to make a transfer.'
                    : 'Here you need to make an approve.'
                }
              >
                <span>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={
                      !(isSupportedChain(chainId) && tokenAddress) ||
                      !account ||
                      // loader.isLoading ||
                      someIsEdit ||
                      (isApprovedEnough && !selectedRows.length) ||
                      isDecimalsError ||
                      !selectedRows.length
                        ? true
                        : false
                    }
                    onClick={
                      isApprovedEnough ? sendTransfer : isEvm ? handleWalletModal : approveHandler
                    }
                  >
                    {isApprovedEnough ? 'Make a transfer' : 'Approve token'}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
            <Stack gridArea="total">
              <div style={{ width: 'auto' }}>
                <Typography variant="body2" sx={{ whiteSpace: { sm: 'nowrap' } }} textAlign="right">
                  {'Total amount with fee'}:{' '}
                  {/* {totalAmount ? Number(totalAmount) : totalAmount + ' ' + tokenSymbol} */}
                  {/* {convertToFloat(Number(+totalAmount + +commissionFromAmount)) +
                    ' ' +
                    (getTokenSymbol || '')} */}
                  {/* {totalAmountWithTaxes + ' ' + getTokenSymbol} */}
                  {networkWithoutTotalAmount
                    ? totalAmountWithTaxes + ' ' + getTokenSymbol
                    : totalSumm.includes('e')
                    ? Number(totalSumm).toFixed(10)
                    : totalSumm + ' ' + getTokenSymbol}
                </Typography>
              </div>
            </Stack>
          </>
        )}
      </Stack>
      <EnhancedTable
        setTableData={setTableData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        page={page}
        addRowTemplate={addRowTemplate}
        setPage={setPage}
        headCells={tableHead}
        data={tableData}
        handleSaveEditRow={handleSaveEditRow}
        deleteHandler={deleteDispatchTransfers}
        exportFile={true}
        emptyStatusText="Upload the list to make a transaction!"
        RowComponent={Row}
      />
      <ImportRecipientsModal
        open={isImportOpen}
        setOpen={setIsImportOpen}
        handleSelectAllClick={handleSelectAllClick}
        importSelected={importSelected}
        setImportSelected={setImportSelected}
        selectImportUser={selectImportUser}
        employees={employees}
      />
      <Modal
        open={openWalletModal}
        onClose={() => setOpenWalletModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Stack mb={{ xs: 2, md: 3 }} sx={style}>
            <Typography variant="body2" textAlign="center">
              The minimum amount to approve a transaction is <br />
              {totalSumm.includes('e')
                ? Number(totalSumm).toFixed(10)
                : totalSumm + ' ' + getTokenSymbol}
            </Typography>
            <Button
              sx={{ marginTop: '10px' }}
              fullWidth
              variant="contained"
              onClick={approveHandler}
            >
              Confirm
            </Button>
          </Stack>
        </>
      </Modal>
    </Stack>
  );
}
