import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { Popover, Stack, useMediaQuery } from '@mui/material';

const TextComponent = ({ text, type }) => {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const isLargeDesk = useMediaQuery('(max-width: 1400px)');
  const MAX_LENGTH = isLargeDesk ? 80 : 93;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {!isMobile && (
        <Stack mt={2}>
          <Collapse in={isExpanded} collapsedheight={56}>
            <Typography color="#E3E3E3" fontSize="12px !important" textAlign="justify">
              {text}
            </Typography>
          </Collapse>
          <Stack
            sx={{ boxSizing: 'border-box' }}
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
          >
            {!isExpanded && (
              <Collapse in={!isExpanded}>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    color: '#E3E3E3',
                    fontSize: '12px !important',
                    fontFamily: 'Public Sans, sans-serif',
                  }}
                >
                  {text.slice(0, MAX_LENGTH)}...
                </Typography>
                {/*<Typography color="#E3E3E3" fontSize="12px !important" sx={{ width: '100%', fontFamily: 'Public Sans, sans-serif' }}>*/}
                {/*  {text.slice(0, MAX_LENGTH)}...*/}
                {/*</Typography>*/}
              </Collapse>
            )}
            {text.length > MAX_LENGTH && (
              <Typography
                color="#E3E3E3"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
                fontSize="12px !important"
                onClick={handleToggle}
                size="small"
              >
                {isExpanded ? 'Hide' : 'View All'}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
      {isMobile && (
        <Stack
          sx={{ maxWidth: { xs: '100%', sm: '540px' }, margin: '8px auto 0' }}
          mt={2}
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography
            onClick={handleClick}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            color="#E3E3E3"
            fontSize="12px !important"
          >
            What is {type}?
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ boxShadow: 'none' }}
          >
            <Stack
              p={2}
              width="300px"
              sx={{
                color: '#E3E3E3',
                background: 'linear-gradient(180deg, #141728 0%, #124453 100%)',
                borderRadius: '8px',
                border: '1px solid #0000004D',
              }}
            >
              <Typography textAlign="justify" fontSize="12px !important">
                {text}
              </Typography>
            </Stack>
          </Popover>
        </Stack>
      )}
    </>
  );
};

export default TextComponent;
