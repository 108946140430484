// AddRowButton.js
import React from 'react';
import { Stack, Button, Grid } from '@mui/material';
import Iconify from '../../Iconify';

export const AddRowButton = ({ push, values, newObject }) => (
  <Grid
    container
    spacing={2}
    direction="column"
    alignItems="flex-start"
    justifyContent="flex-start"
  >
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <Stack
        sx={{ marginBottom: '130px' }}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {values.details.length < 5 && (
          <Button
            onClick={() => push(newObject)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill"/>}
          >
            Add row
          </Button>
        )}
      </Stack>
    </Grid>
  </Grid>
);
