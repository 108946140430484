import { useEffect, useRef, useState, FC, useContext, ReactNode, useMemo } from 'react';
import { WalletFactory } from '../../../wallets/wallet-factory';
import { WalletContext, WalletContextProps } from '../../../context/index';
import { updateWalletState } from 'src/redux/wallet/actions';
import { DEFAULT_CHAIN_ID } from '../../../constants/chains';
import { hexValue } from '@ethersproject/bytes';
import { ConnectionType } from '../../../connection/index';
import { getMetamaskProvider } from '../../../utils/providers';
import { Provider as AptopProvider, Network } from 'aptos';
import { getHumanValue } from '../../../utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function WalletProvider({ children }) {
  const res = useRef();
  if (!res.current) {
    res.current = WalletFactory.getWallet();
  }
  const currentWalletType = useRef('');

  const [wallet, setLocalWallet] = useState(res.current);
  const [walletError, setWalletError] = useState('');
  const [balance, setBalance] = useState('');
  const [chainId, setChainId] = useState(DEFAULT_CHAIN_ID);

  const dispatch = useDispatch();
  const {
    walletAddress,
    walletType,
    isEvm,
    provider: walletProvider,
    connector,
    chainId: walletChainId,
  } = useSelector(({ walletReducer }) => walletReducer);

  useEffect(() => {
    const savedWalletType = localStorage.getItem('walletType');

    if (savedWalletType) {
      const currentWallet = WalletFactory.getWallet(savedWalletType);

      if (currentWallet) {
        currentWallet.checkConnection().then(async (res) => {
          setWallet(currentWallet);

          currentWalletType.current = walletType;

          // const currentChainId = await res.getChainId();

          setChainId(walletChainId);

          let currentBalance;
          if (walletType === ConnectionType.APTOS_PETRA) {
            const provider = new AptopProvider(Network.MAINNET);

            const accountResources = await provider.getAccountCoinsData(walletAddress);

            if (
              accountResources.current_coin_balances &&
              accountResources.current_coin_balances.length &&
              accountResources.current_coin_balances.some(
                (item) => item.coin_type === '0x1::aptos_coin::AptosCoin',
              )
            ) {
              const nativeToken = accountResources.current_coin_balances.find(
                (item) => item.coin_type === '0x1::aptos_coin::AptosCoin',
              );

              if (nativeToken && nativeToken.coin_info) {
                currentBalance = nativeToken.amount;
              } else {
                currentBalance = 0;
              }
            } else {
              currentBalance = 0;
            }
          } else {
            currentBalance = await res.getBalance();
          }

          console.log('currentBalance', currentBalance);

          setBalance(currentBalance);
        });
      }
    }
    // }, [walletType, walletChainId]);
  }, [walletType, chainId, walletAddress]);

  const setWallet = (localWallet) => {
    setLocalWallet(localWallet);
  };

  useEffect(() => {
    addWalletListener();
  }, [walletType]);
  // }, [walletType, walletAddress, chainId]);

  const disconnectListener = (event) => {
    // console.log('disconnectListener', event);
  };
  const chainChangedListener = (event) => {
    console.log('chainChangedListener event, ', event);
    let networkId;
    if (typeof event === 'string' && event.includes('x')) {
      networkId = parseInt(event, 16);
    } else {
      networkId = event;
    }
    console.log('networkId', networkId);
    // console.log('networkId', networkId);

    setChainId(networkId);
  };
  const accountsChangedListener = (event) => {
    console.log('accountsChangedListener event, ', event);
    dispatch(
      updateWalletState({
        walletAddress: event[0],
        walletType: ConnectionType.WALLET_CONNECT,
        isEvm: true,
      }),
    );
  };
  const URIListener = (event) => {
    // console.log('event, ', event);
  };

  const addWalletListener = async () => {
    if (
      typeof window !== 'undefined' &&
      window.ethereum &&
      walletType === ConnectionType.METAMASK
    ) {
      getMetamaskProvider().on('accountsChanged', async (address) => {
        // console.log('accountsChanged');
        if (walletType !== ConnectionType.METAMASK) return;
        wallet.address = address;
        setWallet(wallet);
        dispatch(
          updateWalletState({
            walletAddress: address[0],
            walletType: ConnectionType.METAMASK,
            isEvm: true,
          }),
        );
        setWalletError('');
      });

      getMetamaskProvider().on('chainChanged', async (chainId) => {
        // console.log('chainChanged');
        if (walletType !== ConnectionType.METAMASK) return;
        setChainId(parseInt(chainId, 16));
        // setWallet(wallet);
        setWalletError('');
      });
    }
    if (typeof window !== 'undefined' && window.tronWeb && walletType === ConnectionType.TRONLINK) {
      window.addEventListener('message', function (e) {
        if (e.data.message && e.data.message.action == 'tabReply') {
          console.log('tabReply event', e.data.message);
          // if (e.data.message.data.data.node.chain && e.data.message.data.data.node.chain == '_') {
          //   console.log('tronLink currently selects the main chain');
          // } else {
          //   console.log('tronLink currently selects the side chain');
          // }
        }

        if (e.data.message && e.data.message.action == 'setAccount') {
          // console.log('setAccount e.data.message', e.data.message);

          dispatch(
            updateWalletState({
              walletAddress: e.data.message.data.address,
              walletType: ConnectionType.TRONLINK,
              isEvm: false,
            }),
          );
          setWalletError('');
        }
        if (e.data.message && e.data.message.action == 'setNode') {
          switch (e.data.message.data.node.fullNode) {
            case 'https://api.trongrid.io':
              setChainId('TRON_GRID_MAINNET');
              break;
            // case 'https://api.nileex.io':
            //   setChainId('TRON_NILE_TESTNET');
            //   break;

            default:
              const isToastActive = toast.isActive('Unsupported tron network');

              if (!isToastActive) {
                toast.error('Unsupported tron network');
              }

              setChainId('');
              break;
          }
          console.log('setNode event', e.data.message);
          if (e.data.message.data.node.chain == '_') {
            console.log('tronLink currently selects the main chain');
          } else {
            console.log('tronLink currently selects the side chain');
          }

          // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'connect') {
            console.log('connect event', e.data.message.isTronLink);
          }

          // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'disconnect') {
            console.log('disconnect event', e.data.message.isTronLink);
          }

          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'accountsChanged') {
            console.log('accountsChanged event', e.data.message);
            console.log('current address:', e.data.message.data.address);
          }

          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'connectWeb') {
            console.log('connectWeb event', e.data.message);
            console.log('current address:', e.data.message.data.address);
          }

          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'accountsChanged') {
            console.log('accountsChanged event', e.data.message);
          }

          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'acceptWeb') {
            console.log('acceptWeb event', e.data.message);
          }
          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'disconnectWeb') {
            console.log('disconnectWeb event', e.data.message);
          }

          // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
          if (e.data.message && e.data.message.action == 'rejectWeb') {
            console.log('rejectWeb event', e.data.message);
          }
        }
      });
    }
    if (walletType === ConnectionType.WALLET_CONNECT) {
      if (connector) {
        console.log('connector TRUE', connector);

        connector.on('disconnect', disconnectListener);
        connector.on('chainChanged', chainChangedListener);
        connector.on('accountsChanged', accountsChangedListener);
        // connector.connector.on('display_uri', URIListener);
      }
    }
    if (walletType === ConnectionType.APTOS_PETRA) {
      window.aptos.onNetworkChange((newNetwork) => {
        let aptosNetwork = '';

        switch (newNetwork) {
          case 'Mainnet':
            aptosNetwork = 'APTOS_MAINNET';
            break;
          case 'Devnet':
            aptosNetwork = 'APTOS_DEVNET';
            break;
          case 'Testnet':
            aptosNetwork = 'APTOS_TESTNET';
            break;

          default:
            aptosNetwork = '';
            break;
        }

        setChainId(aptosNetwork);
      });
      window.aptos.onAccountChange((newAccount) => {
        if (newAccount) {
          console.log('aptos newAccount', newAccount);

          dispatch(
            updateWalletState({
              walletAddress: newAccount.address,
              walletType: ConnectionType.APTOS_PETRA,
              isEvm: false,
            }),
          );
        }
      });
    }
  };

  const contextValue = useMemo(() => {
    return {
      wallet,
      setWallet,
      walletError,
      setWalletError,
      walletAddress,
      walletType,
      isEvm,
      provider: walletProvider,
      chainId,
      balance,
      connector,
    };
  }, [
    wallet,
    setWallet,
    walletError,
    setWalletError,
    walletAddress,
    walletType,
    isEvm,
    walletProvider,
    chainId,
    balance,
    connector,
  ]);

  return <WalletContext.Provider value={contextValue}>{children}</WalletContext.Provider>;
}
