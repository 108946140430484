import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
// material
import { Stack, Typography, IconButton, TableCell, SvgIcon, Box, Grid } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '@mui/material/styles';
// components
import { ButtonGroup, Table } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PageWrapper from 'src/components/PageWrapper';
import { INVOICE } from 'src/constants/routes';
// ----------------------------------------------------------------------

export const InvoiceDetails = () => {
  let componentRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const params = useParams();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const invoice = useSelector(({ invoices: { invoice } }) => invoice);
  const clients = useSelector(({ employees: { clientList } }) => clientList);

  const clientInInvoice = clients.find(({ email }) => email === invoice?.email_client);

  useEffect(() => {
    dispatch({
      type: 'GET_INVOICE_BY_ID',
      payload: { organization_id: currentUser.organization_id, invoice_number: params.id },
    });
    dispatch({ type: 'GET_CLIENT_LIST', payload: currentUser.organization_id });
  }, []);

  const GridWithMarginBottom = styled(Grid)({
    marginBottom: '40px !important',
  });

  const CustomTableCellHeader = styled(TableCell)({
    verticalAlign: 'middle !important',
    textAlign: 'left',
  });

  const RobotoTypography = styled(Typography)({
    fontFamily: 'Roboto',
  });

  const STable = styled('table')({
    border: '1px solid #dee2e6',
    borderCollapse: 'collapse',
  });
  const STh = styled('th')({
    border: '1px solid #dee2e6',
    borderCollapse: 'collapse',
    textAlign: 'left',
    padding: '10px',
  });
  const STd = styled('td')({
    border: '1px solid #dee2e6',
    borderCollapse: 'collapse',
    padding: '10px',
  });

  const OrganizationInfoItem = ({ label, value }) =>
    value ? (
      <Stack>
        <RobotoTypography variant="body2">
          {t(label)}: {value}
        </RobotoTypography>
      </Stack>
    ) : null;

  return (
    <PageWrapper title="Invoice Details" path={INVOICE}>
      <Stack
        sx={{
          boxShadow:
            'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
          borderRadius: '16px',
          px: 3,
          py: 2,
          fontFamily: 'Roboto',
          background: '#fff',
        }}
      >
        <Stack
          ref={(el) => (componentRef = el)}
          sx={{
            '@media print': {
              margin: '0 50px !important',
              paddingTop: '50px !important',
            },
          }}
        >
          <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
            <Stack mb={5} mt={2} spacing={2}>
              <Stack borderBottom={'1px solid #FFA31A'}>
                <Typography variant="subtitle1">{`INVOICE № ${
                  invoice?.invoice_number || 'No data'
                }`}</Typography>
              </Stack>
              <RobotoTypography variant="body2" color="#808080" gutterBottom mt={-2}>
                Date: {dayjs(invoice?.created_date || new Date()).format('MMMM D, YYYY')} <br /> Due
                Date: {dayjs(invoice?.due_date || new Date()).format('MMMM D, YYYY')}
              </RobotoTypography>
            </Stack>
            <Stack>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="View">
                      <IconButton aria-label="view">
                        <SvgIcon component={VisibilityIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => componentRef}
                />
              </ButtonGroup>
            </Stack>
          </Stack>
          <GridWithMarginBottom container spacing={2}>
            <Grid item xs={12} sm={3}>
              <RobotoTypography paragraph={true} variant="body1">
                Sent To
              </RobotoTypography>
              <OrganizationInfoItem label="Company" value={clientInInvoice?.name} />
              <OrganizationInfoItem label="Address" value={clientInInvoice?.address} />
              <OrganizationInfoItem label="Phone" value={clientInInvoice?.phone} />
              <OrganizationInfoItem label="Tax number" value={clientInInvoice?.tax_number} />
              <OrganizationInfoItem
                label="Registration number"
                value={clientInInvoice?.registration_number}
              />
              <OrganizationInfoItem
                label="Contact person"
                value={clientInInvoice?.contact_person}
              />
              <OrganizationInfoItem label="Email" value={clientInInvoice?.email} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <RobotoTypography paragraph={true} variant="body1">
                Sent By
              </RobotoTypography>
              <OrganizationInfoItem label="Company" value={invoice?.organization?.company_name} />
              <OrganizationInfoItem label="Address" value={invoice?.organization?.address} />
              <OrganizationInfoItem label="Phone" value={invoice?.organization?.phone} />
              <OrganizationInfoItem label="Tax number" value={invoice?.organization?.tax_number} />
              <OrganizationInfoItem
                label="Registration number"
                value={invoice?.organization?.registration_number}
              />
              <OrganizationInfoItem
                label="Contact person"
                value={invoice?.organization?.contact_person}
              />
              <OrganizationInfoItem label="Email" value={invoice?.organization?.email} />
            </Grid>
          </GridWithMarginBottom>
          <Stack>
            <RobotoTypography
              sx={{ textTransform: 'uppercase' }}
              variant="subtitle1"
              color="#FFA31A"
              textAlign="center"
              mb={2}
            >
              Payment info
            </RobotoTypography>
          </Stack>
          <Stack
            mb={2}
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            sx={{
              background: '#f9f9fa',
              px: 3,
              py: 2,
              borderRadius: '25px',
              border: '1px solid #edeff2',
            }}
          >
            <Box>
              <RobotoTypography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
                color="#808080"
              >
                {`Network: ${invoice?.blockchain || ''}`}
              </RobotoTypography>
              <RobotoTypography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
                color="#808080"
              >
                {`Coin: ${invoice?.amount_currency || ''}`}
              </RobotoTypography>
              <RobotoTypography variant="body2" color="#808080">
                {`Wallet Address: ${invoice?.wallet_client || 'No data'}`}
              </RobotoTypography>
            </Box>
            <Box>
              {invoice?.qr_code && (
                <img style={{ width: '150px', height: '150px' }} src={invoice?.qr_code} alt="QR" />
              )}
            </Box>
          </Stack>
          <SimpleBar>
            <STable style={{ width: '100%' }}>
              <tr>
                <STh>
                  <Typography variant="body2">Id</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">Description</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">Quantity</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">Price</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">Discount</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">TAX</Typography>
                </STh>
                <STh>
                  <Typography variant="body2">Amount</Typography>
                </STh>
              </tr>
              {invoice?.invoice_items?.length > 0 &&
                invoice?.invoice_items?.map((row, index) => (
                  <tr>
                    <STd>
                      <Typography variant="body2">{index + 1}</Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">{row.description}</Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">
                        {row.qty} {row.qty_type}
                      </Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">{row.unit_price}</Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">{row.discount}%</Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">{row.tax}%</Typography>
                    </STd>
                    <STd>
                      <Typography variant="body2">{row.amount}</Typography>
                    </STd>
                  </tr>
                ))}
            </STable>
          </SimpleBar>
          <Stack
            mt={3}
            sx={{
              background: '#f9f9fa',
              borderRadius: '25px',
              py: 2,
              pr: { xs: 3, md: 10 },
              border: '1px solid #edeff2',
            }}
          >
            <Stack justifyContent="flex-end" flexDirection="row">
              <Stack sx={{ width: { xs: '40%', md: '30%' } }} textAlign="left">
                <Typography variant="body2">Amount without tax</Typography>
              </Stack>
              <Stack width="20%" textAlign="right">
                <Typography variant="body2">
                  {invoice?.amount_total +
                    ' ' +
                    (invoice?.amount_currency && invoice?.amount_currency) || 'No data'}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent="flex-end" flexDirection="row">
              <Stack sx={{ width: { xs: '40%', md: '30%' } }} textAlign="left">
                <Typography variant="body2">Total Tax amount</Typography>
              </Stack>
              <Stack width="20%" textAlign="right">
                <Typography variant="body2">
                  {invoice?.amount_total_tax +
                    ' ' +
                    (invoice?.amount_currency && invoice?.amount_currency) || 'No data'}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent="flex-end" flexDirection="row">
              <Stack sx={{ width: { xs: '40%', md: '30%' } }} fontWeight={700} textAlign="left">
                <Typography variant="body2">Total amount</Typography>
              </Stack>
              <Stack width="20%" fontWeight={700} textAlign="right">
                <Typography variant="body2">
                  {invoice?.amount_with_tax +
                    ' ' +
                    (invoice?.amount_currency && invoice?.amount_currency) || 'No data'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
