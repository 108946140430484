// InvoiceTotal.js
import React from 'react';
import { Grid, Stack, Typography, Divider } from '@mui/material';

export const InvoiceTotal = ({ label, amount, currencyCoin }) => (
  <Grid item lg={10} md={10} sm={4} xs={12}>
    <Stack direction="row" justifyContent="space-between" mb={{ xs: 1, md: 2 }}>
      <Typography mt={2} variant="body2">
        {label}
      </Typography>
      <Typography mt={2} variant="body2">
        {amount.toFixed(2)} {currencyCoin || ''}
      </Typography>
    </Stack>
    <Divider />
  </Grid>
);
