import axios from 'axios';
import { API_BASE_URL } from '../constants/defaultURL';
import { instance } from './index';

export const getOrganisationList = () => axios.get(`${API_BASE_URL}organization`);
export const getWalletList = () => axios.get(`${API_BASE_URL}wallet`);

export const addOrganisation = (newOrganisation) =>
  axios.post(`${API_BASE_URL}organization`, newOrganisation);

export const addPayment = (payload) => instance.post(`/payment/savetransaction`, { ...payload });

export const addPaymentTransaction = (payload) =>
  instance.post(`/transaction-history`, { ...payload });

export const sendMessage = (payload) =>
  axios.post(`https://stgcoinsender.wpengine.com/wp-json/nf-form-handler/v1/submit-form`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: `Basic c3RnY29pbnNlbmRlcjpzdGdjb2luc2VuZGVy`,
      'Content-Type': 'application/json',
    },
  });

export const addWallet = (newWallet) => axios.post(`${API_BASE_URL}wallet`, newWallet);

export const getPaymentList = () => instance.get(`/payment`);
export const getOrganisationById = (id) =>
  instance.get(`/organization/getById?organization_id=${id}`);
export const getOrganisationEdit = (data) => instance.put(`/organization`, data);

export const getBalance = (organisationId) =>
  axios.get(`${API_BASE_URL}payment/balance/${organisationId}`);
