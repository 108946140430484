import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { Stack, Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { EditButton } from 'src/components/EditButton';
import dayjs from 'dayjs';
const MemoDatePicker = React.memo(DatePicker);

export const CustomDate = ({ name, text, minDate }) => {
  const { t } = useTranslation('common');
  const [field, meta, helpers] = useField(name);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const handleDateChange = useCallback(
    (date) => {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      helpers.setValue(formattedDate);
      setDatePickerVisible(false);
    },
    [helpers],
  );

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <EditButton onClick={() => setDatePickerVisible(true)} />

      <Typography variant="body2">
        {text} {dayjs(field.value).format('MMMM DD, YYYY')}
      </Typography>
      <Stack sx={{ visibility: 'hidden', position: 'absolute', top: '100px', right: '100px' }}>
        <MemoDatePicker
          label={t('date.added')}
          value={dayjs(field.value).toDate()}
          onClose={() => setDatePickerVisible(false)}
          onChange={handleDateChange}
          open={datePickerVisible}
          renderInput={(params) => <TextField size="small" {...params} />}
          name={name}
        />
      </Stack>
    </Stack>
  );
};
