import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useWeb3React } from '@web3-react/core';
import { isAddress } from 'ethers/lib/utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWeb3 } from 'src/hooks/useWeb3';
import { isCurrentNetworkAddress } from 'src/utils';
import { useMemo } from 'react';

export const Row = ({
  handleClick,
  isItemSelected,
  labelId,
  row,
  handleEditRow,
  handleCancelEditRow,
  handleSaveEditRow,
  data,
}) => {
  const { t } = useTranslation('common');
  const { walletType, isEvm, provider } = useWeb3();

  const initialValues = useMemo(
    () => ({
      name: row?.name,
      second_name: row?.second_name,
      position: row?.position,
      wallet_id: row?.wallet_id,
      network_id: row?.network_id,
      amount: row?.amount,
      add_info: row?.add_info,
    }),
    [row],
  );

  const { isValid, values, errors, touched, dirty, getFieldProps, resetForm } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      second_name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      wallet_id: Yup.string()
        .label('Wallet address')
        .required('Is required')
        .test('Is address', 'Please enter correct wallet address', (value) =>
          isCurrentNetworkAddress(value, isEvm, walletType, provider),
        ),
      network_id: Yup.string().label('Network').required('Is required'),
      amount: Yup.number().min(0, 'Must be at least 0').required('Is required'),
      add_info: Yup.string().max(45, 'Maximum length 45 characters'),
    }),

    onSubmit: (values) => {},
  });

  const someItemIsEditing = data && data?.some(({ isEdit }) => isEdit);

  const handleSelectRow = (event) => !someItemIsEditing && handleClick(event, row, row.id);

  return (
    <>
      {row.isEdit ? (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{ height: '90px' }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={true}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <TextField
              required
              size="small"
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row">
            <TextField
              fullWidth
              size="small"
              label={t('surname')}
              {...getFieldProps('second_name')}
              error={Boolean(touched.second_name && errors.second_name)}
              helperText={touched.second_name && errors.second_name}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label={t('Position')}
              {...getFieldProps('position')}
            />
          </TableCell>

          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label="Network"
              {...getFieldProps('network_id')}
              error={Boolean(touched.network_id && errors.network_id)}
              helperText={touched.network_id && errors.network_id}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              fullWidth
              size="small"
              label={t('wallet.id')}
              error={Boolean(touched.wallet_id && errors.wallet_id)}
              helperText={touched.wallet_id && errors.wallet_id}
              {...getFieldProps('wallet_id')}
              required
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              type="number"
              size="small"
              fullWidth
              label="Amount"
              {...getFieldProps('amount')}
              InputProps={{ inputProps: { min: 0 } }}
              required
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </TableCell>

          <TableCell align="left">
            <TextField fullWidth size="small" label="Notes" {...getFieldProps('add_info')} />
          </TableCell>
          <TableCell align="left">
            <IconButton
              size="small"
              disabled={!(isValid && dirty)}
              onClick={() => {
                handleSaveEditRow({ ...values, id: row.id }, row.isEdit, row.isNew);
                resetForm({
                  values: { ...values, id: row.id },
                  dirtyFields: false,
                });
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                handleCancelEditRow(row.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell onClick={handleSelectRow} padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              disabled={someItemIsEditing}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} component="th" id={labelId} scope="row">
            <Typography variant="body2">{row.second_name || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.position || '-'}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.network_id}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{`${row.wallet_id.substring(
              0,
              4 + 2,
            )}...${row.wallet_id.substring(42 - 4)}`}</Typography>{' '}
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.amount || 0}</Typography>
          </TableCell>
          <TableCell onClick={handleSelectRow} align="left">
            <Typography variant="body2">{row.add_info || '-'}</Typography>
          </TableCell>
          <TableCell align="left">
            <IconButton
              onClick={() => {
                handleEditRow(row.id);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
