import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Page from '../../components/Page';
import LeftIcon from '../../assets/icons/arrow-button-left.svg';
import { CLIENTS } from '../../constants/routes';
import WarningModal from '../../components/WarningModal';
import { useDispatch } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import { PageLayout } from 'src/layouts/PagesLayout';
import { PageTitle } from 'src/components/PageTitle';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const NewClient = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const handleClose = () => {
    setIsOpen(false);
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    values,
    dirty,
    touched,
    getFieldProps,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '+380',
      address: '',
      tax_number: '',
      registration_number: '',
      contact_person: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      email: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
      phone: Yup.string().test(
        'isValid',
        'Phone is not valid',
        (val) => val === '+' || val === '+380' || isValidPhoneNumber(val),
      ),
      address: Yup.string().max(45, 'Maximum length 45 characters'),
      tax_number: Yup.number('Must be a number').typeError('Must be a number'),
      registration_number: Yup.number('Must be a number').typeError('Must be a number'),
      contact_person: Yup.string().max(45, 'Maximum length 45 characters').required('Is required'),
      add_info: Yup.string().max(45, 'Maximum length 45 characters'),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.phone === '+' || values.phone === '+380' ? '' : values.phone);
      formData.append('address', values.address);
      formData.append('tax_number', values.tax_number);
      formData.append('registration_number', values.registration_number);
      formData.append('contact_person', values.contact_person);
      formData.append('description', values.add_info ? values.add_info : '');
      formData.append('organization_id', currentUser.organization_id);
      dispatch({
        type: 'ADD_CLIENT_SAGA',
        payload: formData,
        navigate,
        flag: 'create',
        org_id: currentUser.organization_id,
      });
    },
  });

  return (
    <Page title="New Customer | CoinSender">
      <PageLayout>
        <PageTitle title="Add customer" path={CLIENTS} handler={() => setIsOpen(true)}/>
        <WarningModal open={isOpen} type={CLIENTS} close={handleClose}/>
        <Grid container>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: { xs: 1, md: 3 },
              width: '100%',
              boxShadow:
                'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            }}
            noValidate
            autoComplete="off"
          >
            <Stack mt={2} spacing={2}>
              <TextField
                fullWidth
                size="small"
                label="Company name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                required
              />
              <MuiPhoneNumber
                size="small"
                fullWidth
                variant="outlined"
                label="Phone Number"
                data-cy="user-phone"
                defaultCountry="ua"
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <TextField
                size="small"
                fullWidth
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
              <TextField
                fullWidth
                size="small"
                label="Tax number"
                {...getFieldProps('tax_number')}
                error={Boolean(touched.tax_number && errors.tax_number)}
                helperText={touched.tax_number && errors.tax_number}
              />
              <TextField
                size="small"
                fullWidth
                label="Registration number"
                {...getFieldProps('registration_number')}
                error={Boolean(touched.registration_number && errors.registration_number)}
                helperText={touched.registration_number && errors.registration_number}
              />
              <TextField
                fullWidth
                size="small"
                label="Contact person"
                required
                {...getFieldProps('contact_person')}
                error={Boolean(touched.contact_person && errors.contact_person)}
                helperText={touched.contact_person && errors.contact_person}
              />
              <TextField
                size="small"
                fullWidth
                label={t('Email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
                required
              />
              <Stack direction="row" gap={2}>
                <Button type="submit" disabled={!(isValid && dirty)} variant="contained">
                  {t('Save')}
                </Button>
                <Button onClick={() => setIsOpen(true)} variant="contained">
                  {t('cancel')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </PageLayout>
    </Page>
  );
};
