import {
  GET_ADMIN_INVOICES_REQUEST,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICES_SUCCESS,
  INVOICE_DELETE_FAILURE,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETE_SUCCESS,
  INVOICES_EDIT_FAILURE,
  INVOICES_EDIT_REQUEST,
  INVOICES_EDIT_SUCCESS,
} from 'src/constants/actions';

export const initialState = {
  invoices: null,
  invoiceNumber: null,
  adminInvoices: [],
  invoice: {},
  wallets: [],
  isLoading: false,
  error: null,
};

export const invoices = (state, { type, payload, isLoading }) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (type) {
    case GET_ADMIN_INVOICES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payload,
        isLoading: false,
      };
    case 'GET_ADMIN_INVOICES_SUCCESS':
      return {
        ...state,
        adminInvoices: payload,
        isLoading: false,
      };
    case 'GET_GENERAL_WALLETS':
      return {
        ...state,
        isLoading: true,
      };
    case 'GET_GENERAL_WALLETS_SUCCESS':
      return {
        ...state,
        wallets: payload,
        isLoading: false,
      };
    case 'GET_INVOICE_BY_ID_SUCCESS':
      return {
        ...state,
        invoice: payload,
        isLoading: false,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        isLoading,
      };

    case GET_INVOICE_NUMBER_SUCCESS:
      return {
        ...state,
        invoiceNumber: payload.invoiceNumber,
      };

    case INVOICES_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INVOICES_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case INVOICES_EDIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case INVOICE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case INVOICE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case INVOICE_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
