// DeleteField.js
import React from 'react';
import { Stack, IconButton, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// DeleteField.js
export const DeleteField = ({ index, itemCount, remove }) => (
  <Stack gridArea="delete" alignItems={{ xs: 'flex-start', md: 'center' }}>
    <Stack height="100" width="100" alignItems="center">
      <IconButton
        sx={{
          width: 'fit-content',
          display: { xs: 'none', md: 'block' },
        }}
        color="primary"
        variant="contained"
        disabled={itemCount === 1}
        onClick={() => remove(index)}
      >
        <HighlightOffIcon />
      </IconButton>
      <Button
        fullWidth
        variant="contained"
        disabled={itemCount === 1}
        onClick={() => remove(index)}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        Delete
      </Button>
    </Stack>
  </Stack>
);
