import { useEffect, useState } from 'react';

export function useDebounceValue(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDebounceFunction(func, delay = 500) {
  let timeout;

  function debouncedFunction(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return debouncedFunction;
}
