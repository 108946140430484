import React from 'react';
import { Stack, IconButton } from '@mui/material';
import Iconic from './Iconify';

export const EditButton = ({ onClick }) => (
    <Stack
        sx={{ cursor: 'pointer' }}
        color="#FD9B28"
        direction="row"
        alignItems="center"
        gap={1}
        onClick={onClick}
    >
      <IconButton
          sx={{ p: 0 }}
          color="primary"
          variant="contained"
          aria-label="edit"
      >
        <Iconic icon="eva:edit-fill" />
      </IconButton>
    </Stack>
);
