import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
    Stack,
    FormControl,
    InputLabel,
    Input,
    Box,
    Button,
} from '@mui/material';
import { validationSchemaForCompany, initialValuesForCompany } from './formikSetting';
import { registerUserPolygonId } from '../../../redux/actions';
import { useTranslation } from 'react-i18next';

function RegisterForm({ registerUserPolygonId, value, identity }) {

    const errorMessage = useSelector(({ AuthUser: { errorMessage } }) => errorMessage);

    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const formik = useFormik({
        initialValues: initialValuesForCompany,
        validationSchema: validationSchemaForCompany,

        onSubmit: (values) => {
            registerUserPolygonId(
                {
                    name: values.name,
                    second_name: values.second_name,
                    company_name: values.company,
                    email: values.email,
                    identity,
                },
                navigate,
            );
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        handleChange,
        setFieldValue,
        dirty,
        isValid,
    } = formik;


    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                    <Stack flexDirection="row" mb={2} gap={1}>
                        <FormControl
                            variant="standard"
                            fullWidth
                            required
                            error={touched.name && errors.name}
                        >
                            <InputLabel
                                htmlFor="standard-adornment-name"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.49)',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    fontFamily: 'MullerRegular',

                                    '&.Mui-focused': { color: 'white' },
                                }}
                            >
                                Name
                            </InputLabel>
                            <Input
                                fullWidth
                                required
                                autoFocus
                                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    label: {
                                        color: 'rgba(255, 255, 255, 0.49)',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        '&.Mui-focused': { color: 'white' },
                                        fontFamily: 'MullerRegular',
                                    },
                                    input: {
                                        color: 'white',
                                        fontSize: '12px',
                                        fontFamily: 'MullerRegular',
                                    },
                                }}
                                variant="standard"
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            fullWidth
                            required
                            error={touched.second_name && errors.second_name}
                        >
                            <InputLabel
                                htmlFor="standard-adornment-surname"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.49)',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    fontFamily: 'MullerRegular',

                                    '&.Mui-focused': { color: 'white' },
                                }}
                            >
                                Surname
                            </InputLabel>
                            <Input
                                fullWidth
                                required
                                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
                                {...getFieldProps('second_name')}
                                error={Boolean(touched.second_name && errors.second_name)}
                                helperText={touched.second_name && errors.second_name}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    label: {
                                        color: 'rgba(255, 255, 255, 0.49)',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        '&.Mui-focused': { color: 'white' },
                                        fontFamily: 'MullerRegular',
                                    },
                                    input: {
                                        color: 'white',
                                        fontSize: '12px',
                                        fontFamily: 'MullerRegular',
                                    },
                                }}
                                variant="standard"
                            />
                        </FormControl>
                    </Stack>
                    <Stack spacing={2} mb={2}>
                        <FormControl variant="standard" required error={touched.company && errors.company}>
                            <InputLabel
                                htmlFor="standard-adornment-company"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.49)',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    fontFamily: 'MullerRegular',

                                    '&.Mui-focused': { color: 'white' },
                                }}
                            >
                                Company
                            </InputLabel>
                            <Input
                                fullWidth
                                required
                                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
                                label="Company Name"
                                {...getFieldProps('company')}
                                error={
                                    Boolean(touched.company && errors.company) ||
                                    (errorMessage.includes('Organization') && errorMessage)
                                }
                                helperText={
                                    Boolean(touched.company && errors.company) ||
                                    (errorMessage.includes('Organization') && errorMessage)
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    label: {
                                        color: 'rgba(255, 255, 255, 0.49)',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        '&.Mui-focused': { color: 'white' },
                                        fontFamily: 'MullerRegular',
                                    },
                                    input: {
                                        color: 'white',
                                        fontSize: '12px',
                                        fontFamily: 'MullerRegular',
                                    },
                                }}
                                type="text"
                                variant="standard"
                            />
                        </FormControl>
                    </Stack>
                    <Stack spacing={2} mb={2}>
                        <FormControl
                            variant="standard"
                            fullWidth
                            required
                            error={
                                Boolean(touched.email && errors.email) ||
                                (errorMessage.includes('Admin') && errorMessage)
                            }
                            helperText={
                                Boolean(touched.email && errors.email) ||
                                (errorMessage.includes('Admin') && errorMessage)
                            }
                        >
                            <InputLabel
                                htmlFor="standard-adornment-email"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.49)',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    fontFamily: 'MullerRegular',

                                    '&.Mui-focused': { color: 'white' },
                                }}
                            >
                                Email
                            </InputLabel>
                            <Input
                                fullWidth
                                required
                                autoFocus
                                type="email"
                                label="Email"
                                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
                                {...getFieldProps('email')}
                                error={
                                    Boolean(touched.email && errors.email) ||
                                    (errorMessage.includes('Admin') && errorMessage)
                                }
                                helperText={
                                    Boolean(touched.email && errors.email) ||
                                    (errorMessage.includes('Admin') && errorMessage)
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    label: {
                                        color: 'rgba(255, 255, 255, 0.49)',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        '&.Mui-focused': { color: 'white' },
                                        fontFamily: 'MullerRegular',
                                    },
                                    input: {
                                        color: 'white',
                                        fontSize: '12px',
                                        fontFamily: 'MullerRegular',
                                    },
                                }}
                                variant="standard"
                            />
                        </FormControl>
                    </Stack>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            type="submit"
                            disabled={!isValid}
                            fullWidth
                            sx={{
                                height: '26px',
                                borderRadius: '37.2273px',
                                background: 'rgba(4, 242, 242, 0.4)',
                                fontFamily: 'MullerRegular',

                                border: '1px solid #04F2F2',
                                fontWeight: 400,
                            }}
                        >
                            Update Profile
                        </Button>
                    </Box>
                </Stack>
            </Form>
        </FormikProvider>
    )
}

const mapStateToProps = (state) => ({ state });
const mapActionsToProps = { registerUserPolygonId };


export default connect(mapStateToProps, mapActionsToProps)(RegisterForm);
