import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { sendReferralCode, setReferralPath } from 'src/redux/referral/actions';

export const Join = () => {
  const dispatch = useDispatch();
  const { referralPathIds, referrer } = useSelector((state) => state.referral);
  const [inputCode, setInputCode] = useState('');

  useEffect(() => {
    const currentUserData = localStorage.getItem('currentUser');

    if (currentUserData) {
      const currentUser = JSON.parse(currentUserData);
      if (currentUser.referralPathIds) {
        dispatch(
          setReferralPath(
            currentUser.referralPathIds,
            currentUser.referrer.fullName,
            currentUser.referrer.referralJoinDate,
          ),
        );
      }
    }
  }, [dispatch]);

  const handleClickSet = () => {
    dispatch(sendReferralCode(inputCode));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Limit the input to 20 characters
    if (value.length <= 20) {
      setInputCode(value);
    }
  };

  return (
    <Stack spacing={1}>
      {referralPathIds ? (
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            You have successfully joined the referral program!
          </Typography>
          <Typography variant="body2" pb={1}>Referrer: {referrer.fullName}</Typography>
          <Typography variant="body2">
            Date Joined: {new Date(referrer.referralJoinDate).toLocaleDateString()}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Join our Referral Program
          </Typography>
          <Typography variant="body2" pb={1}>
            Enter your referral code below to join our referral program.
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              label="Enter referral code"
              variant="outlined"
              size="small"
              value={inputCode}
              onChange={handleInputChange}
              inputProps={{ maxLength: 20 }} // limit input to 20 characters
            />
            <Button variant="contained" onClick={handleClickSet} disabled={inputCode.length !== 20}>
              Join Program
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};
