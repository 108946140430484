import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { connect, useDispatch } from 'react-redux';

import { Button, FormControl, Input, InputLabel, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from '../../../pages/forgot.module.scss';

function ForgotForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      corporate_email: '',
    },

    validationSchema: Yup.object().shape({
      corporate_email: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
    }),

    onSubmit: () => {
      dispatch({ type: 'FORGOT_USER_PASSWORD', payload: values, navigate });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form style={{ width: '100%' }} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <FormControl
          fullWidth
          variant="standard"
          sx={{
            mb: 2,
          }}
          error={touched.email && errors.email}
        >
          <InputLabel
            htmlFor="standard-adornment-email"
            sx={{
              color: 'rgba(255, 255, 255, 0.49)',
              fontSize: '12px !important',
              fontWeight: 400,
              fontFamily: 'MullerRegular',

              '&.Mui-focused': { color: 'white' },
            }}
          >
            Email
          </InputLabel>
          <Input
            {...getFieldProps('corporate_email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            id="standard-adornment-email"
            type="email"
            placeholder="Enter your email..."
            inputProps={{
              autoComplete: 'off',
            }}
            sx={{
              label: {
                color: 'rgba(255, 255, 255, 0.49)',
                fontSize: '12px !important',
                fontWeight: 400,
                fontFamily: 'MullerRegular',

                '&.Mui-focused': { color: 'white' },
              },
              input: {
                color: 'white',
                fontSize: '12px !important',
                fontFamily: 'MullerRegular',
              },
            }}
            variant="standard"
          />
        </FormControl>

        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Button
              type="submit"
              disabled={!formik.isValid}
              fullWidth
              sx={{
                height: '26px',
                border: '0.729947px solid #FFFFFF',
                borderRadius: ' 37.2273px',
                background: 'transparent',
                padding: '6.5px 33.8476px',
                fontSize: '10px !important',
                fontFamily: 'MullerRegular',
              }}
            >
              Send
            </Button>
          </Stack>
          <Stack gap={1} textAlign="right" className="have_account">
            <Typography
              sx={{ fontFamily: 'MullerRegular' }}
              fontSize="9px !important"
              fontWeight="400"
              color="rgba(255, 255, 255, 0.49)"
            >
              Already have an account?
              <span className={styles.link_underline}>
                <a href="/">Sign in</a>
              </span>
              .
            </Typography>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

const mapStateToProps = (state) => ({ state });
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(ForgotForm);
