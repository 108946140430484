import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTokenContract, useTokenContractSigned, useTokenContractTron } from './useContract';
import { buildQueryTron, getHumanValue, getAddressByChainId } from '../utils/index';
import { useMutation, useQuery } from 'react-query';
import { ZERO_ADDRESS } from '../constants/tokens';
import {
  ALLOWANCE_KEY,
  APPROVE_MUTATION_KEY,
  APPROVE_SIGNED_MUTATION_KEY,
  BALANCE_OF_QUERY_KEY,
  DECIMALS_QUERY_KEY,
  NAME_QUERY_KEY,
  SYMBOL_QUERY_KEY,
} from '../constants/queryKeys';
import { AddressMap } from '../constants/addresses';
import { MaxUint256 } from '@ethersproject/constants';
// import { updateConnectionError } from '@/state/connection/reducer';
import { getConnection } from '../connection/utils';
// import { useAppDispatch } from '@/state/hooks';
import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3 } from './useWeb3';
import { useDispatch } from 'react-redux';

const MIN_TRANSFER_VALUE = 0.1;

export default function useTokenDataTron(tokenAddress, CONTRACTS) {
  const { walletAddress: account, walletType, chainId } = useWeb3();

  const [tokenDataError, setTokenDataError] = useState(false);
  const dispatch = useDispatch();
  const connectionType = getConnection(walletType);

  const {
    balanceOf: balanceOfQuery,
    symbol: symbolQuery,
    name: nameQuery,
    allowance: allowanceQuery,
    decimals: decimalsQuery,
    approve: approveAction,
  } = useTokenContractTron(tokenAddress || ZERO_ADDRESS);

  const {
    data: tokenName,
    refetch: refetchTokenName,
    isLoading: tokenNameLoading,
  } = useQuery(`${NAME_QUERY_KEY}_${tokenAddress}`, () => buildQueryTron(nameQuery), {
    onError: (err) => {
      //   dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
      console.log(err, `${SYMBOL_QUERY_KEY}_${tokenAddress}`);
    },
    enabled: !!account && !!tokenAddress,
  });

  const {
    data: tokenSymbol,
    refetch: refetchTokenSymbol,
    isLoading: tokenSymbolLoading,
  } = useQuery(`${SYMBOL_QUERY_KEY}_${tokenAddress}`, () => buildQueryTron(symbolQuery), {
    onError: (err) => {
      // dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
      console.log(err, `${SYMBOL_QUERY_KEY}_${tokenAddress}`);
    },
    enabled: !!account && !!tokenAddress,
  });

  const {
    data: tokenDecimals,
    refetch: refetchTokenDecimals,
    isLoading: tokenDecimalsLoading,
  } = useQuery(`${DECIMALS_QUERY_KEY}_${tokenAddress}`, () => buildQueryTron(decimalsQuery), {
    onError: (err) => {
      // dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
      console.log(err, `${BALANCE_OF_QUERY_KEY}_${tokenAddress}_${account}`);
    },
    enabled: !!account && !!tokenAddress,
  });

  const { data: tokenBalance, refetch: refetchTokenBalance } = useQuery(
    `${BALANCE_OF_QUERY_KEY}_${tokenAddress}_${account}`,
    () => buildQueryTron(balanceOfQuery, [account]),
    {
      enabled: !!account && !!tokenAddress,
      onError: (err) => {
        // dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
        console.log(err, `${BALANCE_OF_QUERY_KEY}_${tokenAddress}_${account}`);
      },
    },
  );

  const {
    data: tokenAllowance,
    refetch: refetchAllowance,
    isLoading: isAllowanceLoading,
  } = useQuery(
    `${ALLOWANCE_KEY}_${tokenAddress}_${account}`,
    () => buildQueryTron(allowanceQuery, [account, getAddressByChainId(CONTRACTS, chainId)]),
    {
      enabled: !!account && !!tokenAddress,
      onError: (err) => {
        // dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
        console.log(err, `${ALLOWANCE_KEY}_${tokenAddress}_${account}`);
      },
    },
  );

  const { mutateAsync: approve, isLoading: isApproveLoading } = useMutation(
    `${APPROVE_MUTATION_KEY}_${tokenAddress}`,
    (approveValue) =>
      buildQueryTron(
        approveAction,
        [getAddressByChainId(CONTRACTS, chainId), approveValue || MaxUint256],
        true,
      ),
    {
      onError: (err) => {
        // dispatch(updateConnectionError({ connectionType, error: 'User rejected transaction' }));
        console.log(err, `${APPROVE_MUTATION_KEY}_${tokenAddress}`);
      },
    },
  );

  const tokenApprove = useCallback(async (valueToApprove) => {
    return await approve(valueToApprove);
  }, []);

  useEffect(() => {
    if (!tokenAddress) setTokenDataError(true);
    if (account && tokenAddress && !tokenAllowance) {
      refetchAllowance();
    }
  }, [account, tokenAllowance, tokenAddress]);

  const isAllowed = useMemo(() => {
    if (tokenAllowance && tokenDecimals) {
      return +getHumanValue(tokenAllowance.toString(), tokenDecimals).toString() > 0;
    } else return false;
  }, [tokenAllowance, tokenDecimals]);

  const isExist = useMemo(() => {
    if (
      (!tokenNameLoading &&
        !tokenSymbolLoading &&
        !tokenDecimalsLoading &&
        tokenSymbol &&
        !tokenSymbol.code &&
        typeof tokenSymbol === 'string' &&
        tokenDecimals) ||
      (!tokenNameLoading &&
        !tokenSymbolLoading &&
        !tokenDecimalsLoading &&
        tokenName &&
        !tokenName.code &&
        typeof tokenName === 'string' &&
        tokenDecimals)
    ) {
      return true;
    } else return false;
  }, [
    tokenName,
    tokenSymbol,
    tokenDecimals,
    tokenAddress,
    tokenNameLoading,
    tokenSymbolLoading,
    tokenDecimalsLoading,
  ]);

  return useMemo(() => {
    return {
      tokenDataError,
      tokenName,
      tokenSymbol,
      tokenDecimals,
      tokenBalance,
      tokenAllowance,
      approve,
      isAllowed,
      refetchAllowance,
      isExist,
      tokenNameLoading,
      tokenSymbolLoading,
      tokenDecimalsLoading,
      tokenApprove,
    };
  }, [
    tokenDataError,
    tokenAddress,
    tokenName,
    tokenSymbol,
    tokenDecimals,
    tokenBalance,
    tokenAllowance,
    approve,
    isAllowed,
    refetchAllowance,
    isExist,
    tokenNameLoading,
    tokenSymbolLoading,
    tokenDecimalsLoading,
    tokenApprove,
  ]);
}
