// ----------------------------------------------------------------------

export default function HelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
  };
}
