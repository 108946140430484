import React from 'react';
import { Stack, Typography } from '@mui/material';

const WorkingMessage = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="50vh">
      <Stack>
        <Typography variant="body1">Coming Soon</Typography>
      </Stack>
      <Typography variant="body1" textAlign="center">
        We are working on creating something amazing. It will be ready soon!
      </Typography>
    </Stack>
  );
};

export default WorkingMessage;
