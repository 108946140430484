// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InitializingWallet_initializingWallet__TdaAD {
  display: flex;
  color: var(--theme-text-white);
  border-radius: 10px;
  margin-bottom: 12px;
}
.InitializingWallet_initializingWallet__TdaAD svg {
  max-width: 24px;
  margin-right: 12px;
}
.InitializingWallet_initializingWallet__TdaAD i {
  margin-right: 20px;
}
.InitializingWallet_initializingWallet__TdaAD button {
  margin-left: 20px;
}
.InitializingWallet_initializingWallet__wallet__vn5yv {
  display: flex;
  padding: 16px 20px;
  background: var(--theme-body-color);
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}
.InitializingWallet_initializingWallet__wallet__vn5yv p {
  margin-bottom: 5px;
  color: var(--theme-text-white);
}
.InitializingWallet_initializingWallet__wallet__vn5yv span {
  color: var(--theme-text-gray);
}

@keyframes InitializingWallet_rotate__ceOk9 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Wallet/InitializingWallet/InitializingWallet.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,kBAAA;EACA,mCAAA;EAEA,yBAAA;EACA,mBAAA;AAHJ;AAKI;EACE,kBAAA;EACA,8BAAA;AAHN;AAMI;EACE,6BAAA;AAJN;;AASA;EACE;IACE,uBAAA;EANF;EAQA;IACE,yBAAA;EANF;AACF","sourcesContent":[".initializingWallet {\n  display: flex;\n  color: var(--theme-text-white);\n  border-radius: 10px;\n  margin-bottom: 12px;\n\n  svg {\n    max-width: 24px;\n    margin-right: 12px;\n  }\n\n  i {\n    margin-right: 20px;\n  }\n\n  button {\n    margin-left: 20px;\n  }\n\n  &__wallet {\n    display: flex;\n    padding: 16px 20px;\n    background: var(--theme-body-color);\n    // border: 1px solid var(--theme-border-color);\n    border: 1px solid #c4c4c4;\n    border-radius: 10px;\n\n    p {\n      margin-bottom: 5px;\n      color: var(--theme-text-white);\n    }\n\n    span {\n      color: var(--theme-text-gray);\n    }\n  }\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initializingWallet": `InitializingWallet_initializingWallet__TdaAD`,
	"initializingWallet__wallet": `InitializingWallet_initializingWallet__wallet__vn5yv`,
	"rotate": `InitializingWallet_rotate__ceOk9`
};
export default ___CSS_LOADER_EXPORT___;
