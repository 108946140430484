import { all, call, fork, put, takeLatest, delay } from 'redux-saga/effects';

import {
  REGISTER_USER,
  LOGIN_USER,
  IS_AUTH,
  LOGOUT_USER,
  GOOGLE_LOGIN,
  GET_QRCODE_LINK_REQUEST,
  GET_SIGN_IN_POLYGON_ID_REQUEST,
  REGISTER_USER_POLYGON_ID,
  LOGIN_USER_POLYGON_ID,
  GET_AUTH_STATUS_POLYGON_ID_REQUEST,
  LOGIN_USER_TEAM,
} from '../../constants/actions';
import {
  loginUserSuccess,
  logoutUserSuccess,
  setErrorMessage,
  getQrCodeLinkSuccess,
  getQrCodeLinkFailure,
  getSignInPolygonIdSuccess,
  getSignInPolygonIdFailure,
  setAuthStatusPolygonId,
} from './actions';
import { PROFILE, SIGN_IN, BANKING, INVOICE } from '../../constants/routes';
import {
  signIn as signInServices,
  signUp as signUpServices,
  logoutUser,
  refreshToken,
  changePassword,
  editUserProfile,
  editUserAvatar,
  forgotPassword,
  googleAuth,
  createCredentialQR,
  getSignInPolygonReq,
  checkVerificationStatus,
  signUpPolygonId,
  editUserProfileIdentifier,
  checkVerificationStatusCredential,
  signInPolygonId,
  logInTeam,
  restorePassword,
} from '../../services';

import { toast } from 'react-toastify';

import { getCookie, removeDataFromLocalstorage, setDataToLocalstorage } from './helper';
import { setReferralCode, setReferralPath } from '../referral/actions';

function* registerWithEmailPassword({ payload, navigate, setValue }) {
  try {
    const response = yield call(signUpServices, payload);
    if (response.status === 201) {
      toast.success('Profile has been successfully created!');
      yield navigate(SIGN_IN, { replace: true });
      yield setValue(0);
      // window.location.reload();
      yield put(setErrorMessage(''));
    }
  } catch (e) {
    if (e.response.data.statusCode === 403) {
      yield put(setErrorMessage(e.response.data.message));
      toast.error(e.response.data.message);
    } else {
      toast.error(e.message);
    }
  }
}

function* loginTeam({ payload, navigate }) {
  try {
    const response = yield call(logInTeam, payload);
    if (response.status === 200) {
      const access = getCookie('Authentication');
      const refresh = getCookie('Refresh');
      const data = JSON.stringify(response.data);
      yield call(setDataToLocalstorage, 'authorization_login', data);
      yield call(setDataToLocalstorage, 'access_token', access);
      yield call(setDataToLocalstorage, 'currentUser', data);
      yield call(setDataToLocalstorage, 'refresh_token', refresh);
      yield put(loginUserSuccess(response.data));
      toast.success('Successfully authorized!');
      window.location.reload(true);
      // yield navigate(BANKING, { replace: true });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

function* loginWithEmailPassword({ payload, navigate }) {
  try {
    const response = yield call(signInServices, payload);
    if (response.status === 200) {
      if (response.data.referralCode) {
        yield put(setReferralCode(response.data.referralCode));
      }
      if (response.data.referralPathIds) {
        yield put(setReferralPath(response.data.referralPathIds));
      }
      const access = getCookie('Authentication');
      const refresh = getCookie('Refresh');
      const data = JSON.stringify(response.data);
      yield call(setDataToLocalstorage, 'authorization_login', data);
      yield call(setDataToLocalstorage, 'access_token', access);
      yield call(setDataToLocalstorage, 'currentUser', data);
      yield call(setDataToLocalstorage, 'refresh_token', refresh);
      yield put(loginUserSuccess(response.data));
      toast.success('Successfully authorized!');
      yield navigate(INVOICE, { replace: true });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

function* loginWithPolygonId({ payload, navigate }) {
  try {
    const response = yield call(signInPolygonId, payload.sessionId);
    if (response.status === 200) {
      const access = getCookie('Authentication');
      const refresh = getCookie('Refresh');
      const data = JSON.stringify(response.data);

      yield call(setDataToLocalstorage, 'authorization_login', data);
      yield call(setDataToLocalstorage, 'access_token', access);
      yield call(setDataToLocalstorage, 'currentUser', data);
      yield call(setDataToLocalstorage, 'refresh_token', refresh);
      yield put(loginUserSuccess(response.data));

      toast.success('Successfully authorized!');
      yield payload.navigate(INVOICE, { replace: true });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}
function* loginWithGoogle({ payload, navigate }) {
  try {
    const responce = yield call(googleAuth, payload);
    if (responce.status === 201) {
      const access = getCookie('Authentication');
      const refresh = getCookie('Refresh');
      const data = JSON.stringify(responce.data);
      yield call(setDataToLocalstorage, 'authorization_login', data);
      yield call(setDataToLocalstorage, 'access_token', access);
      yield call(setDataToLocalstorage, 'currentUser', data);
      yield call(setDataToLocalstorage, 'refresh_token', refresh);
      toast.success('Successfully authorized!');
      yield put(loginUserSuccess(responce.data));
      yield navigate(BANKING, { replace: true });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export function* logoutUserWorker({ navigate }) {
  try {
    const response = yield call(logoutUser);
    if (response.status === 200) {
      yield call(removeDataFromLocalstorage, 'access_token');
      yield call(removeDataFromLocalstorage, 'authorization_login');
      yield put(logoutUserSuccess());
      yield navigate(SIGN_IN, { replace: true });
      toast.success('Successfully!');
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export function* isAuth() {
  try {
    const response = yield call(refreshToken);

    if (response.status === 201) {
      localStorage.setItem('access_token', getCookie('Authentication'));
      yield put(loginUserSuccess(response.data));
    }
  } catch (error) {
    removeDataFromLocalstorage('access_token');
    removeDataFromLocalstorage('authorization_login');
    window.location.reload();
  }
}

export function* changePasswordWorker({ payload, navigate }) {
  try {
    const response = yield call(changePassword, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      yield navigate(PROFILE, { replace: true });
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* restorePasswordWorker({ payload, navigate }) {
  try {
    const response = yield call(restorePassword, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      yield navigate(PROFILE, { replace: true });
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* editUserProfileWorker({ payload, navigate, flag }) {
  try {
    const response = yield call(editUserProfile, payload);
    if (response.status === 200 && flag === 'delete') {
      yield toast.success('Data changed successful');
      yield call(setDataToLocalstorage, 'currentUser', JSON.stringify(response.data));
    }
    if (response.status === 200 && flag === 'edit') {
      yield toast.success('Data changed successful');
      yield call(setDataToLocalstorage, 'currentUser', JSON.stringify(response.data));
      yield navigate(PROFILE, { replace: true });
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* editUserIdentifierWorker({ payload, navigate }) {
  try {
    const response = yield call(editUserProfileIdentifier, payload);
    if (response.status === 200) {
      yield toast.success('Data changed successful');
      yield call(setDataToLocalstorage, 'currentUser', JSON.stringify(response.data));
      yield navigate(PROFILE, { replace: true });
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* forgotUserPasswordWorker({ payload, navigate }) {
  try {
    const response = yield call(forgotPassword, payload);
    if (response.status === 201) {
      yield toast.success(response.data.message);
      yield navigate(SIGN_IN, { replace: true });
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* editUserAvatarWorker({ payload, user }) {
  try {
    const response = yield call(editUserAvatar, payload);
    if (response.status === 201) {
      yield call(
        setDataToLocalstorage,
        'currentUser',
        JSON.stringify({
          ...user,
          avatar_url: response?.data?.avatar_url,
        }),
      );
      yield (window.location.href = '/application/profile');
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* fetchQrCodeLink() {
  try {
    const qrCodeLink = yield call(createCredentialQR, {});
    yield put(getQrCodeLinkSuccess(qrCodeLink));
    yield fork(checkVerificationStatusCredentialSaga, {
      sessionId: qrCodeLink.data.sessionID,
      linkId: qrCodeLink.data.linkDetail.id,
    });
  } catch (error) {
    yield put(getQrCodeLinkFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
}

export function* qrCodeLinkSaga() {
  yield takeLatest(GET_QRCODE_LINK_REQUEST, fetchQrCodeLink);
}

function* checkVerificationStatusCredentialSaga({ sessionId, linkId }) {
  let cycle = true;
  while (cycle) {
    try {
      const resp = yield call(checkVerificationStatusCredential, { sessionId, linkId });

      if (resp?.isAuth) {
        const { isAuth, identifier, onboard } = resp;

        yield put(setAuthStatusPolygonId(resp));
        if (onboard) {
          cycle = false;
        }
      }
    } catch (e) {
      cycle = false;
    }
    yield delay(2000);
  }
}

function* checkVerificationStatusSaga({ payload }) {
  const { sessionId } = payload;

  try {
    const resp = yield call(checkVerificationStatus, { sessionId });

    if (resp?.isAuth) {
      const { isAuth, identifier, onboard } = resp;

      yield put(setAuthStatusPolygonId(resp));
    }
  } catch (e) {
    console.log('e.message', e.message);
  }

  // let cycle = true;
  // while (cycle) {
  //   try {
  //     const resp = yield call(checkVerificationStatus, { sessionId });
  //
  //     if (resp?.isAuth) {
  //       const { isAuth, identifier, onboard } = resp;
  //
  //       yield put(setAuthStatusPolygonId(resp));
  //       if (onboard) {
  //         cycle = false;
  //       }
  //     }
  //   } catch (e) {
  //     console.log('e.message', e.message);
  //     cycle = false;
  //   }
  //   yield delay(2000);
  // }
}

function* signInPolygonIdSaga() {
  try {
    const data = yield call(getSignInPolygonReq, {});
    yield put(getSignInPolygonIdSuccess(data));
    // yield fork(checkVerificationStatusSaga, data.sessionId);
    // yield fork(checkVerificationStatusCredentialSaga, data.sessionId);
  } catch (error) {
    yield put(getSignInPolygonIdFailure(error.toString()));
    toast.error(error.response.data.message);
  }
}

export function* watchSignInPolygonIdSaga() {
  yield takeLatest(GET_SIGN_IN_POLYGON_ID_REQUEST, signInPolygonIdSaga);
}

function* registerWithPolygonId({ payload, navigate }) {
  try {
    const response = yield call(signUpPolygonId, payload);
    if (response.status === 201) {
      toast.success('Profile has been successfully created!');
      yield navigate(SIGN_IN, { replace: true });
      window.location.reload();
      yield put(setErrorMessage(''));
    }
  } catch (e) {
    if (e.response.data.statusCode === 403) {
      yield put(setErrorMessage(e.response.data.message));
    } else {
      toast.error(e.response.data.message);
    }
  }
}

export function* watchRegisterWithPolygonId() {
  yield takeLatest(REGISTER_USER_POLYGON_ID, registerWithPolygonId);
}

export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
  yield takeLatest(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginTeam() {
  yield takeLatest(LOGIN_USER_TEAM, loginTeam);
}

export function* watchLoginUserPolygonId() {
  yield takeLatest(LOGIN_USER_POLYGON_ID, loginWithPolygonId);
}

export function* watchLogoutUser() {
  yield takeLatest(LOGOUT_USER, logoutUserWorker);
}

export function* watchIsAuth() {
  yield takeLatest(IS_AUTH, isAuth);
}

export function* changePasswordWatcher() {
  yield takeLatest('CHANGE_PASSWORD', changePasswordWorker);
}

export function* editUserProfileWatcher() {
  yield takeLatest('EDIT_USER_PROFILE', editUserProfileWorker);
}

export function* editUserProfileIdentifierWatcher() {
  yield takeLatest('EDIT_USER_PROFILE_IDENTIFIER', editUserIdentifierWorker);
}

export function* editUserAvatarWatcher() {
  yield takeLatest('EDIT_USER_AVATAR', editUserAvatarWorker);
}

export function* forgotUserPasswordWatcher() {
  yield takeLatest('FORGOT_USER_PASSWORD', forgotUserPasswordWorker);
}
export function* loginWithGoogleWatcher() {
  yield takeLatest(GOOGLE_LOGIN, loginWithGoogle);
}

export function* checkVerificationStatusSagaWatcher() {
  yield takeLatest(GET_AUTH_STATUS_POLYGON_ID_REQUEST, checkVerificationStatusSaga);
}

export function* restorePasswordWatcher() {
  yield takeLatest('RESTORE_PASSWORD', restorePasswordWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchRegisterUser),
    fork(watchIsAuth),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(changePasswordWatcher),
    fork(editUserProfileWatcher),
    fork(editUserAvatarWatcher),
    fork(forgotUserPasswordWatcher),
    fork(loginWithGoogleWatcher),
    fork(qrCodeLinkSaga),
    fork(watchSignInPolygonIdSaga),
    fork(watchRegisterWithPolygonId),
    fork(editUserProfileIdentifierWatcher),
    fork(watchLoginUserPolygonId),
    fork(checkVerificationStatusSagaWatcher),
    fork(watchLoginTeam),
    fork(restorePasswordWatcher),
  ]);
}
