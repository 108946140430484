import React from 'react';
import { Stack } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { GenericInput } from './GenericInput';
import Header from './Header';
import { AddRowButton } from './components/AddRowButton';
import { TotalAmounts } from './components/TotalAmounts';

const CustomDataGrid = ({
  fieldConfig,
  formikArrayName,
  idField = true,
  deleteField = true,
  children,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  let adjustedFieldConfig = [...fieldConfig];

  if (idField) {
    adjustedFieldConfig.unshift({
      gridArea: 'id',
      label: 'Id',
      type: 'id',
      name: 'id',
      gridColumnWidth: '0.25fr',
    });
  }

  if (deleteField) {
    adjustedFieldConfig.push({
      gridArea: 'delete',
      label: '',
      type: 'delete',
      name: 'delete',
      gridColumnWidth: '0.25fr',
    });
  }

  const gridStyles = {
    mb: 2,
    gap: { xs: 2, md: 1 },
    gridTemplateColumns: {
      xs: '1fr',
      md: adjustedFieldConfig.map((field) => field.gridColumnWidth).join(' '),
    },
    gridTemplateRows: '1fr',
    gridTemplateAreas: {
      xs: `${adjustedFieldConfig.map((field) => `'${field.gridArea}'`).join(' ')}`,
      md: `"${adjustedFieldConfig.map((field) => field.gridArea).join(' ')}"`,
    },
  };

  return (
    <Stack p={{ xs: 1, md: 3 }}>
      <Stack
        display="grid"
        alignItems="center"
        sx={{ ...gridStyles, display: { xs: 'none', md: 'grid' } }}
      >
        {adjustedFieldConfig.map((item, index) => (
          <Header gridArea={item.gridArea} label={item.label} key={index} />
        ))}
      </Stack>

      <FieldArray name={formikArrayName}>
        {({ remove, push }) => (
          <>
            {values[formikArrayName].map((item, index) => (
              <Stack key={index} display="grid" alignItems="center" sx={gridStyles}>
                {adjustedFieldConfig.map((configItem) => {
                  const value = item[configItem.name];
                  return (
                    <GenericInput
                      key={configItem.name}
                      type={configItem.type}
                      name={`${formikArrayName}.${index}.${configItem.name}`}
                      gridArea={configItem.gridArea}
                      label={configItem.label}
                      value={value}
                      required={configItem.required}
                      error={configItem.error}
                      helperText={configItem.helperText}
                      options={configItem.options}
                      inputProps={configItem.inputProps}
                      InputProps={configItem.InputProps}
                      index={index}
                      formikArrayName={formikArrayName}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      disabled={configItem.disabled}
                      remove={configItem.type === 'delete' ? remove : undefined}
                      itemCount={values[formikArrayName].length}
                    />
                  );
                })}
              </Stack>
            ))}
            <Stack pb={3} direction="row" justifyContent="space-between" alignItems="center">
              <AddRowButton
                push={push}
                values={values}
                newObject={{
                  id: Math.random(),
                  description: '',
                  discount: '',
                  unit_price: '',
                  qty: '',
                  tax: '',
                  qty_type: 'pc',
                  net_amount: '0',
                }}
              />
              {/*{children}*/}
              <TotalAmounts values={values} />
            </Stack>
          </>
        )}
      </FieldArray>
    </Stack>
  );
};

export default CustomDataGrid;
