import React from "react";
import { Avatar, Card, CardContent, Typography, Box, Grid } from "@mui/material";
import QRCode from "qrcode.react";

export default function CredentialQR({
     qrCode,
     schemaType,
     subTitle,
 }) {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >

            {/*<Box sx={{ padding: "0 24px", textAlign: "center", width: '100%' }}>*/}
            {/*    <Typography variant="h4" component="div">*/}
            {/*        wants to send you a credential*/}
            {/*    </Typography>*/}

            {/*    <Typography variant="subtitle1" component="div">*/}
            {/*        {subTitle}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            <Card sx={{ margin: "auto", width: '100%' }}>
                <CardContent>
                    <Grid container direction="column">
                        <Grid item>
                            <Box
                                sx={{
                                    backgroundImage:
                                        'url("/images/noise-bg.png"), linear-gradient(50deg, rgb(130 101 208) 0%, rgba(221, 178, 248, 1) 50%',
                                    borderRadius: 8,
                                    padding: 24,
                                }}
                            >
                                <QRCode
                                    value={JSON.stringify(qrCode)}
                                    size={300}
                                    level={"H"}
                                    includeMargin
                                />
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={{ padding: "24px 24px 8px" }}>
                                <Typography variant="h5" component="div" noWrap>
                                    {schemaType}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}
