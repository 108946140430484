// NumberInput.js
import React from 'react';
import { Stack, TextField } from '@mui/material';

export const NumberInput = ({
  name,
  gridArea,
  label,
  value,
  required = false,
  error = false,
  helperText = '',
  inputProps,
  placeholder = '0',
  setFieldValue,
  setFieldTouched,
  InputProps,
}) => (
  <Stack gridArea={gridArea}>
    <TextField
      type="number"
      fullWidth
      size="small"
      name={name}
      onChange={e => {
        setFieldValue(name, e.target.value);
        setFieldTouched(name, true, false);
      }}
      onBlur={() => setFieldTouched(name, true)}
      label={label}
      value={value}
      placeholder={placeholder}
      required={required}
      helperText={helperText}
      error={error}
      InputProps={InputProps}
      inputProps={inputProps}
    />
  </Stack>
);
