import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CredentialQR from './CredentialQR';


function QRCodeModal({ isOpen, handleClose, qrCode, schemaType }) {
    console.log('qrCode', qrCode);
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Your QR Code"}
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CredentialQR qrCode={qrCode} schemaType={schemaType} />
            </DialogContent>
        </Dialog>
    );
}

export default QRCodeModal;
