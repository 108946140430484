// ----------------------------------------------------------------------

import { useMediaQuery } from '@mui/material';

export default function Button(theme) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          height: isMobile ? '31.38px' : '37.13px',
          textTransform: 'none',
          fontSize: isMobile ? '8px' : '12px',
          background: '#007994',
          color: 'white',
          boxShadow: 'none',
          fontWeight: 500,
          '&:hover': {
            boxShadow: 'none',
            background: 'rgb(0, 172, 172)',
          },
        },

        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlined: {
          background: 'white',
          color: 'black',
          border: '1px solid #007994',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            border: `1px solid ${theme.palette.primary.dark}`,
            color: 'white',
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: isMobile ? '0 8px !important' : '0 24px !important',
        },
      },
    },
  };
}
