//actions.js
import {
    GET_REFERRAL_CODE_REQUEST,
    GET_REFERRALS_REQUEST, SET_REFERRAL_CODE,
    SEND_REFERRAL_CODE_REQUEST, SET_REFERRAL_PATH, GET_REFERRAL_LEVELS_COUNT_REQUEST,
} from "../../constants/actions";

export const getReferralCode = () => ({
    type: GET_REFERRAL_CODE_REQUEST,
});

export const setReferralCode = (code) => ({
    type: SET_REFERRAL_CODE,
    payload: code
});

export const setReferralPath = (code, referralFullName, referralJoinDate) => ({
    type: SET_REFERRAL_PATH,
    payload: { code, referralFullName, referralJoinDate }
});

export const sendReferralCode = (code) => ({
    type: SEND_REFERRAL_CODE_REQUEST,
    payload: code
});

export const getReferrals = () => ({
    type: GET_REFERRALS_REQUEST,
});

export const getReferralLevelsCount = () => ({
    type: GET_REFERRAL_LEVELS_COUNT_REQUEST,
});
