import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
  Autocomplete,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import WalletConnect from './WalletConnect';
import React from 'react';
import { WalletConnectComponent } from 'src/components/Wallet';
import { useWeb3React } from '@web3-react/core';
import { getChainNameById } from 'src/utils';
import { NETWORK_SELECTOR_CHAINS } from 'src/constants/networks';
import { useWeb3 } from 'src/hooks/useWeb3';
import { formatNetworks } from 'src/helpers/stringUtils';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { walletAddress: account, wallet, chainId, balance: walletBalance } = useWeb3();

  const setNetwork = async (targetChainId) => {
    wallet.switchNetwork(targetChainId);
  };
  const isMobile = useMediaQuery('(max-width: 600px)');

  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
  }));

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    padding: isMobile ? '0 8px !important' : '0 24px !important',

    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
    },
  }));

  const sortedNetworks = NETWORK_SELECTOR_CHAINS.map((chainId) => ({
    name: getChainNameById(chainId),
    chainId,
  })).sort((a, b) => (a.name > b.name ? 1 : -1));

  const currentNetworkObj = sortedNetworks.find((item) => item.chainId === chainId);

  console.log(chainId);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Stack sx={{ width: { xs: '105px', md: '120px' } }}>
            <FormControl sx={{ width: { xs: '105px', md: '120px' } }} fullWidth size="small">
              {!account ? (
                <Tooltip title="Please connect your wallet" placement="top">
                  <Autocomplete
                    sx={{ width: { xs: '105px', md: '120px' } }}
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    disableClearable
                    disabled
                    options={sortedNetworks}
                    value={chainId && currentNetworkObj}
                    getOptionLabel={({ name }) => formatNetworks(name)}
                    isOptionEqualToValue={(option, value) => option.chainId === value.chainId}
                    onChange={(e, value) => {
                      setNetwork(value?.chainId);
                    }}
                    renderInput={(params) => <TextField placeholder="Network" {...params} />}
                  />
                </Tooltip>
              ) : (
                <Autocomplete
                  sx={{ width: { xs: '105px', md: '120px' }, zIndex: 10000 }}
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={sortedNetworks}
                  value={chainId && currentNetworkObj}
                  getOptionLabel={({ name }) => formatNetworks(name)}
                  isOptionEqualToValue={(option, value) => {
                    if (!value || !value.chainId || !option.chainId) return false;
                    return option.chainId === value.chainId;
                  }}
                  onChange={(e, value) => {
                    setNetwork(value?.chainId);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </FormControl>
          </Stack>

          <WalletConnectComponent display="block" />

          {/*<LanguagePopover />*/}
          {/*<NotificationsPopover />*/}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
