import { Alert, AlertTitle, Button, Modal, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

export const DownloadExampleModal = ({
  open,
  setOpen,
  style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 2,
  },
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Alert severity="info" sx={{ background: '#E5F6FD' }}>
          <AlertTitle>Info</AlertTitle>
          <Stack mb={2}>
            <Typography variant="body2" fontStyle="italic" textAlign="justify">
              All data in the line (name, wallet, amount) must be filled.
              <br />
              Field name may contain letters or numbers.
              <br />
              Field wallet must contain a valid wallet.
              <br />
              Field amount must contain a number, a valid delimiter is a dot (0.01).
            </Typography>
          </Stack>
          You can download an example file: <br />{' '}
          <strong>
            <a
              rel="noreferrer"
              href={`https://cs-payments.s3.amazonaws.com/example-download.csv`}
              target="_blank"
              download
              style={{ textDecoration: 'underline' }}
            >
              csv
            </a>
            {' / '}
            <a
              rel="noreferrer"
              href={`https://cs-payments.s3.amazonaws.com/example-download.xlsx`}
              target="_blank"
              download
              style={{ textDecoration: 'underline' }}
            >
              xlsx
            </a>
            {' / '}
            <a
              rel="noreferrer"
              href={`https://cs-payments.s3.amazonaws.com/example-download.numbers`}
              target="_blank"
              download
              style={{ textDecoration: 'underline' }}
            >
              numbers
            </a>
          </strong>
          .
        </Alert>
        <Stack mt={2}>
          <Button variant="contained" component="label">
            Upload
            <input
              onChange={({ target: { files } }) => {
                dispatch({ type: 'UPLOAD_TRANSFERS', payload: files });
                setOpen(false);
              }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .numbers"
              type="file"
              hidden
            />
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
