import { Box, Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Loader } from 'src/components/loader/Loader';

export const SupportTab = () => {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const isLoading = useSelector(({ organisations: { isLoading } }) => isLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const {
    handleChange,
    handleSubmit,
    initialValues,
    isValid,
    values,
    errors,
    touched,
    dirty,
    getFieldProps,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      email: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
      message: Yup.string().max(500, 'Maximum length 500 characters').required('Is required'),
    }),

    onSubmit: (values, { resetForm }) => {
      dispatch({
        type: 'SEND_MESSAGE',
        payload: values,
        resetForm,
        initialValues,
      });
    },
  });

  useEffect(() => {
    dispatch({ type: 'GET_ORGANISATION_BY_ID', payload: user?.organization_id });
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between">
      {isLoading && <Loader />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: { xs: 1, md: 3 },
              width: '100%',
              boxShadow:
                'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            }}
            noValidate
            autoComplete="off"
          >
            <Stack>
              <Typography variant="body2">
                Here you can leave your inquiry and our team will promptly get in touch with you.
              </Typography>
            </Stack>
            <Stack my={2}>
              <Divider />
            </Stack>
            <Stack direction="row" gap="20px">
              <Stack width="100%" gap="16px">
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  required
                  {...getFieldProps('name')}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={t('Email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  {...getFieldProps('email')}
                  required
                />
                <TextField
                  size="small"
                  fullWidth
                  label={t('Message')}
                  multiline
                  minRows={4}
                  error={Boolean(touched.message && errors.message)}
                  helperText={touched.message && errors.message}
                  {...getFieldProps('message')}
                  required
                />
              </Stack>
            </Stack>
            <Stack mt={2} spacing={2}>
              <Stack direction="row" gap={2}>
                <Button type="submit" disabled={!(isValid && dirty)} variant="contained">
                  {t('Send')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
