// import { isSupportedEvmChain } from '@/config/chains';
import { updateWalletState } from 'src/redux/wallet/actions';
import store from '../redux/store';

export class WalletAbstract {
  // type: string;
  // address: string;
  // error: string;
  // connector: any;
  // provider: any;

  constructor() {
    this.type = '';
    this.address = '';
    this.error = '';
    this.connector = '';
    this.provider = '';
  }

  checkConnection = async () => {
    return this;
  };

  connect = async () => {
    return this;
  };

  resetWallet = () => {
    store.dispatch(
      updateWalletState({
        walletAddress: '',
        walletType: '',
        isEvm: null,
        provider: null,
        connector: null,
      }),
    );
    this.type = '';
    this.address = '';
    localStorage.setItem('walletType', 'null');
    localStorage.setItem('walletconnect', 'null');
    // localStorage.setItem('wc@2:ethereum_provider:', 'null');
    localStorage.setItem('wc@2:client:0.3//session', 'null');

    return this;
  };

  getNetwork = () => {
    // Add implementation
  };

  getChainId = async () => {
    // Add implementation
  };

  getBalance = async () => {
    // Add implementation
  };

  getProvider = () => {
    // Add implementation
  };

  switchNetwork = () => {
    // Add implementation
  };
}
