import { IChains, SupportedChainId } from './chains';
import { ARBITRUM_LIST, BSC_LIST, CELO_LIST, OPTIMISM_LIST } from './lists';

export const AVERAGE_L1_BLOCK_TIME = 12000;

const CHAIN_INFO = {
  [SupportedChainId.MAINNET]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.OPTIMISM]: {
    blockWaitMsBeforeWarning: 25000,
    bridge: 'https://app.optimism.io/bridge',
    defaultListUrl: OPTIMISM_LIST,
    docs: 'https://optimism.io/',
    explorer: 'https://optimistic.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/optimism/',
    label: 'Optimism',
    // Optimism perfers same icon for both
    statusPage: 'https://optimism.io/status',
    helpCenterUrl:
      'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  // [SupportedChainId.OPTIMISM_GOERLI]: {
  //   blockWaitMsBeforeWarning: 25000,
  //   bridge: 'https://app.optimism.io/bridge',
  //   defaultListUrl: OPTIMISM_LIST,
  //   docs: 'https://optimism.io/',
  //   explorer: 'https://goerli-optimism.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/optimism/',
  //   label: 'Optimism Görli',
  //   statusPage: 'https://optimism.io/status',
  //   helpCenterUrl:
  //     'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
  //   nativeCurrency: { name: 'Optimism Goerli Ether', symbol: 'görOpETH', decimals: 18 },
  // },
  [SupportedChainId.ARBITRUM_ONE]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://bridge.arbitrum.io/',
    docs: 'https://offchainlabs.com/',
    explorer: 'https://arbiscan.io/',
    infoLink: 'https://info.uniswap.org/#/arbitrum',
    label: 'Arbitrum',
    defaultListUrl: ARBITRUM_LIST,
    helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  // [SupportedChainId.ARBITRUM_RINKEBY]: {
  //   blockWaitMsBeforeWarning: 10000,
  //   bridge: 'https://bridge.arbitrum.io/',
  //   docs: 'https://offchainlabs.com/',
  //   explorer: 'https://rinkeby-explorer.arbitrum.io/',
  //   infoLink: 'https://info.uniswap.org/#/arbitrum/',
  //   label: 'Arbitrum Rinkeby',
  //   defaultListUrl: ARBITRUM_LIST,
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
  //   nativeCurrency: { name: 'Rinkeby Arbitrum Ether', symbol: 'rinkArbETH', decimals: 18 },
  // },
  [SupportedChainId.POLYGON]: {
    bridge: 'https://wallet.polygon.technology/login',
    docs: 'https://polygon.io/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://info.uniswap.org/#/polygon/',
    label: 'Polygon',
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
  },
  // [SupportedChainId.POLYGON_MUMBAI]: {
  //   blockWaitMsBeforeWarning: 10000,
  //   bridge: 'https://wallet.polygon.technology/bridge',
  //   docs: 'https://polygon.io/',
  //   explorer: 'https://mumbai.polygonscan.com/',
  //   infoLink: 'https://info.uniswap.org/#/polygon/',
  //   label: 'Polygon Mumbai',
  //   nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'mMATIC', decimals: 18 },
  // },
  [SupportedChainId.CELO]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    docs: 'https://docs.celo.org/',
    explorer: 'https://celoscan.io/',
    infoLink: 'https://info.uniswap.org/#/celo',
    label: 'Celo',
    nativeCurrency: { name: 'Celo', symbol: 'CELO', decimals: 18 },
    defaultListUrl: CELO_LIST,
  },
  // [SupportedChainId.CELO_ALFAJORES]: {
  //   blockWaitMsBeforeWarning: 10000,
  //   bridge: 'https://www.portalbridge.com/#/transfer',
  //   docs: 'https://docs.celo.org/',
  //   explorer: 'https://alfajores-blockscout.celo-testnet.org/',
  //   infoLink: 'https://info.uniswap.org/#/celo',
  //   label: 'Celo Alfajores',
  //   nativeCurrency: { name: 'Celo', symbol: 'CELO', decimals: 18 },
  //   defaultListUrl: CELO_LIST,
  // },
  [SupportedChainId.BSC]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://bscscan.com/',
    label: 'Binance',
    nativeCurrency: { name: 'Bnb', symbol: 'BNB', decimals: 18 },
    defaultListUrl: BSC_LIST,
  },
  [SupportedChainId.BSC_TEST]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://testnet.bscscan.com/',
    label: 'Binance',
    nativeCurrency: { name: 'Bnb', symbol: 'BNB', decimals: 18 },
    defaultListUrl: BSC_LIST,
  },
  [SupportedChainId.AVALANCHE]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://snowtrace.io/',
    label: 'Avalanche',
    nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
  },
  [SupportedChainId.GODWOKEN]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://gw-mainnet-explorer.nervosdao.community/',
    label: 'Godwoken',
    nativeCurrency: { name: 'CBK', symbol: 'CKB', decimals: 18 },
  },
  [SupportedChainId.FANTOM]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://ftmscan.com/',
    label: 'Fantom',
    nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
  },
  [SupportedChainId.FUSE]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.fuse.io/',
    label: 'Fuse',
    nativeCurrency: { name: 'Fuse', symbol: 'Fuse', decimals: 18 },
  },
  [SupportedChainId.GNOSIS]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://gnosisscan.io/',
    label: 'Gnosis',
    nativeCurrency: { name: 'xDai', symbol: 'xDai', decimals: 18 },
  },
  [SupportedChainId.OASIS_EMERALD]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.emerald.oasis.dev/',
    label: 'Oasis emerals',
    nativeCurrency: { name: 'ROSE', symbol: 'ROSE', decimals: 18 },
  },
  [SupportedChainId.OASIS_SAPPHIRE]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.sapphire.oasis.io',
    label: 'Oasis sapphire',
    nativeCurrency: { name: 'ROSE', symbol: 'ROSE', decimals: 18 },
  },
  [SupportedChainId.MOONBEAM]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://moonscan.io/',
    label: 'Moonbeam',
    nativeCurrency: { name: 'GLMR', symbol: 'GLMR', decimals: 18 },
  },
  [SupportedChainId.AURORA]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://aurorascan.dev',
    label: 'Aurora',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.ZKSYNC]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.zksync.io/',
    label: 'ZkSync',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.HARMONY]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.harmony.one',
    label: 'Harmony',
    nativeCurrency: { name: 'ONE', symbol: 'ONE', decimals: 18 },
  },
  [SupportedChainId.MOONRIVER]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://moonriver.moonscan.io',
    label: 'Moonriver',
    nativeCurrency: { name: 'MOVR', symbol: 'MOVR', decimals: 18 },
  },
  [SupportedChainId.HECO]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://hecoinfo.com',
    label: 'HECO',
    nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
  },
  [SupportedChainId.OKXCHAIN]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://www.oklink.com/en/okc',
    label: 'OKXChain Mainnet',
    nativeCurrency: { name: 'OKT', symbol: 'OKT', decimals: 18 },
  },
  [SupportedChainId.TELOS]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://www.oklink.com/en/okc',
    label: 'TELOS',
    nativeCurrency: { name: 'TLOS', symbol: 'TLOS', decimals: 18 },
  },
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://explorer.athens.zetachain.com',
    label: 'ZETACHAIN ATHENS TESTNET',
    nativeCurrency: { name: 'aZETA', symbol: 'aZETA', decimals: 18 },
  },
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://blockscout.scroll.io',
    label: 'SCROLL ALPHA TESTNET',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.CRONOS]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://cronoscan.com',
    label: 'Cronos',
    nativeCurrency: { name: 'CRO', symbol: 'CRO', decimals: 18 },
  },
  [SupportedChainId.CRONOS_TESTNET]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://cronos.org/explorer/testnet3',
    label: 'Cronos testnet',
    nativeCurrency: { name: 'TCRO', symbol: 'TCRO', decimals: 18 },
  },
  [SupportedChainId.SKALE_EUROPA]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://elated-tan-skat.explorer.mainnet.skalenodes.com',
    label: 'SKALE Europa Hub',
    nativeCurrency: { name: 'sFUEL', symbol: 'sFUEL', decimals: 18 },
  },
  [SupportedChainId.SKALE_EUROPA_TEST]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://staging-legal-crazy-castor.explorer.staging-v3.skalenodes.com',
    label: 'SKALE Europa Hub testnet',
    nativeCurrency: { name: 'sFUEL', symbol: 'sFUEL', decimals: 18 },
  },
  // [SupportedChainId.SKALE_TESTNET]: {
  //   blockWaitMsBeforeWarning: 10000,
  //   bridge: 'https://www.portalbridge.com/#/transfer',
  //   explorer: 'https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com',
  //   label: 'https://skale.space/',
  //   nativeCurrency: { name: 'sFuel', symbol: 'sFuel', decimals: 18 },
  // },
  [SupportedChainId.IOTEX]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://iotexscan.io',
    label: 'IOTEX',
    nativeCurrency: { name: 'IOTX', symbol: 'IOTX', decimals: 18 },
  },
  [SupportedChainId.IOTEX_TESTNET]: {
    blockWaitMsBeforeWarning: 10000,
    bridge: 'https://www.portalbridge.com/#/transfer',
    explorer: 'https://testnet.iotexscan.io',
    label: 'IOTEX TESTNET',
    nativeCurrency: { name: 'IOTX', symbol: 'IOTX', decimals: 18 },
  },
};

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * SupportedChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(chainId) {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined;
  }
  return undefined;
}

const MAINNET_INFO = CHAIN_INFO[SupportedChainId.BSC];
export function getChainInfoOrDefault(chainId) {
  return getChainInfo(chainId) ?? MAINNET_INFO;
}
