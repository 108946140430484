import React, { useState } from 'react';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { makeStyles } from '@material-ui/core/styles';
import CustomDataGrid from '../../../components/CustomDataGrid';
import { invoiceDeleteRequest } from '../../../redux/invoices/actions';
import { useDispatch } from 'react-redux';
import DeleteModal from 'src/components/DeleteModal';

const useStyles = makeStyles({
  stack: {
    width: '100%',
  },
  iconButton: {
    height: '30px',
    width: '30px',
  },
});

function CustomToolbar({ selectedRows, onDelete }) {
  const classes = useStyles();

  return (
    <GridToolbarContainer className={classes.toolbar}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={classes.stack}
      >
        <Typography variant="body2">{selectedRows.length} rows selected</Typography>
        <Tooltip title="Delete">
          <span>
            <IconButton
              onClick={onDelete}
              disabled={selectedRows.length === 0} // disable button if no rows selected
            >
              <DeleteIcon className={classes.iconButton} />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </GridToolbarContainer>
  );
}

export default function InvoiceTable({ data, loading }) {
  const dispath = useDispatch();

  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const closeDeleteModal = () => setIsWarningOpen((prev) => !prev);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDeleteSelected = () => {
    dispath(invoiceDeleteRequest(selectedRows));
  };

  const handleEditRow = (id) => () => {
    navigate(`${id}/edit`);
  };

  const handleViewRow = (id) => () => {
    navigate(`${id}`);
  };

  const handleSendRow = (id) => () => {
    console.log('id', id);
  };

  const columns = [
    { field: 'invoice_number', headerName: 'Invoice No', flex: 1 },
    { field: 'company_name_client', headerName: 'Customer', flex: 1 },
    {
      field: 'amount_total',
      headerName: 'Amount',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'createDateTime',
      headerName: 'Data Added',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          new Date(params.row.createDateTime).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }) || 'No data'
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditRow(id)} />,
          <GridActionsCellItem icon={<ReceiptIcon />} label="Delete" onClick={handleViewRow(id)} />,
          // <GridActionsCellItem icon={<SendIcon />} label="Send" onClick={handleSendRow(id)} />,
        ];
      },
    },
  ];

  const rows = searchText
    ? data.filter((row) =>
        Object.values(row).join(' ').toLowerCase().includes(searchText.toLowerCase()),
      )
    : data;

  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        size="small"
        name="search"
        onChange={handleSearch}
        label={t('search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {/*<CustomDataGrid*/}
      {/*  rows={rows}*/}
      {/*  autoHeight*/}
      {/*  columns={columns}*/}
      {/*  loading={loading}*/}
      {/*  selectedRows={selectedRows}*/}
      {/*  setSelectedRows={setSelectedRows}*/}
      {/*  handleDeleteSelected={handleDeleteSelected}*/}
      {/*  checkboxSelection*/}
      {/*  onRowSelectionModelChange={setSelectedRows}*/}
      {/*  disableRowSelectionOnClick*/}
      {/*  hideFooterSelectedRowCount*/}
      {/*/>*/}

      <DataGrid
        rowHeight={68}
        autoHeight
        rows={rows}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={setSelectedRows}
        disableRowSelectionOnClick
        loading={loading}
        hideFooterSelectedRowCount
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => (
            <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
              <Typography variant="subtitle2">You haven't created an invoice yet.</Typography>
            </Stack>
          ),
        }}
        slotProps={{
          toolbar: { selectedRows, onDelete: closeDeleteModal },
        }}
      />
      <DeleteModal
        open={isWarningOpen}
        close={() => {
          closeDeleteModal();
        }}
        handler={() => {
          handleDeleteSelected();
          setSelectedRows([]);
          closeDeleteModal();
        }}
      />
    </Stack>
  );
}
