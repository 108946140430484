import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAdminInvoices } from '../../redux/invoices/actions';
import { NewInvoice } from './NewInvoice';

const EditInvoice = () => {
  const dispatch = useDispatch();
  const { invoices, isLoading } = useSelector(({ invoices: { adminInvoices, isLoading } }) => ({
    invoices: adminInvoices,
    // isLoading,
  }));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAdminInvoices());
  }, [dispatch]);

  const invoiceData = invoices.find((invoice) => invoice.id === Number(id));

  if (!invoiceData) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    id: invoiceData.id,
    client: invoiceData.email_client,
    paymentWallet: invoiceData.wallet,
    currencyCoin: invoiceData.amount_currency,
    customInvoiceNumber: invoiceData.invoice_number,
    startDate: new Date(invoiceData.created_date),
    dueDate: new Date(invoiceData.due_date),
    details: invoiceData.invoice_items.map((item) => ({
      id: item.id,
      description: item.description,
      discount: item.discount === 0 ? item.discount : '',
      unit_price: item.unit_price,
      qty: item.qty,
      tax: item.tax === 0 ? item.tax : '',
      qty_type: item.qty_type,
      amount: item.amount,
    })),
    amount_with_tax: parseFloat(invoiceData.amount_with_tax),
    amount_total_tax: parseFloat(invoiceData.amount_total_tax),
    amount_total: parseFloat(invoiceData.amount_total),
  };

  return <NewInvoice initialValues={initialValues} isEdit={true} />;
};

export default EditInvoice;
