
export const computeDiscountedAmount = (unit_price, qty, discount) => {
  const quantity = unit_price * qty;
  return quantity - (quantity / 100) * discount;
};

export const computeTaxAmount = (amount, tax) => {
  return (amount / 100) * tax;
};

export const getInvoiceAmount = ({ unit_price = 0, qty = 0, discount = 0, tax = 0 }) => {
  const amount = computeDiscountedAmount(unit_price, qty, discount);
  const taxAmount = computeTaxAmount(amount, tax);
  return amount + taxAmount;
};

export const getInvoiceAmountWithoutTaxes = ({ unit_price = 0, qty = 0, discount = 0 }) => {
  return computeDiscountedAmount(unit_price, qty, discount);
};

export const getInvoiceTaxes = ({ unit_price = 0, qty = 0, discount = 0, tax = 0 }) => {
  const amount = computeDiscountedAmount(unit_price, qty, discount);
  return computeTaxAmount(amount, tax);
};

export const getInvoicesTotalAmount = (invoices, func) => {
  return invoices.reduce((acc, invoice) => {
    acc += func(invoice);
    return acc;
  }, 0);
};

