import { Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { useEffect } from 'react';

export const AlertComponent = ({
  variant = 'outlined',
  severity = 'success',
  children,
  icon,
  onClose = undefined,
  sx,
  waiting = true,
}) => {
  useEffect(() => {
    if (waiting) {
      setTimeout(() => onClose && onClose(), 5000);
    }
    return () => {};
  }, []);

  return (
    <Alert icon={icon} sx={sx} onClose={onClose} variant={variant} severity={severity}>
      <Typography variant="body2" textAlign="justify">
        {children}
      </Typography>
    </Alert>
  );
};
