import { SupportedChainId } from './chains';


export const MULTI_SEND_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.OPTIMISM]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.ARBITRUM_ONE]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.POLYGON]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.CELO]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.BSC]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.BSC_TEST]: '0x45Bb2366E0b43e1e45aDb7128C0150339FCc4729',
  [SupportedChainId.AVALANCHE]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.GODWOKEN]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0', // new version
  // [SupportedChainId.GODWOKEN]: '0x1bb79e75a062ff90F8E79FE281f41324C3052afc',
  [SupportedChainId.FANTOM]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.GNOSIS]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.MOONBEAM]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.OASIS_EMERALD]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.OASIS_SAPPHIRE]: '0xa673810C62c2BfaF261614F72bC01e79bC6F19fA',
  [SupportedChainId.FUSE]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.AURORA]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.ZKSYNC]: '0xc094c359187C92A9F113e41D24c2fDAd8130497A',
  [SupportedChainId.HARMONY]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.MOONRIVER]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.HECO]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.OKXCHAIN]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.TELOS]: '0x82679f36C862E60aAA2506aD26F8c6DE1dA9B5b0',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.CRONOS]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.CRONOS_TESTNET]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  // [SupportedChainId.SKALE_EUROPA_TEST]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x5DA5a85B57822501a26B778Cf90c078c04cfDbf6', // new version
  [SupportedChainId.SKALE_EUROPA]: '0x8CACd4402E2F4248dc7CdF36f4049997F5B57aF7',
  // [SupportedChainId.SKALE_TESTNET]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.IOTEX]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.IOTEX_TESTNET]: '0x42B28E2Dc1843A636347C1D521d08711Ac18B2FB',
  [SupportedChainId.TRON_GRID_MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TRON_NILE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.APTOS_MAINNET]: '0x0000000000000000000000000000000000000000',
};

export const MULTI_SEND_CONTRACTS_TRON = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TRON_GRID_MAINNET]: 'TE6hpzuBVxMLWcoiUk9EZGF8TxBNSKSrn8',
  [SupportedChainId.TRON_NILE_TESTNET]: 'TWjHDth59SwTkMHWXk3gwBPUquYn1nYH4g',
  [SupportedChainId.APTOS_MAINNET]: '0x0000000000000000000000000000000000000000',
};

export const MULTI_SEND_CONTRACTS_APTOS = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TRON_GRID_MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TRON_NILE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.APTOS_MAINNET]:
    '0x9e2c76d559f4b68ab913f3fd2d25b92acbcb5f6bba6e8e5f0f9c996cfa921e0d',
};

export const MULTI_CLAIM_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.OPTIMISM]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.ARBITRUM_ONE]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.POLYGON]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.CELO]: '0xbd2928EB64Bc6518FD689bdeBCaDF6E89D3d6ceb',
  [SupportedChainId.BSC]: '0x4cA8b6F7F2490b6eE783C6eF06EC505f0A3F22cD',
  // [SupportedChainId.BSC]: '0x64C4eD8B34710fe29D1ec4cC8B52C11Dc3e45F63', old
  // [SupportedChainId.BSC_TEST]: '0x5667e1ddBFb35278f4Bc755FCBD1E1419ecdCCF5',
  // [SupportedChainId.BSC_TEST]: '0x4DC537e4B5a470410aB2b137aeAA2DbA22C2C752', old version - one claim
  [SupportedChainId.AVALANCHE]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.GODWOKEN]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.FANTOM]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.GNOSIS]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.MOONBEAM]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.OASIS_EMERALD]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.OASIS_SAPPHIRE]: '0xbd2928EB64Bc6518FD689bdeBCaDF6E89D3d6ceb',
  [SupportedChainId.FUSE]: '0x4cA8b6F7F2490b6eE783C6eF06EC505f0A3F22cD',
  [SupportedChainId.AURORA]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.ZKSYNC]: '0x0138173694578dDEd75606feaEEAD878233fe624',
  [SupportedChainId.SKALE_EUROPA]: '0xe2F5c759848Ac8746c1fa7C355593e0d5f8b591c',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x1D0213029914519b275088A19b2b0BEEe809c18D',
  // [SupportedChainId.ZKSYNC]: '0x57F15f549363741c0c66C190Ece87D906746B7e1',
  [SupportedChainId.HARMONY]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.MOONRIVER]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.HECO]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.OKXCHAIN]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.TELOS]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
};
// 0xe2F5c759848Ac8746c1fa7C355593e0d5f8b591c

export const MULTI_SEND_NFTS_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x3C16FD3a5BaAC4e85b0172291cD12d37156cEF54',
  [SupportedChainId.OPTIMISM]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.ARBITRUM_ONE]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.POLYGON]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.CELO]: '0x4cA8b6F7F2490b6eE783C6eF06EC505f0A3F22cD',
  [SupportedChainId.BSC]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.BSC_TEST]: '0xD35AA04F7Ba71aF9bf8a921a7AE6b86eF21Ab0e4',
  [SupportedChainId.AVALANCHE]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.GODWOKEN]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.FANTOM]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.GNOSIS]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.MOONBEAM]: '0xB0dE7890e79B6585eeFB89C8F7506383862e4a4a',
  [SupportedChainId.OASIS_EMERALD]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x4cA8b6F7F2490b6eE783C6eF06EC505f0A3F22cD',
  [SupportedChainId.FUSE]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.AURORA]: '0xaFa926Ba26b7156eC9759eEBB95CE523397B6Ba9',
  [SupportedChainId.ZKSYNC]: '0xcFb5919260Dab14494124eF71EbF6B14a041b87B',
  [SupportedChainId.HARMONY]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.MOONRIVER]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.HECO]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.OKXCHAIN]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.TELOS]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
};

export const MULTI_CLAIM_NFTS_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x297acd37Fb921f07E03384a02C04429f13C7f7B9',
  [SupportedChainId.OPTIMISM]: '0xB0dE7890e79B6585eeFB89C8F7506383862e4a4a',
  [SupportedChainId.ARBITRUM_ONE]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.POLYGON]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.CELO]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.BSC]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.BSC_TEST]: '0x5052a8B5Ae77d8dE4C1fcfe80C9c6D268ADc4404',
  [SupportedChainId.AVALANCHE]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.GODWOKEN]: '0xB0dE7890e79B6585eeFB89C8F7506383862e4a4a',
  [SupportedChainId.FANTOM]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.GNOSIS]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.MOONBEAM]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.OASIS_EMERALD]: '0xB0dE7890e79B6585eeFB89C8F7506383862e4a4a',
  [SupportedChainId.OASIS_SAPPHIRE]: '0xed7c5B93869dc3a1A8c1D2AeAb22e7a0733774FC',
  [SupportedChainId.FUSE]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.AURORA]: '0xB0dE7890e79B6585eeFB89C8F7506383862e4a4a',
  [SupportedChainId.ZKSYNC]: '0x94A692219CBA838A2505F4e82976f75Fc3c2762e',
  [SupportedChainId.HARMONY]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.MOONRIVER]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.HECO]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.OKXCHAIN]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.TELOS]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
};

export const SWAP_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8', //old
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.POLYGON]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8', // deployed
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8', // deployed
  [SupportedChainId.AVALANCHE]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FANTOM]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.GNOSIS]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
  [SupportedChainId.MOONRIVER]: '0x880070f6f4690411EFa77abaadf9ae60B1048EE8',
};

export const WRAPPED_NATIVE_CURRENCY = {
  [SupportedChainId.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  [SupportedChainId.POLYGON]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  [SupportedChainId.AVALANCHE]: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FANTOM]: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  [SupportedChainId.GNOSIS]: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0be9e53fd7edac9f859882afdda116645287c629',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
  [SupportedChainId.MOONRIVER]: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
};

export const SUSHI_V2_FACTORY = {
  [SupportedChainId.MAINNET]: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.POLYGON]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.CELO]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.BSC]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.AVALANCHE]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FANTOM]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.GNOSIS]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.MOONBEAM]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x43eA90e2b786728520e4f930d2A71a477BF2737C',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
  [SupportedChainId.MOONRIVER]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
};

export const COIN_CRATER_FACTORY = {
  [SupportedChainId.MAINNET]: '0xc38FE13fAbf92bA2193adA6C7fa820F3AaB55649',
  [SupportedChainId.OPTIMISM]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.ARBITRUM_ONE]: '0x863f8aE5e0189775805564CCEE4AC9a7C0f9425D',
  [SupportedChainId.POLYGON]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.CELO]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.BSC]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  // [SupportedChainId.BSC]: '0x152d285C3E5860d96dC0cD9C3Dc4861c9DAfA59e', old version
  [SupportedChainId.BSC_TEST]: '0x810c611aF5c270D28e440eF3702B3AEf8117a78d',
  // [SupportedChainId.BSC_TEST]: '0x4DC537e4B5a470410aB2b137aeAA2DbA22C2C752', old version - one claim
  [SupportedChainId.AVALANCHE]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.GODWOKEN]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.FANTOM]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.GNOSIS]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.MOONBEAM]: '0xF5F6f66340109B075Cf94Dd096b2A838d99903f3',
  [SupportedChainId.OASIS_EMERALD]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x438377BB9619B07799cb80c51cf2667a04Ba1d4F',
  [SupportedChainId.FUSE]: '0x863f8aE5e0189775805564CCEE4AC9a7C0f9425D',
  [SupportedChainId.AURORA]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.ZKSYNC]: '0xbb1C32798c54BF53206D0A663B44992652727Ee8',
  [SupportedChainId.HARMONY]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.MOONRIVER]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.HECO]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.OKXCHAIN]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.TELOS]: '0x5A0F6631b38483deA56caBd56d77a3c5C583f1af',
  [SupportedChainId.SKALE_EUROPA]: '0xb17A2f5040C7BDe9d52996b5679Fc3C3C51825fb',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x15E4b80c0d5af75C7Ca4040CcE3dfeA26289D293',
  // 0xb17A2f5040C7BDe9d52996b5679Fc3C3C51825fb
};

export const COIN_SENDER_FACTORY_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0xBD01Ac14B39c8bfc21f121b4A9063dAb36D3B7bE',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000', // new version
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  // [SupportedChainId.SKALE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
};

export const REGISTRY_FACTORY_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0x6cd3Ff330ffF626bF37033Eb00E5cE6c4a3dd3e6',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000', // new version
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  // [SupportedChainId.SKALE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
};

export const COINSENDER_FACTORY_ERC721 = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0xD6181bc17C713eb103D67b79CED337e0b0d929f2',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000', // new version
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  // [SupportedChainId.SKALE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
};

export const COINSENDER_FACTORY_ERC1155 = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0xcA4E4232a15a0955129Da6cAd429D077a70ea196',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000', // new version
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA]: '0x0000000000000000000000000000000000000000',
  // [SupportedChainId.SKALE_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
};

export const SKALE_DISTRIBUTION_CONTRACTS = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CELO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC_TEST]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000', // new version
  [SupportedChainId.FANTOM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OASIS_SAPPHIRE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.FUSE]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.AURORA]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.HECO]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.TELOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.CRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.SKALE_EUROPA_TEST]: '0x436389289aEAFefD1d7471b7FbEc67539Bde3E34',
  [SupportedChainId.SKALE_EUROPA]: '0x2B267A3e49b351DEdac892400a530ABb2f899d23',
  // [SupportedChainId.SKALE_TESTNET]: '0x1B2e7E6E66a6c202cdC0C31DF996b530af22CBee',
  [SupportedChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
};
