import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import React, { forwardRef } from 'react';
// material
import { Box, Container, Stack, Typography, useTheme, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import LeftIcon from '../assets/icons/arrow-button-left.svg';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const SContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: '0 24px 0 24px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 32px 0 32px',
  },
}));

const PageWrapper = forwardRef(({
  children,
  title = '',
  path,
  button_name,
  button_route,
  button_icon,
  handler,
  border
}, ref) => {
  const { spacing } = useTheme();
  return (
    <Box ref={ref}>
      <Helmet>
        <title>{title} | CoinSender</title>
      </Helmet>
      <SContainer maxWidth="4000px" disableGutters>
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          position="relative"
          justifyContent="space-between"
          mt={{ xs: spacing(3), md: spacing(9) }}
          mb={spacing(3)}
        >
          <Stack borderBottom={border ? '1px solid #FFA31A' : 'none'}>
            <Typography variant="subtitle1">{title}</Typography>
          </Stack>
          {path && handler && (
            <Stack onClick={handler} style={{ position: 'absolute', top: '-100%', cursor: 'pointer' }}>
              <img width="20px" height="20px" src={LeftIcon} alt=""/>
            </Stack>
          )}
          {path && !handler && (
            <Link to={path} style={{ position: 'absolute', top: '-100%', cursor: 'pointer' }}>
              <img width="20px" height="20px" src={LeftIcon} alt="Back"/>
            </Link>
          )}

          <Button
            variant="contained"
            component={Link}
            to={button_route || "#"}
            startIcon={button_icon ? <Iconify icon="eva:plus-fill"/> : null}
            style={{ visibility: button_name && button_route ? "visible" : "hidden" }}
          >
            {button_name || " "}
          </Button>
        </Stack>
        {children}
      </SContainer>
    </Box>
  );
});

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  button_name: PropTypes.string,
  button_route: PropTypes.string,
  button_icon: PropTypes.string,
  handler: PropTypes.func,
  border: PropTypes.bool,
};

export default PageWrapper;
