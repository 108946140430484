// ----------------------------------------------------------------------

import { useMediaQuery } from '@mui/material';

export default function Input(theme) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottomColor: '#FFFFFF !important',
          },
          '&.Mui-error::before': {
            borderBottomColor: '#FF4842 !important',
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            fontSize: isMobile ? '10px !important' : '14px !important',
            color: '#00000099',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: isMobile ? '10px !important' : '14px !important',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: isMobile ? '10px !important' : '14px !important',

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500_32],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'white',
          fontSize: '12px',
          '&.Mui-active': {
            fontWeight: 400,
            color: 'white',
          },
          '&.Mui-completed': {
            fontWeight: 400,
            color: 'white',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          color: 'white',
          fontSize: '12px',
          '&.Mui-active': {
            fontWeight: 400,
            color: 'white',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'white',
          },
        },
      },
    },
  };
}
