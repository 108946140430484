import { useEffect, useRef, useState, createContext, FC, useContext, ReactNode } from 'react';

export const WalletContext = createContext({
  wallet: '',
  setWallet: (localWallet) => null,
  walletError: '',
  setWalletError: (error) => null,
  walletAddress: '',
  walletType: '',
  isEvm: null,
  provider: null,
  connector: null,
  chainId: 1,
  balance: null,
});
