export const trimAddress = (address) => {
  if (!address || typeof address !== 'string') {
    return null;
  }

  const start = address.slice(0, 5);
  const end = address.slice(-5);

  return `${start}...${end}`;
};
