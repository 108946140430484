import {
  Alert,
  Grid,
  Modal,
  Stack,
  Typography,
  Checkbox,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import Scrollbar from 'src/components/Scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { convertToLowerCase } from 'src/commonHelpers/commonHelpers';

export const ImportRecipientsModal = ({
  open,
  setOpen,
  importSelected,
  setImportSelected,
  handleSelectAllClick,
  selectImportUser,
  employees,
  style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', md: '50%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 2,
  },
}) => {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const filteredEmployees = employees
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
      .filter(
        ({ name, second_name, add_info, wallet_id }) =>
          convertToLowerCase(name).includes(convertToLowerCase(searchValue)) ||
          convertToLowerCase(second_name).includes(convertToLowerCase(searchValue)) ||
          convertToLowerCase(wallet_id).includes(convertToLowerCase(searchValue)) ||
          (add_info && convertToLowerCase(add_info).includes(convertToLowerCase(searchValue))),
      );
    setTableData(filteredEmployees);
  }, [employees, searchValue]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setImportSelected([]);
        setSearchValue('');
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Alert severity="info">
          <Typography variant="body1">Choose the recipients:</Typography>
        </Alert>
        <Stack mt={2}>
          <TextField
            size="small"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Stack>
        <Stack width="100%" mt={2} height="100%">
          <Scrollbar
            sx={{
              height: '300px',
              maxHeight: '300px',
              '& .simplebar-content': {},
            }}
          >
            <Grid container spacing={1} height="100%">
              <Grid container spacing={1} item xs={12} alignItems="center">
                <Grid item>
                  <Checkbox
                    checked={tableData.length > 0 && importSelected.length === tableData.length}
                    onChange={handleSelectAllClick}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight={600}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight={600}>
                    Surname
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight={600}>
                    Position
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight={600}>
                    Network
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" fontWeight={600}>
                    Wallet Id
                  </Typography>
                </Grid>
              </Grid>
              {tableData.length > 0 ? (
                tableData.map(({ id, name, second_name, position, network_id, wallet_id }) => (
                  <Grid container spacing={1} item xs={12} key={id} alignItems="center">
                    <Grid item>
                      <Checkbox
                        checked={importSelected.includes(id)}
                        onClick={() => selectImportUser(id)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">{name || '-'}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">{second_name || '-'}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">{position || '-'}</Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="body2">{network_id || '-'}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">
                        {wallet_id
                          ? `${wallet_id.substring(0, 4 + 2)}...${wallet_id.substring(42 - 4)}`
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign="center" alignItems="center" justifyContent="center">
                  <Typography variant="body2">No recipients yet...</Typography>
                </Grid>
              )}
            </Grid>
          </Scrollbar>

          <Stack mt={2}>
            <Button
              variant="contained"
              onClick={() => {
                dispatch({ type: 'IMPORT_TRANSFERS_SAGA', payload: importSelected });
                setImportSelected([]);
                setOpen(false);
                setSearchValue('');
                // if (importSelected.length === employees.length) {
                //   dispatch({ type: 'IMPORT_TRANSFERS_ALL_SAGA' });
                // } else {
                //   dispatch({ type: 'IMPORT_TRANSFERS_SAGA', payload: importSelected });
                // }
                // setImportSelected([]);
                // setOpen(false);
              }}
              disabled={!importSelected.length}
            >
              Import
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
