import { Box, Container, InputAdornment, Stack, TextField } from '@mui/material';
import Page from '../components/Page';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle } from 'src/components/PageTitle';
import EnhancedTable from 'src/components/Table/Table';
import { Row } from './Employees/components/Row';
import SearchIcon from '@mui/icons-material/Search';
import { convertToFloat, convertToLowerCase } from 'src/commonHelpers/commonHelpers';
import { deleteEmployeeBathSagaRequest } from '../redux/employees/actions';
import PageWrapper from '../components/PageWrapper';

export default function Employees() {
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const addRowTemplate = {
    name: '',
    second_name: '',
    position: '',
    network_id: '',
    wallet_id: '',
    amount: '',
    add_info: '',
  };

  const employees = useSelector(({ employees: { employeeList } }) => employeeList);
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'GET_EMPLOYEE_LIST' });
  }, []);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'second_name',
      numeric: false,
      disablePadding: false,
      label: 'Surname',
    },
    {
      id: 'position',
      numeric: false,
      disablePadding: false,
      label: 'Position',
    },
    {
      id: 'network_id',
      numeric: false,
      disablePadding: false,
      label: 'Network',
    },
    {
      id: 'wallet_id',
      numeric: false,
      disablePadding: false,
      label: 'Wallet Id ',
    },
    {
      id: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'add_info',
      numeric: false,
      disablePadding: false,
      label: 'Notes',
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const handleSaveEditRow = (values, isEdit, isNew) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('second_name', values.second_name);
    formData.append('position', values.position || '');
    formData.append('network_id', values.network_id);
    formData.append('wallet_id', values.wallet_id);
    formData.append('amount', convertToFloat(values.amount));
    formData.append('add_info', values.add_info || '');
    formData.append('id', values.id);
    if (isEdit && !isNew) {
      const currentItem = tableData.find(({ id }) => id === values.id);
      const result = tableData.filter(({ id }) => id !== currentItem.id);
      dispatch({
        type: 'EDIT_EMPLOYEE_PROFILE',
        payload: formData,
        id: values.id,
        flag: 'edit',
      });
      setTableData(() => [...result, { ...currentItem, isEdit: false }]);
      return;
    }
    dispatch({
      type: 'ADD_EMPLOYEE_SAGA',
      payload: formData,
    });
  };

  const deleteDispatchRecipient = () =>
    dispatch(deleteEmployeeBathSagaRequest({ ids: selectedRows.map((item) => item.id) }));

  useEffect(() => {
    const filteredEmployees = employees
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
      .filter(
        ({ name, second_name, add_info, wallet_id }) =>
          convertToLowerCase(name).includes(convertToLowerCase(searchValue)) ||
          convertToLowerCase(second_name).includes(convertToLowerCase(searchValue)) ||
          convertToLowerCase(wallet_id).includes(convertToLowerCase(searchValue)) ||
          (add_info && convertToLowerCase(add_info).includes(convertToLowerCase(searchValue))),
      );
    setTableData(filteredEmployees);
  }, [employees, searchValue]);

  return (
    <PageWrapper title="Recipients">
      <Stack>
        <Box sx={{ pb: 5 }}>
          <Container sx={{ display: 'flex', padding: '0!important' }}></Container>
          <Stack mb={3}>
            <TextField
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
          </Stack>
          <EnhancedTable
            setTableData={setTableData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            headCells={headCells}
            data={tableData}
            addRowTemplate={addRowTemplate}
            handleSaveEditRow={handleSaveEditRow}
            deleteHandler={deleteDispatchRecipient}
            emptyStatusText="You haven't created a recipient yet."
            RowComponent={Row}
          />
        </Box>
      </Stack>
    </PageWrapper>
  );
}
