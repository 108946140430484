import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { Typography, CircularProgress, Card, Box, Link, Button, Dialog, DialogTitle, DialogContent, Stack } from '@mui/material';
// import jwt_decode from 'jwt-decode'

// import './App.css'
import { pascalStrToSpacedWord } from './components/helper'
import FormPolygonID from './components/FormPolygonID'
import {getSignInPolygonIdRequest} from "../../redux/auth/actions";
import {polygonIdStatus, selectPolygonIdAuthReq, selectPolygonIdIsSuccess} from "../../redux/auth/reducer";
import QRCode from "qrcode.react";
import {useNavigate} from "react-router-dom";
import QRCodeModal from './components/QRCodeModal';
import ContentWrapper from "../../components/ContentWrapper";

function PolygonID({ identifier, user }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { qrCode, linkDetail: {schemaType} } = useSelector(state => state.AuthUser.qrCodeLink);

    const polygonIdAuthReq = useSelector(selectPolygonIdAuthReq);
    const polygonIdIsSuccess = useSelector(selectPolygonIdIsSuccess);
    const polygonIdStatusReq = useSelector(polygonIdStatus);

    const [qrCodeModalOpen, setQrCodeModalOpen] = React.useState(false);


    useEffect(() => {
        if (qrCode) {
            setQrCodeModalOpen(true);
        }
    }, [ qrCode ])

    const [token, setToken] = useState(null)
    const [schema, setSchema] = useState(null)
    const [schemaLink, setClaimLink] = useState(null)

    const [open, setOpen] = useState(false);

    const handleConnectPIOpen = () => {
        dispatch(getSignInPolygonIdRequest());
        setOpen(true);
    }

    const handleCloseQrCodeModal = () => {
        setQrCodeModalOpen(false);
    }

    const handleClose = () => setOpen(false);

    const handleGetQrCodeClick = () => {
        dispatch({ type: 'GET_QRCODE_LINK_REQUEST'});
        setQrCodeModalOpen(true);
    }

    useEffect(() => {
        if (!polygonIdStatusReq.isAuth && !polygonIdStatusReq.identifier) return;

        handleCloseQrCodeModal();
        if (!user.identifier) {
            dispatch({
                type: 'EDIT_USER_PROFILE_IDENTIFIER',
                payload: {
                    identifier: polygonIdStatusReq.identifier
                },
                navigate,
            });
        }
    }, [polygonIdStatusReq]);

    const deleteIdentifier = () => {
        dispatch({
            type: 'EDIT_USER_PROFILE_IDENTIFIER',
            payload: {
                identifier: null
            },
            navigate,
        });
    }

    const renderIdentifier = () => {
        if (!identifier) {
            // return <Button onClick={handleConnectPIOpen}>Connect identifier</Button>;
            return <Button onClick={handleGetQrCodeClick}>Get Proof Coinsender Admin</Button>;
        }

        const shortenedIdentifier = `${identifier.slice(0, 10)}...${identifier.slice(-10)}`;

        return (
            <Stack  direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
            >
                <Typography variant="body2"><b>Proof of Coinsender user role admin:</b> </Typography>
                <Typography variant="body2">{shortenedIdentifier}</Typography>
                {/*<Button onClick={handleConnectPIOpen}>Update</Button>*/}
                <Button onClick={deleteIdentifier}>Delete</Button>
            </Stack>
        );
    };

    return (
        <ContentWrapper>
            <Stack  direction="row" spacing={2}>
                {renderIdentifier()}
            </Stack>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update identifier</DialogTitle>
                <DialogContent>
                    {polygonIdIsSuccess && (
                        <div>
                            <QRCode
                                level="Q"
                                value={JSON.stringify(polygonIdAuthReq?.request)}
                                size={256} // это изменит размер QR кода
                            />
                        </div>
                    )}
                </DialogContent>
            </Dialog>

            <QRCodeModal isOpen={qrCodeModalOpen} handleClose={handleCloseQrCodeModal} qrCode={qrCode} schemaType={schemaType}/>
        </ContentWrapper>
    );


    // return !!qrCode ? (
    //     <>
    //         <Card sx={{ maxWidth: 'xl', p: 10, bgcolor: 'rgba(255,255,255,.90)' }}>
    //             <Typography variant="h5" mb={5}>
    //                 Claim {pascalStrToSpacedWord(schema.schema)}
    //             </Typography>
    //             <FormPolygonID fieldInfo={schema?.attributes} passBackResults={handleResults} />
    //         </Card>
    //         <Box m={10}>
    //             <Typography variant="subtitle2" className="builtBy">
    //                 Template built by{' '}
    //                 <Link href="https://twitter.com/0ceans404" target="_blank">
    //                     🌊 oceans404
    //                 </Link>
    //             </Typography>
    //         </Box>
    //     </>
    // ) : (
    //     <div>
    //         11111
    //         <CircularProgress size="xl"  />
    //     </div>
    //
    // )
}

export default PolygonID
