import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack, TextField, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isAddress } from '@ethersproject/address';
import { useDispatch } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  maxWidth: '440px',
  width: '100%',
  p: 2,
};

const initialValues = {
  wallet_address: '',
  wallet_name: '',
  network: '',
};

const validationSchema = Yup.object().shape({
  wallet_address: Yup.string()
    .label('Wallet address')
    // .test('Is address', 'Please enter correct wallet address', (value) => isAddress(value))
    .required('Is required'),
  wallet_name: Yup.string().label('Wallet name').required('Is required'),
  network: Yup.string().label('Network').required('Is required'),
});

export default function ReceiveModal({ open, close }) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleClose = () => close();

  const { touched, errors, handleSubmit, getFieldProps, isValid } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch({
        type: 'ADD_GENERAL_WALLET',
        payload: values,
        org_id: currentUser.organization_id,
      });
      handleClose();
      resetForm();
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid style={{ marginBottom: 2 }} container spacing={2}>
          <Grid item xs={12}>
            <Stack mb={2}>
              <Typography variant="subtitle1">How do you want to receive funds?</Typography>
            </Stack>
            <TextField
              fullWidth
              size="small"
              label={t('Wallet name')}
              {...getFieldProps('wallet_name')}
              error={Boolean(touched.wallet_name && errors.wallet_name)}
              helperText={Boolean(touched.wallet_name && errors.wallet_name) && errors.wallet_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Network"
              {...getFieldProps('network')}
              error={Boolean(touched.network && errors.network)}
              helperText={touched.network && errors.network}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label={t('Wallet address')}
              {...getFieldProps('wallet_address')}
              error={Boolean(touched.wallet_address && errors.wallet_address)}
              helperText={
                Boolean(touched.wallet_address && errors.wallet_address) && errors.wallet_address
              }
            />
          </Grid>
        </Grid>
        <Button
          sx={{ marginTop: '10px' }}
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit}
        >
          Save Wallet
        </Button>
      </Box>
    </Modal>
  );
}
