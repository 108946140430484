import { Typography } from '@mui/material';
import { saveAs } from 'file-saver';

export const DownloadWallets = ({ wallets, name }) => {
  const downloadNotRegisteredCSV = (arr) => {
    const removedDuplicates = [...new Set(arr)];
    const csvData = removedDuplicates.map((address) => [address]).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${name}.csv`);
  };

  return (
    <Typography
      component="a"
      variant="body2"
      sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} // Add link styles
      onClick={() => downloadNotRegisteredCSV(wallets)}
    >
      Download not registered wallets
    </Typography>
  );
};
