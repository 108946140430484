import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
import { PROFILE } from '../../constants/routes';
import { LOGOUT_USER } from '../../constants/actions';
// import { AVATAR_URL } from 'src/constants/defaultURL';
import { stringAvatar } from 'src/utils/stringAvatar';
import { roles } from '../../pages/Profile/components/TeamTab';
import { loginUserTeam } from '../../redux/auth/actions';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'My account',
    icon: 'eva:person-fill',
    linkTo: PROFILE,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const token = localStorage.getItem('access_token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { email, password } = user;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLogoutTeam = (organizationId) => () => {
    dispatch(loginUserTeam(organizationId, navigate));
  };

  const mockLogout = () => {
    dispatch({ type: LOGOUT_USER, navigate });
    localStorage.setItem('provider', null);
    // logoutUser();
    // removeDataFromLocalstorage("access_token");
    // removeDataFromLocalstorage("authorization_login");
    // removeDataFromLocalstorage("authorization_login");
    // navigate(SIGN_IN, { replace: true });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,

          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar
          src={'/images/example.jpg'}
          sx={{
            background: (theme) => alpha(theme.palette.grey[900], 0.5),
            width: { xs: 32, md: 40 },
            height: { xs: 32, md: 40 },
          }}
          {...stringAvatar(user?.name, user?.second_name)}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 350 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" noWrap>
            {user?.name || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email || ''}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            {option.icon && (
              <Iconify
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
            )}
            <Typography variant="body2">{option.label}</Typography>
          </MenuItem>
        ))}
        <a href="https://coinsender.io/" style={{ textDecoration: 'none' }}>
          <MenuItem
            onClick={handleClose}
            sx={{ color: 'black', typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon="eva:home-fill"
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            <Typography variant="body2">Landing</Typography>
          </MenuItem>
        </a>
        <a href="https://dapp.coinsender.io/" style={{ textDecoration: 'none' }}>
          <MenuItem
            onClick={handleClose}
            sx={{ color: 'black', typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon="eva:paper-plane-fill"
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            <Typography variant="body2">DAPP</Typography>
          </MenuItem>
        </a>

        <Divider sx={{ my: 1 }} />

        {user &&
          user.teams?.map(({ company_name, role, organization_id }, index) => (
            <MenuItem
              key={index}
              sx={{ color: 'black', typography: 'body2', py: 1, px: 2.5 }}
              onClick={onLogoutTeam(organization_id)}
              disabled={user.organization_id === organization_id}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Stack>
                    <Typography variant="body2">{company_name}</Typography>
                    <Typography variant="body2">{roles.find((i) => i.id === role).name}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" alignItems="center" height="100%">
                    <Typography variant="body2" color="textSecondary">
                      {user.organization_id === organization_id ? 'You are here' : ''}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </MenuItem>
          ))}

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton fullWidth size="medium" onClick={mockLogout} variant="contained">
            <Typography variant="body2">Logout</Typography>
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
