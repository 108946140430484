import {
  ADD_TEAM_REQUEST,
  DELETE_TEAM_REQUEST,
  EDIT_TEAM_REQUEST,
  GET_TEAMS_REQUEST,
} from '../../constants/actions';

export const getTeamsAction = () => ({
  type: GET_TEAMS_REQUEST,
});

export const addTeamAction = (team) => ({
  type: ADD_TEAM_REQUEST,
  payload: team,
});

export const deleteTeamAction = (teamIds) => ({
  type: DELETE_TEAM_REQUEST,
  payload: teamIds,
});

export const editTeamAction = (team) => ({
  type: EDIT_TEAM_REQUEST,
  payload: team,
});
