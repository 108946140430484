import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  makeStyles,
  Box,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import PoligonIdLogo from '../../../../assets/sign-in/polygon-id-logo.svg';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  logo: {
    maxHeight: '24px',
    maxWidth: '24px',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  qrCodeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px 0`,
    height: '256px',
  },
  qrCodeBorder: {
    border: '1px solid #FFFFFF',
    background: '#FFFFFF',
    width: '258px',
    height: '258px',
  },
  note: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.49)',
    fontSize: '9px !important',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredText: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    fontFamily: 'MullerRegular',
  },
  dialogPaper: {
    background: 'linear-gradient(180deg, #141728 0%, #124453 100%)',
    boxShadow: '24px -32px 240px rgba(21, 22, 40, 0.56)',
    padding: theme.spacing(5, 4),
    borderRadius: '24px',
    color: 'white',
  },
  centeredTitle: {
    textAlign: 'center',
    width: '100%',
    fontFamily: 'MullerRegular',
    fontSize: '16px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

function Space(props) {
  return null;
}

Space.propTypes = { children: PropTypes.node };

function PolygonIdDialog({ open, handleClose, polygonIdIsSuccess, polygonIdAuthReq }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (polygonIdAuthReq?.request) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [polygonIdAuthReq]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      TransitionComponent={Fade}
      transitionDuration={150}
    >
      <DialogTitle>
        <Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.centeredTitle}>
          <img className={classes.logo} src={PoligonIdLogo} alt="PolygonId Logo" />
          PolygonId Authorization
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.centeredText} gutterBottom variant="body2">
          To sign in to our platform via PolygonId, please scan the QR code below.
        </Typography>
        <div className={classes.qrCodeWrapper}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className={classes.qrCodeBorder}>
              <QRCode level="Q" value={JSON.stringify(polygonIdAuthReq?.request)} size={256} />
            </div>
          )}
        </div>
        <Typography variant="body2" className={classes.note}>
          Note: To authorize via PolygonId, you need to add proof in your account settings within
          your profile.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default PolygonIdDialog;
