import { CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';

export const Loader = ({ title }) => {
  useEffect(() => {
    const body = document.body;
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Stack
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
        background: 'rgba(0,0,0,0.1)',
        gap: 2,
      }}
    >
      <Stack>
        <CircularProgress />
      </Stack>
      {title && <Stack>{title}</Stack>}
    </Stack>
  );
};
