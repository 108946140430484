import { Typography, Stack, TextField, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { convertToLowerCase, sortStringValuesTwoWays } from '../commonHelpers/commonHelpers';
import { NEW_CLIENT } from '../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { ListComponent } from 'src/components/List';
import PageWrapper from '../components/PageWrapper';
import { Search } from '@material-ui/icons';

export default function Employees() {
  const [value, setValue] = React.useState('az');
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);

  const clients = useSelector(({ employees: { clientList } }) => clientList);
  const isLoading = useSelector(({ employees: { isLoading } }) => isLoading);
  const dispatch = useDispatch();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  useEffect(() => {
    dispatch({ type: 'GET_CLIENT_LIST', payload: currentUser.organization_id });
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const filteredClients = clients
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
      .filter(({ name }) => convertToLowerCase(name).includes(convertToLowerCase(searchValue)));
    setTableData(filteredClients);
  }, [clients, searchValue]);

  return (
    <PageWrapper title="Customers" button_name="Add customer" button_route={NEW_CLIENT}>
      <Stack>
        {/*<Container sx={{ display: 'flex', padding: '0!important' }}></Container>*/}
        <Stack mb={3}>
          <TextField
            size="small"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Stack>
        {clients.length === 0 && !isLoading ? (
          <Typography textAlign="center" variant="subtitle2">
            You haven't created a customer yet.
          </Typography>
        ) : (
          <>
            {clients && tableData && (
              <ListComponent isPartner={true} handleOpen={handleOpen} items={tableData} />
            )}
          </>
        )}
      </Stack>
    </PageWrapper>
  );
}
