/* eslint-disable */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { Container, Stack, Typography, Button, useTheme, Modal } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProfileTab from './components/ProfileTab';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePassFormProfile } from './components/ChangePassFormProfile';
import { EDIT_COMPANY, EDIT_PROFILE } from 'src/constants/routes';
import { PageLayout } from 'src/layouts/PagesLayout';
import { PageTitle } from 'src/components/PageTitle';
import { WalletsTab } from './components/WalletsTab';
import { CompanyTab } from './components/Company';
import GroupIcon from '@mui/icons-material/Group';
import { TeamTab } from './components/TeamTab';
import { InvoiceSettings } from './components/InvoiceSettings';
import CredentialQR from './components/CredentialQR';
import QRCodeModal from './components/QRCodeModal';
import { getQrCodeLinkRequest } from '../../redux/actions';
import PolygonID from './PolygonID';
import PolygonIdLogo from '../../assets/sign-in/poligon-id-logo.js';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { SupportTab } from './components/SupportTab';

export const Profile = () => {
  const { t } = useTranslation('common');
  const [value, setValue] = React.useState('1');
  const [open, setIsOpen] = React.useState(false);
  // const { qrCode, linkDetail: {schemaType} } = useSelector(state => state.AuthUser.qrCodeLink);

  const [qrCodeModalOpen, setQrCodeModalOpen] = React.useState(false);

  const user = JSON.parse(localStorage.getItem('currentUser'));

  const { spacing } = useTheme();

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    setIsOpen((prev) => !prev);
  };

  // const handleGetQrCodeClick = () => {
  //   dispatch({ type: 'GET_QRCODE_LINK_REQUEST'});
  //   // setQrCodeModalOpen(true);
  // }

  const handleCloseQrCodeModal = () => {
    setQrCodeModalOpen(false);
  };

  useEffect(() => {
    // dispatch({ type: 'GET_EMPLOYEE_SAGA', payload: user?.id });
    dispatch({ type: 'GET_GENERAL_WALLETS', payload: user.organization_id });
    dispatch({ type: 'GET_ORGANISATION_BY_ID', payload: user?.organization_id });
  }, []);

  return (
    <Page title="Profile | CoinSender">
      <PageLayout>
        <Stack mt={{ xs: spacing(3), md: spacing(9) }}>
          <TabContext value={value}>
            <Stack
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TabList
                variant="scrollable"
                scrollButtons={false}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab icon={<AccountBoxIcon />} iconPosition="start" label={'Profile'} value="1" />
                <Tab
                  icon={<AccountBalanceWalletIcon />}
                  iconPosition="start"
                  label={t('Wallets')}
                  value="3"
                />
                <Tab icon={<BusinessIcon />} iconPosition="start" label={t('Company')} value="4" />
                <Tab icon={<GroupIcon />} iconPosition="start" label={t('Team')} value="5" />
                <Tab
                  icon={<SettingsIcon />}
                  iconPosition="start"
                  label="Invoice settings"
                  value="2"
                />
                <Tab icon={<PolygonIdLogo />} iconPosition="start" label={'Polygon ID'} value="6" />
                <Tab icon={<SupportAgentIcon />} iconPosition="start" label={'Support'} value="7" />
              </TabList>

              {/*{value === '6' && handleGetQrCodeClick() && (*/}
              {/*    <Stack>*/}
              {/*      <Button variant="contained" color="primary" onClick={handleGetQrCodeClick}>*/}
              {/*        {t('Get QR Code')}*/}
              {/*      </Button>*/}
              {/*    </Stack>*/}
              {/*)}*/}
            </Stack>
            <TabPanel sx={{ px: 0, py: 2 }} value="1">
              <ProfileTab user={user} />
            </TabPanel>

            <TabPanel sx={{ px: 0, py: 2 }} value="3">
              <WalletsTab open={open} handleCloseModal={handleCloseModal} />
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 2 }} value="4">
              <CompanyTab />
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 2 }} value="5">
              <TeamTab />
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 2 }} value="2">
              <InvoiceSettings />
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 2 }} value="6">
              {/*<QRCodeModal isOpen={qrCodeModalOpen} handleClose={handleCloseQrCodeModal} qrCode={qrCode} schemaType={schemaType}/>*/}
              <PolygonID identifier={user?.identifier} user={user} />
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 2 }} value="7">
              <SupportTab />
            </TabPanel>
          </TabContext>
        </Stack>
      </PageLayout>
    </Page>
  );
};
