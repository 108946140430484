import React from 'react';
import { Stack, Typography } from '@mui/material';
import { EditButton } from '../../../components/EditButton';

export const SectionLeft = ({ titleKey, buttonHandler, isEdit, children }) => {
  return (
    <Stack p={{ xs: 1, md: 3 }} spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">{titleKey}</Typography>
        {buttonHandler && !isEdit && <EditButton onClick={buttonHandler} />}
      </Stack>
      {children}
    </Stack>
  );
};
