import { ConnectionType } from '../connection/index';
import { WalletAbstract } from './wallet-abstract';
import { RPC_URLS } from '../constants/networks';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
// import UniversalProvider from '@walletconnect/universal-provider';
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from '../constants/chains';
import { Web3Provider } from '@ethersproject/providers';
import store from '../redux/store';
import { updateWalletState } from 'src/redux/wallet/actions';
import { WalletFactory } from './wallet-factory.js';
import { hexValue } from '@ethersproject/bytes';
import { getChainInfo } from '../constants/chainInfo';

const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
  (map, [chainId, urls]) => ({
    ...map,
    [chainId]: urls[0],
  }),
  {},
);

export class WalletConnectorV2 extends WalletAbstract {
  constructor() {
    super();
    this.type = ConnectionType.WALLET_CONNECT;

    this.initializeWalletConnect();
  }

  async initializeWalletConnect() {
    const chainIds = [1];

    const allChainIds = [
      1, 42161, 10, 137, 42220, 56, 97, 43114, 71402, 250, 100, 1284, 42262, 23294, 1313161554, 122,
      324, 1666600000, 1285, 128, 66, 40, 7001, 534353, 25, 338, 2046399126, 476158412, 4689, 4690,
    ];
    // const allChainIds = Object.values(SupportedChainId).filter(
    //   (item: string | number) => typeof item === 'number',
    // );

    this.connector = await EthereumProvider.init({
      projectId: '61b60124b9f6e9222bb98dd3ccd10888', // REQUIRED your projectId
      chains: chainIds, // REQUIRED chain ids
      // chains: chainIds, // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
      optionalChains: allChainIds,
      rpcMap: RPC_URLS_WITHOUT_FALLBACKS,
      events: [
        'accountsChanged',
        'chainChanged',
        'connect',
        'session_event',
        'display_uri',
        'disconnect',
      ],
      methods: [
        // 'personal_sign',
        'eth_sendTransaction',
        'eth_accounts',
        'eth_requestAccounts',
        'eth_call',
        'eth_getBalance',
        // 'eth_sendRawTransaction',
        // 'eth_sign',
        // 'eth_signTransaction',
        // 'eth_signTypedData',
        // 'eth_signTypedData_v3',
        // 'eth_signTypedData_v4',
        'wallet_switchEthereumChain',
        'wallet_addEthereumChain',
        // 'wallet_getPermissions',
        // 'wallet_requestPermissions',
        // 'wallet_registerOnboarding',
        // 'wallet_watchAsset',
        // 'wallet_scanQRCode',
      ],
      // optionalMethods: [
      //   'personal_sign',
      //   'eth_sendTransaction',
      //   'eth_accounts',
      //   'eth_requestAccounts',
      //   'eth_call',
      //   'eth_getBalance',
      //   'eth_sendRawTransaction',
      //   'eth_sign',
      //   'eth_signTransaction',
      //   'eth_signTypedData',
      //   'eth_signTypedData_v3',
      //   'eth_signTypedData_v4',
      //   'wallet_switchEthereumChain',
      //   'wallet_addEthereumChain',
      //   'wallet_getPermissions',
      //   'wallet_requestPermissions',
      //   'wallet_registerOnboarding',
      //   'wallet_watchAsset',
      //   'wallet_scanQRCode',
      // ],
    });
  }

  checkConnection = async () => {
    const walletConnectStorage = localStorage.getItem('wc@2:client:0.3//session');
    if (!walletConnectStorage || (walletConnectStorage && walletConnectStorage === 'null'))
      return this;
    await this.connect();
    return this;
  };

  connect = async () => {
    try {
      await this.initializeWalletConnect();

      const accounts = await this.connector.enable();

      const provider = this.getProvider();

      console.log('walletconnect provider', provider);

      console.log('walletconnect this.connector', this.connector);

      console.log('this.connector.chainId BEFORE', this.connector.chainId);

      let currentChainId = this.connector.chainId;

      console.log('currentChainId', currentChainId);

      if (provider) {
        console.log('walletconnect provider TRUE', provider);
        console.log(
          'store.getState().walletReducer.walletAddress',
          store.getState().walletReducer.walletAddress,
        );
        console.log(
          'accounts && accounts.length === 1 && !store.getState().walletReducer.walletAddress',
          accounts && accounts.length === 1 && !store.getState().walletReducer.walletAddress,
        );

        console.log('provider.accounts[0]', accounts[0]);
        console.log('this.connector.chainId', this.connector.chainId);
        console.log('currentChainId AFTRER', currentChainId);
        if (accounts && accounts.length === 1 && !store.getState().walletReducer.walletAddress) {
          this.address = accounts[0];

          store.dispatch(
            updateWalletState({
              walletAddress: this.address,
              walletType: this.type,
              isEvm: true,
              provider,
              connector: this.connector,
              chainId: currentChainId,
              // chainId: this.connector.chainId,
            }),
          );
        } else {
          store.dispatch(
            updateWalletState({
              walletType: this.type,
              isEvm: true,
              provider,
              connector: this.connector,
              chainId: currentChainId,
              // chainId: this.connector.chainId,
            }),
          );
        }
      }
    } catch (error) {
      console.log('WALLET CONNECT V2 CONNECT ERROR', error);
    }

    return this;
  };

  getProvider = () => {
    return new Web3Provider(this.connector);
  };

  getChainId = async () => {
    try {
      // console.log('this.connector', this.connector);
      return this.connector.chainId;
    } catch (error) {
      console.log('getChainId error', error);
      return 1;
    }
  };

  getBalance = async () => {
    try {
      const provider = this.getProvider();
      if (provider) {
        const address = this.address;
        const balance = await provider.getBalance(address);

        return balance.toString();
      } else {
        return 0;
      }
    } catch (err) {
      console.error('Failed to retrieve balance:', err);
    }
  };

  switchNetwork = async (chainId) => {
    if (
      chainId === SupportedChainId.TRON_NILE_TESTNET ||
      chainId === SupportedChainId.TRON_GRID_MAINNET
    ) {
      if (window.tronWeb && window.tronLink.ready) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.TRONLINK);
      } else if (window.tronWeb && !window.tronLink.ready) {
        alert('Connect Tronlink wallet');
      } else {
        alert('Download Tronlink wallet chrome extension');
      }
      return;
    }
    const hexChainId = hexValue(chainId);

    const allChainIds = [
      1, 42161, 10, 137, 42220, 56, 97, 43114, 71402, 250, 100, 1284, 42262, 23294, 1313161554, 122,
      324, 1666600000, 1285, 128, 66, 40, 7001, 534353, 25, 338, 2046399126, 476158412, 4689, 4690,
    ];

    const currentProvider = await EthereumProvider.init({
      projectId: '61b60124b9f6e9222bb98dd3ccd10888', // REQUIRED your projectId
      chains: [1], // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
      optionalChains: allChainIds,
      rpcMap: RPC_URLS_WITHOUT_FALLBACKS,
      methods: [
        'personal_sign',
        'eth_sendTransaction',
        'eth_accounts',
        'eth_requestAccounts',
        'eth_call',
        'eth_getBalance',
        'eth_sendRawTransaction',
        'eth_sign',
        'eth_signTransaction',
        'eth_signTypedData',
        'eth_signTypedData_v3',
        'eth_signTypedData_v4',
        'wallet_switchEthereumChain',
        'wallet_addEthereumChain',
        'wallet_getPermissions',
        'wallet_requestPermissions',
        'wallet_registerOnboarding',
        'wallet_watchAsset',
        'wallet_scanQRCode',
      ],
      optionalMethods: [
        'personal_sign',
        'eth_sendTransaction',
        'eth_accounts',
        'eth_requestAccounts',
        'eth_call',
        'eth_getBalance',
        'eth_sendRawTransaction',
        'eth_sign',
        'eth_signTransaction',
        'eth_signTypedData',
        'eth_signTypedData_v3',
        'eth_signTypedData_v4',
        'wallet_switchEthereumChain',
        'wallet_addEthereumChain',
        'wallet_getPermissions',
        'wallet_requestPermissions',
        'wallet_registerOnboarding',
        'wallet_watchAsset',
        'wallet_scanQRCode',
      ],
    });

    try {
      await currentProvider.enable();
    } catch (error) {
      console.log('currentProvider AFTER  ERROR', error);
    }

    try {
      await currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
    } catch (switchError) {
      console.log('switchError', switchError);

      if (switchError.code === 4902 || switchError.code === -32603) {
        try {
          await currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: hexChainId,
                chainName: CHAIN_IDS_TO_NAMES[chainId],
                nativeCurrency: getChainInfo(chainId).nativeCurrency,
                rpcUrls: RPC_URLS[chainId],
                blockExplorerUrls: [getChainInfo(chainId).explorer],
              },
            ],
          });
        } catch (addError) {
          console.log('addError', addError);
        }
      }
    }
  };
}
