// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastBody {
  background-color: #00cfe8 !important;
}

.toastProgress {
  background-color: #00cfe8 !important;
}

.Toastify__toast-container {
  z-index: 10000;
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 10px !important;
  background: #f0f9fa !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/notification/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oCAAoC;EACpC,8BAA8B;EAC9B,8BAA8B;AAChC","sourcesContent":[".toastBody {\n  background-color: #00cfe8 !important;\n}\n\n.toastProgress {\n  background-color: #00cfe8 !important;\n}\n\n.Toastify__toast-container {\n  z-index: 10000;\n}\n\n.Toastify__toast--success {\n  border: 1px solid #3a9ea5 !important;\n  border-radius: 10px !important;\n  background: #f0f9fa !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
