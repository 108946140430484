export const initialState = {
  walletAddress: '',
  walletType: '',
  isEvm: null,
  provider: null,
  connector: null,
  chainId: null,
};

export const walletReducer = (state = initialState, action) => {
  //   const { walletAddress, walletType, isEvm, provider, connector, chainId } = action.payload;

  switch (action.type) {
    case 'SET_WALLET_DATA':
      if (
        (action.payload.walletAddress && state.walletAddress !== action.payload.walletAddress) ||
        action.payload.walletAddress === ''
      ) {
        state.walletAddress = action.payload.walletAddress;
      }
      state.walletType = action.payload.walletType;
      state.isEvm = action.payload.isEvm;
      if (action.payload.provider) {
        state.provider = action.payload.provider;
      }
      if (action.payload.connector) {
        state.connector = action.payload.connector;
      }
      if (action.payload.chainId) {
        state.chainId = action.payload.chainId;
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
