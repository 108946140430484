/* eslint-disable */

import { filter } from 'lodash';

export const sortStringValuesTwoWays = (array, direction) => {
  if (direction === 'az') {
    return array.sort(function (a, b) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a === b) return 0;
      return a < b ? -1 : 1;
    });
  }
  if (direction === 'za') {
    return array.sort(function (a, b) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a === b) return 0;
      return a > b ? -1 : 1;
    });
  }
};

export const filterRequests = (USERLIST, filtering) => {
  return Object.values(filtering).includes(true)
    ? USERLIST.filter((request) => filtering[request.requestName])
    : USERLIST;
};

export const convertToLowerCase = (string) => string.toLowerCase();

export const convertToFloat = (amount) =>
  Number.isInteger(Number(amount)) ? Number(amount).toFixed(2) : amount;

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
