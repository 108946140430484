import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import {
  getOrganisationByIdRequest,
  getOrganisationByIdSuccess,
  getOrganisationEditRequest,
  getOrganisationEditSuccess,
  getOrganisationListRequest,
  getOrganisationListSuccess,
  setIsLoading,
} from './actions';
import { toast } from 'react-toastify';

import {
  addOrganisation,
  addWallet,
  getOrganisationById,
  getOrganisationEdit,
  getOrganisationList,
  sendMessage,
} from '../../../services/banking';
import { sagaWalletListWorker } from '../wallets/saga';
import { sagaPaymentBalanceWorker } from '../payments/saga';

export function* sagaOrganisationsListWorker() {
  try {
    yield put(getOrganisationListRequest(true));
    const responseOrganisationList = yield call(getOrganisationList);
    yield put(getOrganisationListSuccess(responseOrganisationList.data, false));
    yield call(sagaPaymentBalanceWorker, { id: responseOrganisationList.data[0].wallet_id });
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* sagaAddOrganisationsListWorker({ payload }) {
  try {
    const newOrganisation = yield call(addOrganisation, payload);
    if (newOrganisation.status === 201) {
      yield call(addWallet, {
        description: 'Some description',
        name: `${payload.name} Wallet`,
        wallet_id: payload.wallet_id,
        organization_id: newOrganisation.data.identifiers[0].id,
      });
      yield call(sagaWalletListWorker);
      yield call(sagaOrganisationsListWorker);
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* sagaOrganisationByIdWorker({ payload }) {
  try {
    yield put(getOrganisationByIdRequest(true));
    const responseOrganisation = yield call(getOrganisationById, payload);
    yield put(getOrganisationByIdSuccess(responseOrganisation.data, false));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
  }
}

export function* sagaSendMessageWorker({ payload, resetForm, initialValues }) {
  try {
    yield put(setIsLoading(true));
    const responseOrganisation = yield call(sendMessage, payload);
    if (responseOrganisation.status === 200) {
      toast.success('Thank you! Your letter has been sent!');
      resetForm({
        values: initialValues,
      });
      yield put(setIsLoading(false));
    }
  } catch (e) {
    toast.error(e.response.data.message);
    yield put(setIsLoading(false));
  }
}
export function* sagaEditOrganisationWorker({ payload }) {
  try {
    yield put(getOrganisationEditRequest(true));
    const responseOrganisation = yield call(getOrganisationEdit, payload);
    yield put(getOrganisationEditSuccess(responseOrganisation.data, false));
    yield call(sagaOrganisationByIdWorker, { payload: payload.organization_id });

    toast.success('Data updated successful');
  } catch (e) {
    const errorMessage =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : e.message;
    toast.error(errorMessage);
  }
}

export function* sagaOrganisationByIdWatcher() {
  yield takeEvery('GET_ORGANISATION_BY_ID', sagaOrganisationByIdWorker);
}

export function* sagaSendMessageWatcher() {
  yield takeEvery('SEND_MESSAGE', sagaSendMessageWorker);
}

export function* sagaOrganisationsListWatcher() {
  yield takeEvery('GET_ORGANISATIONS_LIST', sagaOrganisationsListWorker);
}

export function* sagaAddOrganisationsListWatcher() {
  yield takeEvery('ADD_ORGANISATION_SAGA', sagaAddOrganisationsListWorker);
}
export function* sagaEditOrganisationWatcher() {
  yield takeEvery('EDIT_ORGANISATION', sagaEditOrganisationWorker);
}

export default function* rootSaga() {
  yield all([
    fork(sagaOrganisationsListWatcher),
    fork(sagaAddOrganisationsListWatcher),
    fork(sagaOrganisationByIdWatcher),
    fork(sagaSendMessageWatcher),
    fork(sagaEditOrganisationWatcher),
  ]);
}
