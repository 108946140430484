import React from 'react';
import { Box } from '@mui/material';

function ContentWrapper({ children }) {
  return (
    <Box
      sx={{
        p: { xs: 1, md: 3 },
        width: '100%',
        boxShadow:
          'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
      }}
    >
      {children}
    </Box>
  );
}

export default ContentWrapper;
