import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Stack, Grid, Box, Typography, Alert, AlertTitle, TextField, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import ProfileLeftSide from './ProfileLeftSide';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { tokenSymbol } from 'src/utils';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ChangePassFormProfile } from './ChangePassFormProfile';

const BlockWrapper = styled(Box)({
  boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
});

const CustomDivider = styled(Divider)({
  margin: '10px 0',
});

export default function ProfileTab({ user, params }) {
  const { t } = useTranslation('common');
  const { chainId, account } = useWeb3React();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const accountChangeHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getUserBalance(newAccount.toString());
  };

  const getUserBalance = (address) => {
    window.ethereum
      ?.request({ method: 'eth_getBalance', params: [address, 'latest'] })
      .then((balance) => {
        setUserBalance(ethers.utils.formatEther(balance));
      });
  };

  const initialValues = React.useMemo(
    () => ({
      name: user?.name,
      second_name: user?.second_name,
      email: user?.email,
      phone: user?.phone ? user?.phone : '+380',
      avatar_url: user?.avatar_url,
    }),
    [user],
  );

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    values,
    errors,
    touched,
    getFieldProps,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(15, 'Maximum length 15 characters').required('Is required'),
      second_name: Yup.string().max(15, 'Maximum length 15 characters').required('Is required'),
      phone: Yup.string().test(
        'isValid',
        'Phone is not valid',
        (val) => val && (val === '+' || val === '+380' || isValidPhoneNumber(val)),
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append('avatar', values.avatar ? values.avatar[0] : null);
      formData.append('name', values.name);
      formData.append('second_name', values.second_name);
      formData.append(
        'phone',
        values.phone === '+' || values.phone === '+380' || values.phone === '' ? '' : values.phone,
      );
      !values.avatar && formData.append('avatar_url', null);

      dispatch({
        type: 'EDIT_USER_PROFILE',
        payload: formData,
        navigate,
        flag: 'edit',
      });
      resetForm({
        values: {
          ...values,
          phone: values.phone === '+' || values.phone === '+380' ? '' : values.phone,
        },
      });
    },
  });

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum?.request({ method: 'eth_requestAccounts' }).then((result) => {
        accountChangeHandler(result[0]);
      });
    }
  };

  // useEffect(async () => {
  //   await connectWalletHandler();
  //   await getUserBalance(defaultAccount);
  // }, []);

  // useEffect(async () => {
  //   await getUserBalance(defaultAccount);
  // }, [userBalance]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                p: { xs: 1, md: 3 },
                width: '100%',
                boxShadow:
                  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
              }}
              noValidate
              autoComplete="off"
            >
              <Stack direction="row" gap="20px">
                <Stack width="100%" gap="16px">
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Surname"
                      {...getFieldProps('second_name')}
                      error={Boolean(touched.second_name && errors.second_name)}
                      helperText={touched.second_name && errors.second_name}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Email"
                      disabled
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <MuiPhoneNumber
                      fullWidth
                      required
                      size="small"
                      variant="outlined"
                      label="Phone Number"
                      data-cy="user-phone"
                      defaultCountry="ua"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange('phone')}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack mt={2} spacing={2}>
                <Stack direction="row" gap={2}>
                  <Button type="submit" disabled={!dirty || !isValid} variant="contained">
                    {t('Save')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              p: { xs: 1, md: 3 },
              width: '100%',
              boxShadow:
                'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            }}
          >
            <ChangePassFormProfile />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
