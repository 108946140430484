import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OrganizationInfo = ({ organization }) => {
  const { t } = useTranslation();
  const company_name = organization?.name || organization?.company_name;

  const OrganizationInfoItem = ({ label, value }) =>
    value ? (
      <Stack>
        <Typography variant="body2">
          {t(label)}: {value}
        </Typography>
      </Stack>
    ) : null;

  return (
    <Stack spacing={1}>
      <OrganizationInfoItem label="Company" value={company_name} />
      <OrganizationInfoItem label="Address" value={organization?.address} />
      <OrganizationInfoItem label="Phone" value={organization?.phone} />
      <OrganizationInfoItem label="Tax number" value={organization?.tax_number} />
      <OrganizationInfoItem label="Registration number" value={organization?.registration_number} />
      <OrganizationInfoItem label="Contact person" value={organization?.contact_person} />
      <OrganizationInfoItem label="Email" value={organization?.email} />
    </Stack>
  );
};

OrganizationInfo.propTypes = {
  organization: PropTypes.shape({
    company_name: PropTypes.string,
    // address: PropTypes.string,
    // phone: PropTypes.string,
    // tax_number: PropTypes.string,
    // registration_number: PropTypes.string,
    // contact_person: PropTypes.string,
    // email: PropTypes.string,
  }).isRequired,
};

export default OrganizationInfo;
