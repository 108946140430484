export const getMetamaskProvider = () => {
  if (typeof window !== 'undefined' && window.ethereum && window.ethereum.providers) {
    return window.ethereum.providers.find((item) => item.isMetaMask);
  } else if (typeof window !== 'undefined' && window.ethereum && !window.ethereum.providers) {
    return window.ethereum;
  }
};

export const getAprosPetraProvider = () => {
  if (typeof window !== 'undefined' && window.aptos) {
    return window.aptos;
  } else {
    window.open('https://petra.app/', '_blank');
    return null;
  }
};
