import { CHAIN_IDS_TO_NAMES } from 'src/constants/chains';
import { DEFAULT_CHAIN_ID } from './connectors';
import { TOKENS } from './const';
import { BigNumber as BigNumberETH } from '@ethersproject/bignumber';
import BigNumber from 'bignumber.js';
import { parseUnits } from '@ethersproject/units';
import * as sapphire from '@oasisprotocol/sapphire-paratime';
import { getAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import { ConnectionType } from 'src/connection';
import { ZERO_ADDRESS } from '../constants/tokens';

export const DEFAULT_DECIMAL = 18;

export function isAddress(value) {
  try {
    // Alphabetical letters must be made lowercase for getAddress to work.
    // See documentation here: https://docs.ethers.io/v5/api/utils/address/
    return getAddress(value.toLowerCase());
  } catch {
    return false;
  }
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatAddress = (address) => {
  return address.length >= 10 ? `${address.slice(0, 5)}...${address.slice(-4)}` : address;
};

const SCAN_PREFIXES = {
  71402: 'https://v1.mainnet.godwoken.io/rpc',
  71401: 'https://godwoken-testnet-v1.ckbapp.dev',
  56: 'https://bsc-dataseed1.binance.org/',
  1: 'https://eth-mainnet.public.blastapi.io',
};

export function getScanLink(type, chainId, data) {
  const prefix = SCAN_PREFIXES[chainId] || SCAN_PREFIXES[1];
  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export const getAddressByChainId = (address, chainId) =>
  chainId && address[chainId] ? address[chainId] : address[DEFAULT_CHAIN_ID];

export const getTokensByChainId = (tokens, chainId) =>
  chainId && tokens[chainId] ? tokens[chainId] : tokens[DEFAULT_CHAIN_ID];

export const getChainNameById = (chainId) => CHAIN_IDS_TO_NAMES[chainId];

export const tokenSymbol = (networkId) => {
  return TOKENS[networkId];
};

export function getExponentValue(decimals) {
  return new BigNumber(10).pow(decimals);
}

export function getHumanValue(value, decimals = DEFAULT_DECIMAL) {
  return new BigNumber(value).div(
    getExponentValue(decimals && typeof decimals === 'number' ? decimals : DEFAULT_DECIMAL),
  );
}

export function getNonHumanValue(value, decimals) {
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return parseUnits(
    value.toString(),
    decimals && typeof decimals === 'number' ? decimals : DEFAULT_DECIMAL,
  );
}

export function getNonHumanValueSumm(amounts) {
  return amounts.reduce((acc, amount) => {
    return BigNumberETH.from(acc).add(BigNumberETH.from(amount));
  }, BigNumberETH.from(0));
}

const parseMetamaskError = (err) => {
  const parsedErrorObject = JSON.parse(JSON.stringify(err));
  return {
    code: parsedErrorObject.code,
    message: err.reason,
  };
};

export const buildQueryTron = async (
  method,
  // method: ContractFunctionTron,
  args = [],
  isSend = false,
  options = {},
) => {
  let result;
  try {
    if (isSend) {
      console.log('method', method);
      console.log('args', args);
      console.log('options', options);

      result = await method(...args).send({
        shouldPollResponse: true,
        ...options,
      });
    } else {
      if (
        method &&
        method.call &&
        args.length &&
        !args.includes(ZERO_ADDRESS) &&
        args.every((arg) => !isAddress(arg))
      ) {
        result = await method(...args).call(options);
      } else {
        return {};
      }
    }
  } catch (err) {
    console.log('buildQueryTron Error', err);

    return parseMetamaskError(err);
  }

  return result;
};

// export const buildQuery = async (method, args, estimateGas = false, options) => {
//   let tx;
//   try {
//     if (estimateGas) {
//       const gasLimit = await estimateGas(...args, options);
//       tx = await method(...args, {
//         gasLimit: calculateGasMargin(gasLimit),
//         ...options,
//       });
//     } else {
//       tx = await method(...args, options);
//     }
//     if (tx?.hash) {
//       localStorage.transactionHash = `${tx.hash}`;
//     }
//     if (tx?.wait) {
//       await tx.wait();
//       localStorage.transactionHash = '';
//     }
//   } catch (err) {
//     console.log('buildQuery Error', err);
//     console.error(`buildQuery failed with args: ${args}`);
//     return parseMetamaskError(err);
//   }

//   return tx;
// };

export const buildQuery = async (method, args = [], estimateGas, options = {}) => {
  let tx;
  try {
    if (estimateGas) {
      const gasLimit = await estimateGas(...args, options);
      tx = await method(...args, {
        gasLimit: calculateGasMargin(gasLimit),
        ...options,
      });
    } else {
      tx = await method(...args, options);
    }
    if (tx?.wait) {
      await tx.wait();
    }
  } catch (err) {
    console.log('buildQuery Error', err);
    console.error(`buildQuery failed with args: ${args}`);
    return parseMetamaskError(err);
  }

  return tx;
};

export const getSigner = (provider, account) => provider.getSigner();

export const getSignerSapphire = (provider, account) => sapphire.wrap(provider.getSigner());
// export const getSigner = (provider: Web3Provider, account: string): JsonRpcSigner =>
//   provider.getSigner(account).connectUnchecked();

// account is optional
export const getProviderOrSigner = (provider, account) =>
  account ? getSigner(provider, account) : provider;

// account is optional
export const getContract = (address, ABI, provider, account) => {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, provider && getProviderOrSigner(provider, account));
};

export const getContractSapphire = (address, ABI, provider, account) => {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, provider && sapphire.wrap(provider));
};

export const getContractSapphireSigned = (address, ABI, provider, account) => {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, provider && getSignerSapphire(provider, account));
};

export const getSignContract = (address, ABI, provider, account) => {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, provider && account && getSigner(provider, account));
};

export const getSignContractSapphire = (address, ABI, provider, account) => {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, provider && account && getSignerSapphire(provider, account));
};

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// add 25%
export const calculateGasMargin = (value) =>
  value.mul(BigNumberETH.from(10000).add(BigNumberETH.from(2500))).div(BigNumberETH.from(10000));

// add 0.1%
export const calculateCommissionFee = (value, percent = 10) => {
  let val;
  if (typeof value === 'string') {
    val = BigNumberETH.from(value);
  } else {
    val = value;
  }

  return val
    .mul(BigNumberETH.from(10000).add(BigNumberETH.from(percent)))
    .div(BigNumberETH.from(10000));
};

export const getCommissionFee = (value, percent = 10) => {
  let val;
  if (typeof value === 'string') {
    val = BigNumberETH.from(value);
  } else {
    val = value;
  }
  return val.mul(BigNumberETH.from(percent)).div(BigNumberETH.from(10000));
};

export const subBigNumber = (value, value2) => value.sub(value2);

export const calculateDecimalsPlaces = (value, decimals) => {
  let decimalPart = value.split('.')[1];
  let decimalPlaces = decimalPart ? decimalPart.length : 0;
  return decimalPlaces > decimals;
};

export const isCurrentNetworkAddress = (address, isEvm, walletType, provider) => {
  if (isEvm) {
    return isAddress(address);
  } else {
    if (!isEvm && walletType === ConnectionType.TRONLINK) {
      return provider.isAddress(address);
    } else if (!isEvm && walletType === ConnectionType.APTOS_PETRA) {
      return true;
    } else {
      return false;
    }
  }
};
