import React from "react";
import PageWrapper from "../../components/PageWrapper";
import WorkingMessage from "../../components/WorkingMessage";

export const Statistic = () => {
  return (
    <PageWrapper title="Dashboard">
          <WorkingMessage />
      </PageWrapper>
  );
};
