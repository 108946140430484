import React, { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useField } from 'formik';

export const CustomFormControl = ({
  label,
  name,
  options,
  required = false,
  additionalCustomButtonLabel,
  additionalCustomButtonHandler,
  onChange,
}) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      helpers.setValue(newValue);
      if (onChange) onChange(newValue);
    },
    [helpers, onChange],
  );

  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <FormControl size="small" fullWidth error={!!errorText}>
      <InputLabel id={label} required={required} size="small">
        {label}
      </InputLabel>
      <Select
        labelId={label}
        size="small"
        required={required}
        label={label}
        {...field}
        onChange={handleChange}
      >
        {additionalCustomButtonLabel && additionalCustomButtonHandler && (
          <Button
            sx={{ width: '95%', marginLeft: '10px', my: 1 }}
            variant="contained"
            color="primary"
            margin="normal"
            type="button"
            onClick={additionalCustomButtonHandler}
          >
            {additionalCustomButtonLabel}
          </Button>
        )}
        {options.map(({ key, value, display }) => (
          <MenuItem
            sx={{
              color: '#000',
            }}
            key={key}
            value={value}
          >
            <Typography variant="body2"> {display}</Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
