import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { connect, useDispatch } from 'react-redux';
// material
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
// component
import { loginUser } from '../../../redux/actions';
import { HidePassIcon } from 'src/assets/sign-in/hide-pass';
import { ViewHidePassIcon } from 'src/assets/sign-in/view-hide';

// ----------------------------------------------------------------------

const inputStyles = {
  label: {
    color: 'rgba(255, 255, 255, 0.49)',
    fontSize: '12px !important',
    fontWeight: 400,
    fontFamily: 'MullerRegular',

    '&.Mui-focused': { color: 'white' },
  },
  input: {
    color: 'white',
    fontSize: '12px !important',
    fontFamily: 'MullerRegular',
  },
};

function ChangePasswordForm({ loginUser }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const passwordExp = '[a-zA-Z0-9]+';
  const dispatch = useDispatch();
  const params = useParams();

  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowSecondPassword = () => {
    setShowSecondPassword((show) => !show);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimum password length 8 characters')
      .matches(passwordExp)
      .required('Is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords are not the same')
      .matches(passwordExp)
      .min(8, 'Minimum password length 8 characters')
      .required('Is required'),
  });

  const formik = useFormik({
    initialValues: {
      confirm_password: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: 'RESTORE_PASSWORD',
        payload: { password: values.password, restorePassKey: params.token },
        navigate,
      });
    },
  });

  const { errors, touched, values, isValid, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2} mb={3}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            variant="standard"
            sx={inputStyles}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <ViewHidePassIcon /> : <HidePassIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type={showSecondPassword ? 'text' : 'password'}
            label="Confirm Password"
            variant="standard"
            sx={inputStyles}
            {...getFieldProps('confirm_password')}
            error={touched.confirm_password && !!errors.confirm_password}
            helperText={touched.confirm_password && errors.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowSecondPassword}>
                    {showSecondPassword ? <ViewHidePassIcon /> : <HidePassIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Button
          type="submit"
          fullWidth
          disabled={!isValid}
          sx={{
            height: '26px',
            border: '0.729947px solid #FFFFFF',
            borderRadius: ' 37.2273px',
            background: 'transparent',
            padding: '6.5px 33.8476px',
            fontSize: '10px !important',
            fontFamily: 'MullerRegular',
          }}
        >
          Change password
        </Button>
      </Form>
    </FormikProvider>
  );
}

const mapStateToProps = (state) => ({ state });
const mapActionsToProps = { loginUser };

export default connect(mapStateToProps, mapActionsToProps)(ChangePasswordForm);
