import React from 'react';

export const HidePassIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0064 12.1593C15.104 11.83 15.1564 11.4814 15.1564 11.1204C15.1564 9.10474 13.5224 7.4707 11.5067 7.4707C11.1457 7.4707 10.7971 7.52309 10.4678 7.62068L15.0064 12.1593ZM8.62746 8.87724C8.14461 9.49611 7.85693 10.2747 7.85693 11.1204C7.85693 13.1361 9.49097 14.7702 11.5067 14.7702C12.3524 14.7702 13.131 14.4825 13.7499 13.9996L8.62746 8.87724Z"
        fill="#01CFD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9919 14.1452C17.9214 13.3357 18.6594 12.4923 19.1319 11.9024C19.4126 11.5519 19.5529 11.3766 19.5529 11.1204C19.5529 10.8641 19.4126 10.6888 19.1319 10.3383C17.9203 8.82552 14.9626 5.64575 11.5062 5.64575C10.6385 5.64575 9.80215 5.84616 9.01702 6.17029L16.9919 14.1452ZM7.03829 7.28846C5.6267 8.29854 4.51453 9.54669 3.88052 10.3383C3.59982 10.6888 3.45947 10.8641 3.45947 11.1204C3.45947 11.3766 3.59982 11.5519 3.88052 11.9024
  C5.09208 13.4152 8.04976 16.595 11.5062 16.595C12.8374 16.595 14.0947 16.1233 15.2067 15.4568L7.03829 7.28846Z"
        fill="#0D7D87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0064 12.1593C15.104 11.83 15.1564 11.4814 15.1564 11.1204C15.1564 9.10474 13.5224 7.4707 11.5067 7.4707C11.1457 7.4707 10.7971 7.52309 10.4678 7.62068L15.0064 12.1593ZM8.62746 8.87724C8.14461 9.49611 7.85693 10.2747 7.85693 11.1204C7.85693 13.1361 9.49097 14.7702 11.5067 14.7702C12.3524 14.7702 13.131 14.4825 13.7499 13.9996L8.62746 8.87724Z"
        fill="#01CFD4"
      />
      <path d="M7.85645 4.7334L18.8056 15.6826" stroke="#01CFD4" strokeWidth="0.875936" />
    </svg>
  );
};
