// import { getContract, getSignContract, getUnsignContract } from '../utils/contracts';
// import MULTI_SEND_ABI from '../web3/abi/MultiSend.json';
// import { useWeb3React } from '@web3-react/core';
// import { REACT_APP_DEFAULT_CHAIN_ID } from 'src/utils/connectors';
// import { CONTRACTS } from 'src/utils/const';
// import { getAddressByChainId } from 'src/utils/index';
// import { useWeb3 } from './useWeb3';
// import ERC20_ABI from '@/web3/abi/ERC20.json';
// import { ZERO_ADDRESS } from 'src/constants/tokens';
// import { SupportedChainId } from 'src/constants/chains';

// function useContractByChainId(address, ABI, withSignerIfPossible = true) {
//   const { library, account, chainId } = useWeb3React();
//   return getContract(
//     getAddressByChainId(address, chainId || Number(REACT_APP_DEFAULT_CHAIN_ID)),
//     ABI,
//     library,
//     withSignerIfPossible && account ? account : undefined,
//   );
// }

// function useContract(address, ABI, withSignerIfPossible = true) {
//   const { library, account } = useWeb3React();
//   return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
// }

// function useSignContract(address, ABI, withSignerIfPossible = true) {
//   const { library, account } = useWeb3React();
//   return getSignContract(
//     address,
//     ABI,
//     library,
//     withSignerIfPossible && account ? account : undefined,
//   );
// }

// function useUnsignContract(address, ABI) {
//   const { library } = useWeb3React();
//   return getUnsignContract(address, ABI, library);
// }

// export const usePoapLinksSignContract = () => {
//   const { chainId } = useWeb3React();
//   return useSignContract(
//     getAddressByChainId(CONTRACTS, chainId || Number(REACT_APP_DEFAULT_CHAIN_ID)),
//     MULTI_SEND_ABI,
//     true,
//   );
// };

// export const usePoapLinksUnsignContract = (withSignerIfPossible = false) => {
//   const { chainId } = useWeb3React();
//   return useUnsignContract(
//     getAddressByChainId(CONTRACTS, chainId || Number(REACT_APP_DEFAULT_CHAIN_ID)),
//     MULTI_SEND_ABI,
//     withSignerIfPossible,
//   );
// };

// function useContractSapphire(address, ABI, withSignerIfPossible = true) {
//   const { walletAddress: account, provider } = useWeb3();
//   return getContractSapphire(
//     address,
//     ABI,
//     provider,
//     withSignerIfPossible && account ? account : undefined,
//   );
// }

// function useContractSapphireSigned(address, ABI, withSignerIfPossible = true) {
//   const { walletAddress: account, provider } = useWeb3();
//   return getContractSapphireSigned(
//     address,
//     ABI,
//     provider,
//     withSignerIfPossible && account ? account : undefined,
//   );
// }

// export const useTokenContract = (tokenAddress, withSignerIfPossible) => {
//   const { walletAddress: account, chainId, isEvm, provider } = useWeb3();
//   if (isEvm) {
//     if (chainId === SupportedChainId.OASIS_SAPPHIRE) {
//       return useContractSapphire(tokenAddress, ERC20_ABI, withSignerIfPossible);
//     } else {
//       return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
//     }
//   }
//   // else {
//   //   return getSignContract(
//   //     ZERO_ADDRESS,
//   //     ERC20_ABI,
//   //     new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
//   //     withSignerIfPossible && account ? account : undefined,
//   //   );
//   // }
// };

// export const useTokenContractTron = (tokenAddress, withSignerIfPossible) => {
//   const { walletAddress: account, chainId, isEvm, provider, walletType } = useWeb3();

//   if (
//     !isEvm &&
//     walletType === ConnectionType.TRONLINK &&
//     provider &&
//     typeof window !== 'undefined' &&
//     window.tronLink.ready &&
//     window.tronweb &&
//     provider.contract
//   ) {
//     console.log('provider useTokenContractTron', provider);

//     return provider.contract(ERC20_ABI, tokenAddress);
//   }
//   // else {
//   //   return getSignContract(
//   //     ZERO_ADDRESS,
//   //     ERC20_ABI,
//   //     new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
//   //     withSignerIfPossible && account ? account : undefined,
//   //   );
//   // }
// };

// // export default useContract;

import { SupportedEvmChainId } from './../constants/chains';
import { Contract } from '@ethersproject/contracts';

import ERC20_ABI from '../web3/abi/ERC20.json';
import MULTI_SEND_ABI from '../web3/abi/MultiSend.json';
import { DEFAULT_CHAIN_ID, SupportedChainId } from '../constants/chains';
import {
  getAddressByChainId,
  getContract,
  getContractSapphire,
  getContractSapphireSigned,
  getSignContract,
  getSignContractSapphire,
} from '../utils/index';
import {
  AddressMap,
  MULTI_CLAIM_CONTRACTS,
  MULTI_CLAIM_NFTS_CONTRACTS,
  MULTI_SEND_CONTRACTS,
  MULTI_SEND_NFTS_CONTRACTS,
  SUSHI_V2_FACTORY,
  SWAP_CONTRACTS,
  COIN_CRATER_FACTORY,
  REGISTRY_FACTORY_CONTRACTS,
} from '../constants/addresses';
import { useWeb3 } from './useWeb3';
import { AppJsonRpcProvider } from '../constants/providers';
import { ConnectionType } from '../connection/index';
import { Web3Provider } from '@ethersproject/providers';
import { WalletFactory } from '../wallets/wallet-factory';
import { COIN_SENDER_FACTORY_CONTRACTS } from '../constants/addresses';
import { ZERO_ADDRESS } from '../constants/tokens';
import { isAddress } from '@ethersproject/address';

function useContractByChainId(address, ABI, withSignerIfPossible = true) {
  const { walletAddress: account, isEvm, provider, chainId } = useWeb3();

  if (isEvm) {
    return getSignContract(
      getAddressByChainId(address, chainId || DEFAULT_CHAIN_ID),
      ABI,
      provider,
      withSignerIfPossible && account ? account : undefined,
    );
  } else {
    return getSignContract(
      getAddressByChainId(address, chainId || DEFAULT_CHAIN_ID),
      ABI,
      new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
      withSignerIfPossible && account ? account : undefined,
    );
  }
}

function getContractData(address, ABI, withSignerIfPossible = true, account, isEvm, provider) {
  // const { walletAddress: account, isEvm, provider } = useWeb3();

  if (isEvm && isAddress(address)) {
    return getContract(
      address,
      ABI,
      provider,
      withSignerIfPossible && account ? account : undefined,
    );
  } else {
    return getSignContract(
      ZERO_ADDRESS,
      ABI,
      new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
      withSignerIfPossible && account ? account : undefined,
    );
  }
}

function getContractSapphireData(address, ABI, withSignerIfPossible = true, account, provider) {
  // const { walletAddress: account, provider } = useWeb3();
  return getContractSapphire(
    address,
    ABI,
    provider,
    withSignerIfPossible && account ? account : undefined,
  );
}

function getContractSapphireSignedData(
  address,
  ABI,
  withSignerIfPossible = true,
  account,
  provider,
) {
  // const { walletAddress: account, provider } = useWeb3();
  return getContractSapphireSigned(
    address,
    ABI,
    provider,
    withSignerIfPossible && account ? account : undefined,
  );
}

// export const useTokenContract = (tokenAddress, withSignerIfPossible) => {
//   const { walletAddress: account, chainId, isEvm, provider } = useWeb3();

//   let contract;

//   if (isEvm) {
//     if (chainId === SupportedChainId.OASIS_SAPPHIRE) {
//       contract = useContractSapphire(tokenAddress, ERC20_ABI, withSignerIfPossible);
//     } else {
//       contract = useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
//     }
//   } else {
//     const mainnetProvider = new AppJsonRpcProvider(SupportedEvmChainId.MAINNET);
//     contract = getSignContract(
//       ZERO_ADDRESS,
//       ERC20_ABI,
//       mainnetProvider,
//       withSignerIfPossible && account ? account : undefined,
//     );
//   }

//   return contract;
// };

export const useTokenContract = (tokenAddress, withSignerIfPossible) => {
  const { walletAddress: account, chainId, isEvm, provider } = useWeb3();
  if (isEvm) {
    if (chainId === SupportedChainId.OASIS_SAPPHIRE) {
      return getContractSapphireData(
        tokenAddress,
        ERC20_ABI,
        withSignerIfPossible,
        account,
        provider,
      );
    } else {
      return getContractData(
        tokenAddress,
        ERC20_ABI,
        withSignerIfPossible,
        account,
        isEvm,
        provider,
      );
    }
  } else {
    return getSignContract(
      ZERO_ADDRESS,
      ERC20_ABI,
      new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
      withSignerIfPossible && account ? account : undefined,
    );
  }
};

export const useTokenContractTron = (tokenAddress, withSignerIfPossible) => {
  const { walletAddress: account, chainId, isEvm, provider, walletType, connector } = useWeb3();

  if (
    !isEvm &&
    walletType === ConnectionType.TRONLINK &&
    provider &&
    typeof window !== 'undefined' &&
    window.tronLink.ready &&
    // window.tronweb &&
    // provider.contract
    provider.toSun
  ) {
    console.log('useTokenContractTron connector', connector);
    console.log('provider useTokenContractTron', provider);

    return provider.contract(ERC20_ABI, tokenAddress);
  } else {
    return getSignContract(
      ZERO_ADDRESS,
      ERC20_ABI,
      new AppJsonRpcProvider(SupportedEvmChainId.MAINNET),
      withSignerIfPossible && account ? account : undefined,
    );
  }
};

export const useTokenContractSigned = (tokenAddress, withSignerIfPossible) => {
  const { chainId, walletAddress: account, isEvm, provider } = useWeb3();

  if (chainId === SupportedChainId.OASIS_SAPPHIRE) {
    return getContractSapphireSignedData(
      tokenAddress,
      ERC20_ABI,
      withSignerIfPossible,
      account,
      provider,
    );
  } else {
    return getContractData(tokenAddress, ERC20_ABI, withSignerIfPossible, account, isEvm, provider);
  }
};

export const useMultiSendContract = () =>
  useContractByChainId(MULTI_SEND_CONTRACTS, MULTI_SEND_ABI);
