import { WalletAbstract } from './wallet-abstract';
import store from '../redux/store';
import { updateWalletState } from 'src/redux/wallet/actions';
import { ConnectionType } from '../connection/index';
import { SupportedChainId } from '../constants/chains';
import { WalletFactory } from './wallet-factory.js';
import { getMetamaskProvider } from '../utils/providers';
import { toast } from 'react-toastify';

export class WalletTron extends WalletAbstract {
  constructor() {
    super();
    this.type = ConnectionType.TRONLINK;
  }

  checkConnection = async () => {
    await this.connectWallet();
    return this;
  };

  connect = async () => {
    await this.connectWallet();
    return this;
  };

  connectWallet = async () => {
    if (typeof window !== 'undefined' && window.tronWeb) {
      if (window.tronLink.ready) {
        try {
          const address = window.tronWeb.defaultAddress.base58;

          if (address) {
            this.address = address;

            let tronNetwrork = '';

            switch (window.tronWeb.solidityNode.host) {
              case 'https://api.trongrid.io':
                tronNetwrork = 'TRON_GRID_MAINNET';
                break;
              // case 'https://api.nileex.io':
              //   tronNetwrork = 'TRON_NILE_TESTNET';
              //   break;

              default:
                // const isToastActive = toast.isActive('Unsupported tron network');
                // if (!isToastActive) {
                //   toast.error('Unsupported tron network');
                // }
                tronNetwrork = '';
                break;
            }

            if (!store.getState().walletReducer.walletAddress) {
              store.dispatch(
                updateWalletState({
                  walletAddress: this.address,
                  walletType: this.type,
                  isEvm: false,
                  chainId: tronNetwrork,
                  provider: window.tronLink.tronWeb,
                }),
              );
            } else {
              store.dispatch(
                updateWalletState({
                  walletType: this.type,
                  isEvm: false,
                  chainId: tronNetwrork,
                  provider: window.tronLink.tronWeb,
                }),
              );
            }
          } else {
            throw new Error('No Tron address found');
          }
        } catch (err) {
          this.error = err.message;
          this.resetWallet();
        }
      } else {
        // alert('Connect Tronlink wallet');
        try {
          const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
          if (res.code === 200) {
            await this.connectWallet();
          }
        } catch (error) {
          console.log('error TRONLINK', error);
        }
      }
    } else {
      alert('Download Tronlink wallet chrome extension');

      this.error = 'Please install TronLink';
    }
  };

  getChainId = async () => {
    let tronNetwrork = '';

    switch (window.tronWeb.solidityNode.host) {
      case 'https://api.trongrid.io':
        tronNetwrork = 'TRON_GRID_MAINNET';
        break;
      case 'https://api.nileex.io':
        tronNetwrork = 'TRON_NILE_TESTNET';
        break;

      default:
        tronNetwrork = '';
        break;
    }

    return tronNetwrork;
  };

  getBalance = async () => {
    return await window.tronWeb.trx.getBalance(this.address);
  };

  getNetwork = (chainId) => {
    window.tronWeb.trx
      .getNetwork()
      .then((network) => {
        // console.log('Tron network:', network);

        // Check the network name
        if (network === 'nile') {
          console.log('Tron Nile network');
        } else if (network === 'grid') {
          console.log('Tron Grid network');
        } else {
          console.log('Unknown network');
        }
      })
      .catch((error) => {
        console.error('Failed to get Tron network:', error);
      });
  };

  getProvider = () => {
    if (typeof window !== 'undefined' && window.tronLink) {
      return window.tronLink.tronWeb;
    } else {
      throw new Error('TronLink not available');
    }
  };

  switchNetwork = async (chainId) => {
    if (chainId === SupportedChainId.APTOS_MAINNET) {
      if (window.aptos) {
        this.resetWallet();
        WalletFactory.getWallet(ConnectionType.APTOS_PETRA);
      } else {
        alert('Download Petra Wallet wallet chrome extension');
      }
      return;
    }
    if (
      chainId !== SupportedChainId.TRON_NILE_TESTNET &&
      chainId !== SupportedChainId.TRON_GRID_MAINNET
    ) {
      if (getMetamaskProvider()) {
        this.resetWallet();
        const wallet = WalletFactory.getWallet(ConnectionType.METAMASK);
        if (wallet && typeof chainId === 'number') {
          wallet.switchNetwork(chainId);
        }
      } else {
        alert('Download metamask wallet chrome extension');
      }
      return;
    }
    alert('Network switching is not supported for Tron');
    // throw new Error('Network switching is not supported for Tron');
  };
}
