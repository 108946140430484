import { Stack, Typography } from '@mui/material';
import PageWrapper from 'src/components/PageWrapper';
import WorkingMessage from 'src/components/WorkingMessage';

export const MyInvoices = () => {
  return (
    <PageWrapper title="My invoices">
        <WorkingMessage />
    </PageWrapper>
  );
};
