import React from 'react';

const PolygonIdLogo = ({ size = 20 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 44 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: '8px' }}
    >
        <path
            d="M32.9874 12.4144C32.1808 11.9197 31.1323 11.9197 30.2451 12.4144L23.9541 16.2071L19.6794 18.6807L13.3885 22.4736C12.5819 22.9683 11.5335 22.9683 10.6463 22.4736L5.64576 19.5053C4.83922 19.0106 4.27465 18.1036 4.27465 17.1141V11.26C4.27465 10.2706 4.75857 9.36364 5.64576 8.86892L10.5656 5.98308C11.3722 5.48837 12.4207 5.48837 13.3078 5.98308L18.2278 8.86892C19.0343 9.36364 19.5988 10.2706 19.5988 11.26V15.0528L23.8735 12.4968V8.70402C23.8735 7.71459 23.3896 6.80762 22.5024 6.3129L13.3885 0.871036C12.5819 0.376321 11.5335 0.376321 10.6463 0.871036L1.37111 6.3129C0.483922 6.80762 0 7.71459 0 8.70402V19.6702C0 20.6596 0.483922 21.5666 1.37111 22.0613L10.6463 27.5031C11.4528 27.9979 12.5013 27.9979 13.3885 27.5031L19.6794 23.7927L23.9541 21.2368L30.2451 17.5264C31.0517 17.0317 32.1001 17.0317 32.9874 17.5264L37.9072 20.4123C38.7137 20.907 39.2784 21.814 39.2784 22.8034V28.6575C39.2784 29.6469 38.7944 30.5539 37.9072 31.0486L32.9874 34.017C32.1808 34.5117 31.1323 34.5117 30.2451 34.017L25.3253 31.1311C24.5187 30.6364 23.9541 29.7294 23.9541 28.7399V24.9472L19.6794 27.5031V31.296C19.6794 32.2854 20.1635 33.1924 21.0506 33.687L30.3258 39.129C31.1323 39.6237 32.1808 39.6237 33.068 39.129L42.3431 33.687C43.1498 33.1924 43.7143 32.2854 43.7143 31.296V20.3298C43.7143 19.3404 43.2304 18.4334 42.3431 17.9387L32.9874 12.4144Z"
            fill="#7950DD"
        />
    </svg>
);

export default PolygonIdLogo;