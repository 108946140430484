/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_TEAM = 'LOGIN_USER_TEAM';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_POLYGON_ID = 'LOGIN_USER_POLYGON_ID';

export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_COMPANY = 'LOGIN_COMPANY';
export const LOGIN_COMPANY_SUCCESS = 'LOGIN_COMPANY_SUCCESS';

export const IS_AUTH = 'IS_AUTH';
export const IS_AUTH_SUCCESS = 'IS_AUTH_SUCCESS';
export const IS_AUTH_ERROR = 'IS_AUTH_ERROR';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_POLYGON_ID = 'REGISTER_USER_POLYGON_ID';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

/* User */
export const DELETE_USER_EVENT = 'DELETE_USER_EVENT';
export const DELETE_USER_EVENT_SUCCESS = 'DELETE_USER_EVENT_SUCCESS';
export const GET_EVENTS_LIST = 'GET_EVENTS_LIST';
export const GET_EVENTS_LIST_SUCCESS = 'GET_EVENTS_LIST_SUCCESS';
export const GET_BALANCE = 'GET_BALANCE';

// Invoices

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE_NUMBER = 'GET_INVOICE_NUMBER';
export const GET_INVOICE_NUMBER_SUCCESS = 'GET_INVOICE_NUMBER_SUCCESS';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GET_ADMIN_INVOICES_REQUEST = 'GET_ADMIN_INVOICES_REQUEST';
export const GET_ADMIN_INVOICES_SUCCESS = 'GET_ADMIN_INVOICES_SUCCESS';
export const INVOICES_EDIT_REQUEST = 'INVOICES_EDIT_REQUEST';
export const INVOICES_EDIT_SUCCESS = 'INVOICES_EDIT_SUCCESS';
export const INVOICES_EDIT_FAILURE = 'INVOICES_EDIT_FAILURE';
export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_DELETE_FAILURE = 'INVOICE_DELETE_FAILURE';

export const GET_GENERAL_WALLETS_REQUEST = 'GET_GENERAL_WALLETS_REQUEST';
export const GET_GENERAL_WALLETS_SUCCESS = 'GET_GENERAL_WALLETS_SUCCESS';

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE';

export const GET_QRCODE_LINK_REQUEST = 'GET_QRCODE_LINK_REQUEST';
export const GET_QRCODE_LINK_SUCCESS = 'GET_QRCODE_LINK_SUCCESS';
export const GET_QRCODE_LINK_FAILURE = 'GET_QRCODE_LINK_FAILURE';

export const GET_SIGN_IN_POLYGON_ID_REQUEST = 'GET_SIGN_IN_POLYGON_ID_REQUEST';
export const GET_SIGN_IN_POLYGON_ID_SUCCESS = 'GET_SIGN_IN_POLYGON_ID_SUCCESS';
export const GET_SIGN_IN_POLYGON_ID_FAILURE = 'GET_SIGN_IN_POLYGON_ID_FAILURE';
export const SET_AUTH_STATUS_POLYGON_ID = 'SET_AUTH_STATUS_POLYGON_ID';
export const GET_AUTH_STATUS_POLYGON_ID_REQUEST = 'GET_AUTH_STATUS_POLYGON_ID_REQUEST';

export const GET_REFERRAL_CODE_REQUEST = 'GET_REFERRAL_CODE_REQUEST';
export const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS';
export const GET_REFERRAL_CODE_FAILURE = 'GET_REFERRAL_CODE_FAILURE';
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';
export const SET_REFERRAL_PATH = 'SET_REFERRAL_PATH';
export const SEND_REFERRAL_CODE_REQUEST = 'SEND_REFERRAL_CODE_REQUEST';
export const SEND_REFERRAL_CODE_SUCCESS = 'SEND_REFERRAL_CODE_SUCCESS';
export const SEND_REFERRAL_CODE_FAILURE = 'SEND_REFERRAL_CODE_FAILURE';
export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILURE = 'GET_REFERRALS_FAILURE';
export const GET_REFERRAL_LEVELS_COUNT_REQUEST = 'GET_REFERRAL_LEVELS_COUNT_REQUEST';
export const GET_REFERRAL_LEVELS_COUNT_SUCCESS = 'GET_REFERRAL_LEVELS_COUNT_SUCCESS';
export const GET_REFERRAL_LEVELS_COUNT_FAILURE = 'GET_REFERRAL_LEVELS_COUNT_FAILURE';

export const DELETE_EMPLOYEE_BATH_SAGA_REQUEST = 'DELETE_EMPLOYEE_BATH_SAGA_REQUEST';
export const DELETE_EMPLOYEE_BATH_SAGA_SUCCESS = 'DELETE_EMPLOYEE_BATH_SAGA_SUCCESS';
export const DELETE_EMPLOYEE_BATH_FAILURE = 'DELETE_EMPLOYEE_BATH_FAILURE';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const EDIT_TEAM_REQUEST = 'EDIT_TEAM_REQUEST';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
