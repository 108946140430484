// reducer.js
import {
    GET_REFERRAL_CODE_REQUEST,
    GET_REFERRAL_CODE_SUCCESS,
    GET_REFERRAL_CODE_FAILURE,
    GET_REFERRALS_REQUEST,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILURE,
    SEND_REFERRAL_CODE_SUCCESS,
    SEND_REFERRAL_CODE_FAILURE,
    SET_REFERRAL_CODE,
    SET_REFERRAL_PATH,
    GET_REFERRAL_LEVELS_COUNT_REQUEST,
    GET_REFERRAL_LEVELS_COUNT_SUCCESS, GET_REFERRAL_LEVELS_COUNT_FAILURE
} from '../../constants/actions';

const initialState = {
    referralPathIds: null, // 1/2/3
    referralCode: null, // `referralCode`
    referrals: [],
    referralLevelsCount: { level1: 0, level2: 0, level3: 0 },
    loading: false,
    error: null,
    referrer: {
        fullName: null,
        referralJoinDate: null,
    }
};

export const referral = (state = initialState, action) => {
    switch (action.type) {
        case SET_REFERRAL_CODE:
            return { ...state, referralCode: action.payload };
        case SET_REFERRAL_PATH:
            return {
                ...state,
                referralPathIds: action.payload.code,
                referrer: {
                    fullName: action.payload.referralFullName,
                    referralJoinDate: action.payload.referralJoinDate,
                }
            };
        case GET_REFERRAL_CODE_REQUEST:
            return { ...state, loading: true };
        case GET_REFERRAL_CODE_SUCCESS:
            return { ...state, loading: false, referralCode: action.payload.referralCode };
        case GET_REFERRAL_CODE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SEND_REFERRAL_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                referralPathIds: action.payload.referralPathIds,
                referrer: {
                    fullName: action.payload.fullName,
                    referralJoinDate: action.payload.referralJoinDate,
                }
            };
        case SEND_REFERRAL_CODE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_REFERRALS_REQUEST:
            return { ...state, loading: true };
        case GET_REFERRALS_SUCCESS:
            return { ...state, loading: false, referrals: action.payload };
        case GET_REFERRALS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_REFERRAL_LEVELS_COUNT_REQUEST:
            return { ...state, loading: true };
        case GET_REFERRAL_LEVELS_COUNT_SUCCESS:
            return { ...state, loading: false, referralLevelsCount: action.payload };
        case GET_REFERRAL_LEVELS_COUNT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
