import React from 'react';
import PageWrapper from 'src/components/PageWrapper';
import { Participate } from './components/Participate';
import { Join } from './components/Join';
// import { ReferralTable } from './components/ReferralTable';
import { Stack } from '@mui/material';
import { ReferralLevelsCountTable } from './components/ReferralLevelsCountTable';
import { AlertComponent } from 'src/components/alert/alert';

export const ReferralPage = () => {
  return (
    <PageWrapper title="Referral">
      <AlertComponent sx={{ mb: { xs: 2, md: 3 } }} severity="info">
        If you bring a user to our platform, you will receive 10% of the total amount of fees paid
        for transactions, and we will offer your newbie friend a 10% discount on commission fees.
        Cool, right? So don't miss the chance to earn and save with our promotion!
      </AlertComponent>
      <Stack spacing={5}>
        <Participate />
        <Join />
        {/*<ReferralTable />*/}
        <ReferralLevelsCountTable />
      </Stack>
    </PageWrapper>
  );
};
