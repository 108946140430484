import { ConnectionType } from './index';

export function getHasMetaMaskExtensionInstalled() {
  if (typeof window !== 'undefined' && 'ethereum' in window) {
    return window.ethereum?.isMetaMask ?? false;
  } else return false;
}

export function getHasCoinbaseExtensionInstalled() {
  if (typeof window !== 'undefined' && 'ethereum' in window) {
    return window.ethereum?.isCoinbaseWallet ?? false;
  } else return false;
}

export function getIsMetaMask(connectionType) {
  return connectionType === ConnectionType.METAMASK && getHasMetaMaskExtensionInstalled();
}

export function getConnection(c) {
  switch (c) {
    case 'METAMASK':
      return ConnectionType.METAMASK;
    case 'TRONLINK':
      return ConnectionType.TRONLINK;
    case 'APTOS_PETRA':
      return ConnectionType.APTOS_PETRA;
    case 'WALLET_CONNECT':
      return ConnectionType.WALLET_CONNECT;
    case 'OKX':
      return ConnectionType.OKX;
    default:
      return ConnectionType.NETWORK;
  }
}
