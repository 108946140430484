import {
  CREATE_INVOICE,
  GET_INVOICES_REQUEST,
  GET_INVOICE_NUMBER,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICES_SUCCESS,
  GET_ADMIN_INVOICES_REQUEST,
  GET_GENERAL_WALLETS_REQUEST,
  GET_GENERAL_WALLETS_SUCCESS,
  GET_ADMIN_INVOICES_SUCCESS,
  INVOICES_EDIT_REQUEST,
  INVOICE_DELETE_REQUEST,
} from 'src/constants/actions';

export const getInvoicesAction = () => ({
  type: GET_INVOICES_REQUEST,
});

export const getInvoicesActionSuccess = (payload) => ({
  type: GET_INVOICES_SUCCESS,
  payload,
});

export const getAdminInvoices = () => ({
  type: GET_ADMIN_INVOICES_REQUEST,
});

export const getAdminInvoicesActionSuccess = (payload) => ({
  type: GET_ADMIN_INVOICES_SUCCESS,
  payload,
});

export const getWalletsAction = () => ({
  type: GET_GENERAL_WALLETS_REQUEST,
});

export const getWalletsActionSuccess = (payload) => ({
  type: GET_GENERAL_WALLETS_SUCCESS,
  payload,
});

export const getAdminByIdActionSuccess = (payload) => ({
  type: 'GET_INVOICE_BY_ID_SUCCESS',
  payload,
});

export const getInvoiceNumberActionSuccess = (payload) => ({
  type: GET_INVOICE_NUMBER_SUCCESS,
  payload,
});

export const getInvoiceNumberAction = () => ({
  type: GET_INVOICE_NUMBER,
});

export const createInvoiceAction = ({ payload, navigate }) => ({
  type: CREATE_INVOICE,
  payload,
  navigate,
});

export const loadingAction = (isLoading) => ({
  type: 'LOADING_ACTION',
  isLoading,
});

export const invoiceEditRequest = ({ payload, navigate }) => ({
  type: INVOICES_EDIT_REQUEST,
  payload,
  navigate,
});

export const invoiceDeleteRequest = (payload) => ({
  type: INVOICE_DELETE_REQUEST,
  payload,
});
