import { makeStyles } from '@material-ui/core/styles';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles({
  stack: {
    width: '100%',
  },
  iconButton: {
    height: '30px',
    width: '30px',
  },
});

function ExportIcon(props) {
  return null;
}

ExportIcon.propTypes = { className: PropTypes.string };

function AddIcon(props) {
  return null;
}

AddIcon.propTypes = { className: PropTypes.string };

export function CustomToolbar({ selectedRows, onDelete, onExport, onAddRow }) {
  const classes = useStyles();

  return (
    <GridToolbarContainer className={classes.toolbar}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={classes.stack}
      >
        <Typography variant="body2">{selectedRows.length} rows selected</Typography>
        <Stack direction="row" spacing={1}>
          {onDelete && (
            <IconButton onClick={onDelete} disabled={selectedRows.length === 0}>
              <DeleteIcon className={classes.iconButton} />
            </IconButton>
          )}
          {onExport && (
            <IconButton onClick={onExport}>
              <ExportIcon className={classes.iconButton} />
            </IconButton>
          )}
          {onAddRow && (
            <IconButton onClick={onAddRow}>
              <AddIcon className={classes.iconButton} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}
