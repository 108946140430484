import { SupportedChainId } from './chains';

export const EXPLORER_BY_NETWORK = {
  [SupportedChainId.POLYGON]: 'https://polygonscan.com/tx/',
  [SupportedChainId.BSC]: 'https://bscscan.com/tx/',
  [SupportedChainId.MAINNET]: 'https://etherscan.io/tx/',
  [SupportedChainId.OPTIMISM]: 'https://optimistic.etherscan.io/tx/',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arbiscan.io/tx/',
  [SupportedChainId.CELO]: 'https://celoscan.io/tx/',
  [SupportedChainId.GODWOKEN]: 'https://v1.gwscan.com/tx/',
  [SupportedChainId.AURORA]: 'https://explorer.aurora.dev/tx/',
  [SupportedChainId.FANTOM]: 'https://ftmscan.com/tx/',
  [SupportedChainId.MOONBEAM]: 'https://moonscan.io/tx/',
  [SupportedChainId.OASIS_EMERALD]: 'https://explorer.emerald.oasis.dev/tx/',
  [SupportedChainId.OASIS_SAPPHIRE]: 'https://explorer.sapphire.oasis.io/tx/',
  [SupportedChainId.FUSE]: 'https://explorer.fuse.io/tx/',
  [SupportedChainId.GNOSIS]: 'https://gnosisscan.io/tx/',
  [SupportedChainId.HARMONY]: 'https://explorer.harmony.one/tx/',
  [SupportedChainId.HECO]: 'https://www.hecoinfo.com/en-us/tx/',
  [SupportedChainId.MOONRIVER]: 'https://moonriver.moonscan.io/tx/',
  [SupportedChainId.OKXCHAIN]: 'https://www.oklink.com/ru/oktc/tx/',
  [SupportedChainId.TELOS]: 'https://www.teloscan.io/tx/',
  [SupportedChainId.BSC_TEST]: 'https://testnet.bscscan.com/tx/',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: 'https://explorer.athens.zetachain.com/evm/tx/',
  // [SupportedChainId.ZETACHAIN]: 'https://explorer.zetachain.com/cc/',
  [SupportedChainId.ZKSYNC]: 'https://explorer.zksync.io/tx/',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: 'https://blockscout.scroll.io/tx/',
  [SupportedChainId.CRONOS]: 'https://cronoscan.com/tx/',
  [SupportedChainId.CRONOS_TESTNET]: 'https://cronos.org/explorer/testnet3/tx/',
  [SupportedChainId.SKALE_EUROPA_TEST]:
    'https://staging-legal-crazy-castor.explorer.staging-v3.skalenodes.com/tx/',
  [SupportedChainId.SKALE_EUROPA]: 'https://elated-tan-skat.explorer.mainnet.skalenodes.com/tx/',
  // [SupportedChainId.SKALE_TESTNET]:
  //   'https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/tx/',
  [SupportedChainId.IOTEX]: 'https://iotexscan.io/tx/',
  [SupportedChainId.IOTEX_TESTNET]: 'https://testnet.iotexscan.io/tx/',
  [SupportedChainId.TRON_GRID_MAINNET]: 'https://tronscan.io/#/transaction/',
  [SupportedChainId.TRON_NILE_TESTNET]: 'https://nile.tronscan.org/#/transaction/',
  [SupportedChainId.APTOS_MAINNET]: 'https://explorer.aptoslabs.com/txn/',
};

export const EXPLORER_BY_NETWORK_ADDRESS = {
  [SupportedChainId.POLYGON]: 'https://polygonscan.com/address/',
  [SupportedChainId.BSC]: 'https://bscscan.com/address/',
  [SupportedChainId.MAINNET]: 'https://etherscan.io/address/',
  [SupportedChainId.OPTIMISM]: 'https://optimistic.etherscan.io/address/',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arbiscan.io/address/',
  [SupportedChainId.CELO]: 'https://celoscan.io/address/',
  [SupportedChainId.GODWOKEN]: 'https://v1.gwscan.com/address/',
  [SupportedChainId.AURORA]: 'https://explorer.aurora.dev/address/',
  [SupportedChainId.FANTOM]: 'https://ftmscan.com/address/',
  [SupportedChainId.MOONBEAM]: 'https://moonscan.io/address/',
  [SupportedChainId.OASIS_EMERALD]: 'https://explorer.emerald.oasis.dev/address/',
  [SupportedChainId.OASIS_SAPPHIRE]: 'https://explorer.sapphire.oasis.io/address/',
  [SupportedChainId.FUSE]: 'https://explorer.fuse.io/address/',
  [SupportedChainId.GNOSIS]: 'https://gnosisscan.io/address/',
  [SupportedChainId.HARMONY]: 'https://explorer.harmony.one/address/',
  [SupportedChainId.HECO]: 'https://www.hecoinfo.com/en-us/address/',
  [SupportedChainId.MOONRIVER]: 'https://moonriver.moonscan.io/address/',
  [SupportedChainId.OKXCHAIN]: 'https://www.oklink.com/ru/oktc/address/',
  [SupportedChainId.TELOS]: 'https://www.teloscan.io/address/',
  [SupportedChainId.BSC_TEST]: 'https://testnet.bscscan.com/address/',
  [SupportedChainId.ZETACHAIN_ATHENS_TESTNET]: 'https://explorer.athens.zetachain.com/evm/address/',
  // [SupportedChainId.ZETACHAIN]: 'https://explorer.zetachain.com/cc/',
  [SupportedChainId.ZKSYNC]: 'https://explorer.zksync.io/address/',
  [SupportedChainId.SCROLL_ALPHA_TESTNET]: 'https://blockscout.scroll.io/address/',
  [SupportedChainId.CRONOS]: 'https://cronoscan.com/address/',
  [SupportedChainId.CRONOS_TESTNET]: 'https://cronos.org/explorer/testnet3/address/',
  [SupportedChainId.SKALE_EUROPA_TEST]:
    'https://staging-legal-crazy-castor.explorer.staging-v3.skalenodes.com/address/',
  [SupportedChainId.SKALE_EUROPA]:
    'https://elated-tan-skat.explorer.mainnet.skalenodes.com/address/',
  // [SupportedChainId.SKALE_TESTNET]:
  //   'https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/address/',
  [SupportedChainId.IOTEX]: 'https://iotexscan.io/address/',
  [SupportedChainId.IOTEX_TESTNET]: 'https://testnet.iotexscan.io/address/',
  [SupportedChainId.TRON_GRID_MAINNET]: 'https://tronscan.io/#/address/',
  [SupportedChainId.TRON_NILE_TESTNET]: 'https://nile.tronscan.org/#/address/',
  [SupportedChainId.APTOS_MAINNET]: 'https://explorer.aptoslabs.com/account/',
};
