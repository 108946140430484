import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Grid, Box, TextField, Button } from '@mui/material';
import ProfileLeftSide from './ProfileLeftSide';
import Divider from '@mui/material/Divider';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Link, useNavigate, useParams } from 'react-router-dom';

import MuiPhoneNumber from 'material-ui-phone-number';

const BlockWrapper = styled(Box)({
  boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
});

export default function EmployeeProfileTab({ user, params }) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    values,
    dirty,
    touched,
    getFieldProps,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      address: user?.address || '',
      registration_number: user?.registration_number || '',
      contact_person: user?.contact_person || '',
      tax_number: user?.tax_number || '',
      position: '',
      phone: user?.phone ? user?.phone : '+380',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(25, 'Maximum length 25 characters').required('Is required'),
      email: Yup.string()
        .email('Email must be a valid email address')
        .required('Email is required'),
      phone: Yup.string().test(
        'isValid',
        'Phone is not valid',
        (val) => val === '+' || val === '+380' || isValidPhoneNumber(val),
      ),
      address: Yup.string().max(45, 'Maximum length 45 characters'),
      tax_number: Yup.number('Must be a number').typeError('Must be a number'),
      registration_number: Yup.number('Must be a number').typeError('Must be a number'),
      contact_person: Yup.string().max(45, 'Maximum length 45 characters').required('Is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.phone === '+' || values.phone === '+380' ? '' : values.phone);
      formData.append('address', values.address ? values.address : '');
      formData.append('tax_number', values.tax_number ? values.tax_number : '');
      formData.append(
        'registration_number',
        values.registration_number ? values.registration_number : '',
      );
      formData.append('contact_person', values.contact_person ? values.contact_person : '');
      formData.append('organization_id', currentUser.organization_id);
      formData.append('id', user?.id);
      dispatch({
        type: 'EDIT_CLIENT_SAGA',
        payload: formData,
        id: user?.id,
        navigate,
        flag: 'edit',
      });
      resetForm({
        values: {
          ...values,
          phone: values.phone === '+' || values.phone === '+380' ? '' : values.phone,
        },
      });
    },
  });

  return (
    <Stack direction="row" justifyContent="space-between">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BlockWrapper
            sx={{
              p: { xs: 1, md: 3 },
              mb: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Company name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  required
                />

                <MuiPhoneNumber
                  size="small"
                  fullWidth
                  variant="outlined"
                  label="Phone Number"
                  data-cy="user-phone"
                  defaultCountry="ua"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />

                <TextField
                  size="small"
                  fullWidth
                  label="Address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Tax number"
                  {...getFieldProps('tax_number')}
                  error={Boolean(touched.tax_number && errors.tax_number)}
                  helperText={touched.tax_number && errors.tax_number}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="Registration number"
                  {...getFieldProps('registration_number')}
                  error={Boolean(touched.registration_number && errors.registration_number)}
                  helperText={touched.registration_number && errors.registration_number}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Contact person"
                  required
                  {...getFieldProps('contact_person')}
                  error={Boolean(touched.contact_person && errors.contact_person)}
                  helperText={touched.contact_person && errors.contact_person}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={'Email'}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  {...getFieldProps('email')}
                  required
                />
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    sx={{ height: '30px' }}
                    disabled={!(isValid && dirty)}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </BlockWrapper>
        </Grid>
      </Grid>
    </Stack>
  );
}
