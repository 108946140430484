import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  Stack,
  TextField,
  Typography,
  Link,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import { validationSchemaForCompany, initialValuesForCompany } from './formikSetting';
import { registerUser } from '../../../redux/actions';
import { SIGN_IN } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';

import { HidePassIcon } from 'src/assets/sign-in/hide-pass';
import { ViewHidePassIcon } from 'src/assets/sign-in/view-hide';

function RegisterForm({ registerUser, value, setValue }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);

  const errorMessage = useSelector(({ AuthUser: { errorMessage } }) => errorMessage);

  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const steps = ['Select campaign settings', 'Create an ad group'];

  const [activeStep, setActiveStep] = React.useState(0);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const formik = useFormik({
    initialValues: initialValuesForCompany,
    validationSchema: validationSchemaForCompany,

    onSubmit: (values) => {
      registerUser(
        {
          name: values.name,
          second_name: values.second_name,
          company_name: values.company,
          email: values.email,
          password: values.confirm_password,
        },
        navigate,
        setValue,
      );
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleChange,
    setFieldValue,
    dirty,
    isValid,
  } = formik;

  const handleNext = () => {
    if (!touched.name || !touched.second_name || !touched.company) {
      errors.name = 'Is required!';
      errors.second_name = 'Is required!';
      errors.company = 'Is required!';
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowSecondPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack
      width="100%"
      sx={{
        fontFamily: 'MullerRegular',
        height: '144.5px',
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {activeStep === steps.length ? (
            <Stack>
              <Typography sx={{ mt: 2, mb: 1, fontFamily: 'MullerRegular' }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset} sx={{ fontFamily: 'MullerRegular' }}>
                  Reset
                </Button>
              </Box>
            </Stack>
          ) : activeStep === 0 ? (
            <Stack>
              <Stack flexDirection="row" mb={2} gap={1}>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={touched.name && errors.name}
                >
                  <InputLabel
                    htmlFor="standard-adornment-name"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      fontFamily: 'MullerRegular',

                      '&.Mui-focused': { color: 'white' },
                    }}
                  >
                    Name
                  </InputLabel>
                  <Input
                    fullWidth
                    required
                    autoFocus
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    variant="standard"
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={touched.second_name && errors.second_name}
                >
                  <InputLabel
                    htmlFor="standard-adornment-surname"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      fontFamily: 'MullerRegular',

                      '&.Mui-focused': { color: 'white' },
                    }}
                  >
                    Surname
                  </InputLabel>
                  <Input
                    fullWidth
                    required
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    {...getFieldProps('second_name')}
                    error={Boolean(touched.second_name && errors.second_name)}
                    helperText={touched.second_name && errors.second_name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    variant="standard"
                  />
                </FormControl>
              </Stack>
              <Stack spacing={2} mb={2}>
                <FormControl
                  variant="standard"
                  required
                  error={
                    Boolean(touched.company && errors.company) ||
                    (errorMessage.includes('Organization') && errorMessage)
                  }
                >
                  <InputLabel
                    htmlFor="standard-adornment-company"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      fontFamily: 'MullerRegular',

                      '&.Mui-focused': { color: 'white' },
                    }}
                    error={
                      Boolean(touched.company && errors.company) ||
                      (errorMessage.includes('Organization') && errorMessage)
                    }
                  >
                    Company
                  </InputLabel>
                  <Input
                    fullWidth
                    required
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    label="Company Name"
                    {...getFieldProps('company')}
                    error={
                      Boolean(touched.company && errors.company) ||
                      (errorMessage.includes('Organization') && errorMessage)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    type="text"
                    variant="standard"
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Enter the name of the company you own or your last name">
                          <InfoIcon
                            sx={{
                              width: 17,
                              height: 17,
                              cursor: 'pointer',
                              color: '#637381',
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  fullWidth
                  sx={{
                    height: '26px',
                    borderRadius: '37.2273px',
                    background: 'rgba(4, 242, 242, 0.4)',
                    border: '1px solid #04F2F2',
                    fontFamily: 'MullerRegular',

                    fontWeight: 400,
                  }}
                  onClick={handleNext}
                  disabled={errors.name || errors.second_name || errors.company}
                >
                  Next
                </Button>
              </Box>
            </Stack>
          ) : (
            <Stack>
              {/* {(errorMessage.includes('Organization') || errorMessage.includes('Admin')) && (
                <Stack mb={2} color="#FF4842" fontSize="12px !important">
                  {errorMessage}
                </Stack>
              )} */}
              <Stack spacing={2} mb={2}>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={
                    Boolean(touched.email && errors.email) ||
                    (errorMessage.includes('Admin') && errorMessage)
                  }
                >
                  <InputLabel
                    htmlFor="standard-adornment-email"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      fontFamily: 'MullerRegular',

                      '&.Mui-focused': { color: 'white' },
                    }}
                    error={
                      Boolean(touched.email && errors.email) ||
                      (errorMessage.includes('Admin') && errorMessage)
                    }
                  >
                    Email
                  </InputLabel>
                  <Input
                    fullWidth
                    required
                    autoFocus
                    type="email"
                    label="Email"
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    {...getFieldProps('email')}
                    error={
                      Boolean(touched.email && errors.email) ||
                      (errorMessage.includes('Admin') && errorMessage)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    variant="standard"
                  />
                </FormControl>
              </Stack>
              <Stack flexDirection="row" mb={2} gap={1}>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={touched.password && errors.password}
                >
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      '&.Mui-focused': { color: 'white' },
                      fontFamily: 'MullerRegular',
                    }}
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Minimum password length 8 characters">
                          <InfoIcon
                            sx={{
                              width: 17,
                              height: 17,
                              cursor: 'pointer',
                              color: '#637381',
                            }}
                          />
                        </Tooltip>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <ViewHidePassIcon /> : <HidePassIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={touched.confirm_password && errors.confirm_password}
                >
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.49)',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      '&.Mui-focused': { color: 'white' },
                      fontFamily: 'MullerRegular',
                    }}
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    fullWidth
                    type={showSecondPassword ? 'text' : 'password'}
                    label="Confirm password"
                    {...getFieldProps('confirm_password')}
                    FormHelperTextProps={{
                      style: { fontSize: '12px !important', fontWeight: 400 },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                    helperText={touched.confirm_password && errors.confirm_password}
                    sx={{
                      label: {
                        color: 'rgba(255, 255, 255, 0.49)',
                        fontSize: '12px !important',
                        fontWeight: 400,
                        '&.Mui-focused': { color: 'white' },
                        fontFamily: 'MullerRegular',
                      },
                      input: {
                        color: 'white',
                        fontSize: '12px !important',
                        fontFamily: 'MullerRegular',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Minimum password length 8 characters">
                          <InfoIcon
                            sx={{
                              width: 17,
                              height: 17,
                              cursor: 'pointer',
                              color: '#637381',
                            }}
                          />
                        </Tooltip>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showSecondPassword ? <ViewHidePassIcon /> : <HidePassIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>

              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    height: '26px',
                    border: '0.729947px solid #FFFFFF',
                    borderRadius: ' 37.2273px',
                    background: 'transparent',
                    padding: '6.5px 33.8476px',
                    fontSize: '10px !important',
                    fontFamily: 'MullerRegular',

                    mr: 1,
                  }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep === steps.length - 1 ? (
                  <Button
                    type="submit"
                    disabled={!isValid}
                    fullWidth
                    sx={{
                      height: '26px',
                      borderRadius: '37.2273px',
                      background: 'rgba(4, 242, 242, 0.4)',
                      fontFamily: 'MullerRegular',

                      border: '1px solid #04F2F2',
                      fontWeight: 400,
                    }}
                  >
                    Create Profile
                  </Button>
                ) : (
                  <Button
                    sx={{ borderRadius: '37.2273px', fontFamily: 'MullerRegular' }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Stack>
          )}
        </Form>
      </FormikProvider>
    </Stack>
  );
}

const mapStateToProps = (state) => ({ state });
const mapActionsToProps = { registerUser };

export default connect(mapStateToProps, mapActionsToProps)(RegisterForm);
