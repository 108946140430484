export const setDataToLocalstorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const updateDataInLocalstorage = (key, newValue) => {
  const existingData = getDataFromLocalstorage(key);

  // Check if we are trying to update a property of an object
  if (typeof existingData === 'object' && existingData !== null && typeof newValue === 'object' && newValue !== null) {
    const updatedData = {
      ...existingData,
      ...newValue,
    };
    const data = JSON.stringify(updatedData);
    setDataToLocalstorage(key, data);
  } else {
    // For primitive data types, simply overwrite the existing data
    setDataToLocalstorage(key, newValue);
  }
};

export const getDataFromLocalstorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {};
};

export const removeDataFromLocalstorage = (key) => {
  localStorage.removeItem(key);
};

export function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}
