import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import PageWrapper from 'src/components/PageWrapper';
import { INVOICE } from 'src/constants/routes';

import {
  OrganizationInfo,
  CustomFormControl,
  WalletInfo,
  SectionLeft,
  SectionRight,
  CustomDate,
} from './components';
import { trimAddress } from '../../helpers/trimAddress';
import CustomBox from '../../components/CustomBox';
import {
  createInvoiceAction,
  getInvoiceNumberAction,
  invoiceEditRequest,
} from '../../redux/invoices/actions';
import CustomDataGrid from '../../components/CustomGrid/CustomDataGrip';
import { detailsInitialData, detailsDataConfig } from './config';
import SubmitButton from './components/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { validationSchema } from './validationSchema';
import ClientModal from './components/ClientModal';
import ReceiveModal from './components/ReceiveModal';
import { convertToFloat } from 'src/commonHelpers/commonHelpers';
import { Loader } from 'src/components/loader/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Memoize components
const MemoOrganizationInfo = React.memo(OrganizationInfo);
const MemoCustomFormControl = React.memo(CustomFormControl);
const MemoWalletInfo = React.memo(WalletInfo);
const MemoCustomDate = React.memo(CustomDate);
const MemoCustomDataGrid = React.memo(CustomDataGrid);
const MemoSubmitButton = React.memo(SubmitButton);

const initialValuesDefault = {
  id: null,
  client: '',
  paymentWallet: '',
  currencyCoin: '',
  customInvoiceNumber: null,
  startDate: new Date(),
  dueDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  details: detailsInitialData,
  amount_with_tax: 0,
  amount_total_tax: 0,
  amount_total: 0,
};

export const NewInvoice = ({ initialValues = initialValuesDefault, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formInitialValues, setFormInitialValues] = useState(initialValuesDefault);

  const user = JSON.parse(localStorage.getItem('currentUser'));

  const organization = useSelector(({ organisations: { organisation } }) => organisation);
  const { clientList: clientsList, isLoading: isLoadingClients } = useSelector(
    ({ employees }) => employees,
  );
  const { wallets: walletList, isLoading: isLoadingWallets } = useSelector(
    ({ invoices }) => invoices,
  );
  const invoiceNumber = useSelector(({ invoices: { invoiceNumber } }) => invoiceNumber);
  const isLoading = useSelector(({ invoices: { isLoading } }) => isLoading);

  useEffect(() => {
    dispatch(getInvoiceNumberAction());
    // dispatch({ type: 'GET_CLIENT_LIST', payload: user.organization_id });
    dispatch({ type: 'GET_CLIENT_LIST_WISH_DELETED', payload: user.organization_id });
    dispatch({ type: 'GET_GENERAL_WALLETS', payload: user.organization_id });
    dispatch({ type: 'GET_ORGANISATION_BY_ID', payload: user?.organization_id });
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setCustomInvoiceNumber(invoiceNumber);
      // setFieldValue('customInvoiceNumber', invoiceNumber);
    }
  }, [invoiceNumber]);

  const [client, setClient] = React.useState(null);
  const [editClient, setEditClient] = React.useState(!initialValues.client);
  const [paymentWallet, setPaymentWallet] = React.useState(null);
  const [editPaymentWallet, setEditPaymentWallet] = React.useState(!initialValues.paymentWallet);
  const [customInvoiceNumber, setCustomInvoiceNumber] = useState(
    initialValues.customInvoiceNumber || null,
  );
  const [isOpenClientModal, setIsOpenClientModal] = useState(false);
  const [isOpenReceiveModal, setIsOpenReceiveModal] = useState(false);

  useEffect(() => {
    const clientValue = isEdit
      ? clientsList.find((client) => client.email === initialValues.client)
      : null;
    const paymentWalletValue = isEdit
      ? walletList.find((wallet) => wallet.wallet_address === initialValues.paymentWallet)
      : null;

    setClient(clientValue);
    setPaymentWallet(paymentWalletValue);
    setFormInitialValues({
      ...initialValues,
      client: clientValue,
      paymentWallet: paymentWalletValue,
    });
  }, [clientsList, walletList]);

  // Use useCallback for handlers
  const handleClientChange = useCallback((newValue) => {
    setClient(newValue);
    setEditClient(false);
  }, []);

  const handlePaymentWalletChange = useCallback((newValue) => {
    setPaymentWallet(newValue);
    setEditPaymentWallet(false);
  }, []);

  const handleStartDateChange = useCallback((newValue, setFieldValue, values) => {
    if (newValue > values.dueDate) {
      setFieldValue('dueDate', newValue);
    }
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      const newValues = values.details.map((item) => {
        const { id, ...restOfItem } = item;
        return {
          invoice_number: initialValues.id,
          ...restOfItem,
          tax: item?.tax ? item?.tax : 0,
          discount: item?.discount ? item?.discount : 0,
        };
      });

      const payload = {
        email: user.email,
        email_client: client.email,
        invoice_number: values.customInvoiceNumber,
        created_date: values.startDate,
        due_date: values.dueDate,
        wallet: values.paymentWallet?.wallet_address,
        wallet_client: values.paymentWallet?.wallet_address,
        amount_with_tax: convertToFloat(values.amount_with_tax),
        amount_total_tax: convertToFloat(values.amount_total_tax),
        amount_total: convertToFloat(values.amount_total),
        invoice_items: newValues,
        blockchain: values.paymentWallet?.network,
        company_name_client: client.name,
        amount_currency: values.currencyCoin,
      };

      if (isEdit) {
        payload.id = initialValues.id;
        dispatch(invoiceEditRequest({ payload, navigate }));
      } else {
        dispatch(
          createInvoiceAction({
            payload,
            navigate,
          }),
        );
      }
    },
    [client, dispatch, navigate, user.email],
  );

  if (isLoadingClients || isLoadingWallets || (isEdit && (!client || !paymentWallet))) {
    return <Loader />;
  }

  // Use memoized components in return statement
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PageWrapper title="New invoice" path={INVOICE}>
        <ClientModal close={() => setIsOpenClientModal(false)} open={isOpenClientModal} />
        <ReceiveModal close={() => setIsOpenReceiveModal(false)} open={isOpenReceiveModal} />
        {isLoading && <Loader />}
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values, isValid }) => {
            return (
              <Form>
                <CustomBox>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SectionLeft titleKey={t('From')}>
                        <MemoOrganizationInfo organization={organization} />
                      </SectionLeft>

                      <SectionLeft
                        titleKey={t('To')}
                        isEdit={editClient}
                        buttonHandler={() => {
                          setFieldValue('client', null);
                          setEditClient(!editClient);
                        }}
                      >
                        {!editClient && !!client ? (
                          <MemoOrganizationInfo organization={client} />
                        ) : (
                          <MemoCustomFormControl
                            name="client"
                            label={t('Find or add new customer')}
                            onChange={handleClientChange}
                            options={clientsList
                              .sort((a, b) =>
                                a.name.localeCompare(b.name, undefined, { numeric: true }),
                              )
                              .map((item) => ({
                                key: item.id,
                                value: item,
                                label: item.id,
                                display: item.name,
                              }))}
                            required={true}
                            additionalCustomButtonLabel="Add new customer"
                            additionalCustomButtonHandler={() => setIsOpenClientModal(true)}
                          />
                        )}
                      </SectionLeft>

                      <SectionLeft
                        titleKey="Choose a wallet"
                        isEdit={editPaymentWallet}
                        buttonHandler={() => {
                          setFieldValue('paymentWallet', null);
                          setEditPaymentWallet(!editPaymentWallet);
                        }}
                      >
                        {!editPaymentWallet ? (
                          <MemoWalletInfo wallet={paymentWallet} />
                        ) : (
                          <MemoCustomFormControl
                            name="paymentWallet"
                            label={t('Find or add new wallet')}
                            onChange={handlePaymentWalletChange}
                            options={walletList.map((item) => ({
                              key: item.id,
                              value: item,
                              label: item.wallet_address,
                              display: `${item.wallet_name} (${item.network}) ${trimAddress(
                                item.wallet_address,
                              )}`,
                            }))}
                            required={true}
                            additionalCustomButtonLabel="Add new wallet"
                            additionalCustomButtonHandler={() => setIsOpenReceiveModal(true)}
                          />
                        )}
                      </SectionLeft>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SectionRight
                        invoiceNumber={customInvoiceNumber}
                        setCustomInvoiceNumber={setCustomInvoiceNumber}
                      >
                        <MemoCustomDate
                          text={'Issued on'}
                          onChange={(newValue) =>
                            handleStartDateChange(newValue, setFieldValue, values)
                          }
                          name="startDate"
                        />
                        <MemoCustomDate
                          text={'Payment due by'}
                          minDate={values.startDate}
                          name="dueDate"
                        />
                      </SectionRight>
                    </Grid>
                  </Grid>

                  <MemoCustomDataGrid fieldConfig={detailsDataConfig} formikArrayName="details" />

                  <MemoSubmitButton
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    text={isEdit ? 'Save' : 'Create'}
                  />
                </CustomBox>
              </Form>
            );
          }}
        </Formik>
      </PageWrapper>
    </LocalizationProvider>
  );
};
