import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../../components/Iconify';

export const detailsInitialData = [
  {
    // id: Math.random(),
    description: '',
    discount: '',
    unit_price: '',
    qty: '',
    tax: '',
    qty_type: 'pc',
    amount: '',
  },
];

export const detailsDataConfig = [
  {
    id: 'field1',
    type: 'text',
    name: 'description',
    label: 'Description',
    value: '',
    required: true,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'desc',
    gridColumnWidth: '3fr',
  },
  {
    id: 'field2',
    type: 'number',
    name: 'qty',
    label: 'Quantity',
    value: '',
    required: true,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'quantity',
    gridColumnWidth: '1fr',
    inputProps: { min: 0 },
  },
  {
    id: 'field3',
    type: 'select',
    name: 'qty_type',
    label: 'Type',
    value: 'pc',
    required: true,
    error: false,
    helperText: '',
    options: [{ value: 'pc' }, { value: 'h' }, { value: 'd' }, { value: 'wk' }, { value: 'mo' }],
    gridArea: 'type',
    gridColumnWidth: '1fr',
  },
  {
    id: 'field4',
    type: 'number',
    name: 'unit_price',
    label: 'Price',
    value: '',
    required: true,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'price',
    gridColumnWidth: '1fr',
    inputProps: { min: 0, step: 0.0000000001 },
  },
  {
    id: 'field5',
    type: 'number',
    name: 'discount',
    label: 'Discount',
    value: '',
    required: false,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'discount',
    gridColumnWidth: '1fr',
    inputProps: { min: 0, max: 100 },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <Iconify icon="eva:percent-outline" />
        </InputAdornment>
      ),
    },
  },
  {
    id: 'field6',
    type: 'qty',
    name: 'amount',
    label: 'Net amount',
    value: '',
    required: false,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'net',
    gridColumnWidth: '1fr',
    disabled: true,
  },
  {
    id: 'field7',
    type: 'number',
    name: 'tax',
    label: 'Taxes',
    value: '',
    required: false,
    error: false,
    helperText: '',
    options: [],
    gridArea: 'taxes',
    gridColumnWidth: '1fr',
    inputProps: { min: 0, max: 1000 },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <Iconify icon="eva:percent-outline" />
        </InputAdornment>
      ),
    },
  },
];
