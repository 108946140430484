// ----------------------------------------------------------------------

export default function Popover() {
  return {
    MuiPopover: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiModal: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        backdrop: {
          background: 'linear-gradient(75deg, rgba(33, 33, 33, 0.48) 0%, rgb(33, 33, 33) 100%)',
        },
      },
    },
  };
}
