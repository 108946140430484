import React from 'react';
import { Stack, Typography } from '@mui/material';

const Header = ({ gridArea, label }) => {
  return (
    <Stack gridArea={gridArea}>
      <Typography fontSize="body2">{label}</Typography>
    </Stack>
  );
};

export default Header;
