import React from 'react';

export const ViewHidePassIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0069 12.1592C15.1045 11.8299 15.1569 11.4813 15.1569 11.1204C15.1569 9.10468 13.5228 7.47064 11.5072 7.47064C11.1463 7.47064 10.7976 7.52302 10.4684 7.6206L15.0069 12.1592ZM8.62798 8.87713C8.14512 9.49601 7.85742 10.2746 7.85742 11.1204C7.85742 13.1361 9.49146 14.7701 11.5072 14.7701C12.3529 14.7701 13.1315 14.4824 13.7504 13.9995L8.62798 8.87713Z"
        fill="#01CFD4"
      />
      <path
        d="M19.1328 10.3384C19.4135 10.6889 19.5539 10.8641 19.5539 11.1204C19.5539 11.3767 19.4135 11.5519 19.1328 11.9024C17.9213 13.4152 14.9636 16.595 11.5072 16.595C8.05073 16.595 5.09306 13.4152 3.88149 11.9024C3.6008 11.5519 3.46045 11.3766 3.46045 11.1204C3.46045 10.8641 3.6008 10.6889 3.88149 10.3384C5.09306 8.82555 8.05073 5.64578 11.5072 5.64578C14.9636 5.64578 17.9213 8.82555 19.1328 10.3384Z"
        fill="#0D7D87"
      />
      <path
        d="M15.1569 11.1204C15.1569 13.1361 13.5228 14.7701 11.5072 14.7701C9.49146 14.7701 7.85742 13.1361 7.85742 11.1204C7.85742 9.10468 9.49146 7.47064 11.5072 7.47064C13.5228 7.47064 15.1569 9.10468 15.1569 11.1204Z"
        fill="#01CFD4"
      />
    </svg>
  );
};
