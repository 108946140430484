import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_POLYGON_ID,
  IS_AUTH,
  IS_AUTH_SUCCESS,
  IS_AUTH_ERROR,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  LOGIN_COMPANY,
  LOGIN_COMPANY_SUCCESS,
  LOGOUT_USER,
  GET_BALANCE,
  GOOGLE_LOGIN,
  GET_QRCODE_LINK_REQUEST,
  GET_QRCODE_LINK_SUCCESS,
  GET_QRCODE_LINK_FAILURE,
  GET_SIGN_IN_POLYGON_ID_REQUEST,
  GET_SIGN_IN_POLYGON_ID_SUCCESS,
  GET_SIGN_IN_POLYGON_ID_FAILURE,
  SET_AUTH_STATUS_POLYGON_ID,
  REGISTER_USER_POLYGON_ID,
  GET_AUTH_STATUS_POLYGON_ID_REQUEST,
  LOGIN_USER_TEAM,
} from '../../constants/actions';

export const loginUser = (user, navigate) => ({
  type: LOGIN_USER,
  payload: user,
  navigate,
});
export const loginUserTeam = (organizationId, navigate) => ({
  type: LOGIN_USER_TEAM,
  payload: organizationId,
  navigate,
});
export const loginUserPolygonId = (sessionId, navigate) => ({
  type: LOGIN_USER_POLYGON_ID,
  payload: sessionId,
  navigate,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = (msg) => ({
  type: LOGIN_USER_ERROR,
  payload: msg,
});

export const isAuth = () => ({
  type: IS_AUTH,
});

export const isAuthSuccess = () => ({
  type: IS_AUTH_SUCCESS,
});

export const isAuthError = () => ({
  type: IS_AUTH_ERROR,
});

export const registerUser = (payload, navigate, setValue) => ({
  type: REGISTER_USER,
  payload,
  navigate,
  setValue,
});

export const registerUserPolygonId = (payload, navigate) => ({
  type: REGISTER_USER_POLYGON_ID,
  payload,
  navigate,
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const logoutUserSuccess = () => ({
  type: 'LOGOUT_USER_SUCCESS',
});

export const loginCompany = (payload) => ({
  type: LOGIN_COMPANY,
  payload,
});

export const loginCompanySuccess = (payload) => ({
  type: LOGIN_COMPANY_SUCCESS,
  payload,
});

export const setUserBalance = (payload) => ({
  type: GET_BALANCE,
  payload,
});

export const setErrorMessage = (payload) => ({
  type: 'SET_ERROR_MESSAGE',
  payload,
});

export const googleLoginAction = (payload, navigate) => ({
  type: GOOGLE_LOGIN,
  payload,
  navigate,
});

export const getQrCodeLinkRequest = () => ({
  type: GET_QRCODE_LINK_REQUEST,
});

export const getQrCodeLinkSuccess = (qrCodeLink) => ({
  type: GET_QRCODE_LINK_SUCCESS,
  payload: qrCodeLink,
});

export const getQrCodeLinkFailure = (error) => ({
  type: GET_QRCODE_LINK_FAILURE,
  payload: error,
});

export const getSignInPolygonIdRequest = () => ({
  type: GET_SIGN_IN_POLYGON_ID_REQUEST,
});

export const getSignInPolygonIdSuccess = (data) => ({
  type: GET_SIGN_IN_POLYGON_ID_SUCCESS,
  payload: data,
});

export const getSignInPolygonIdFailure = (error) => ({
  type: GET_SIGN_IN_POLYGON_ID_FAILURE,
  payload: error,
});

export const getAuthStatusPolygonIdRequest = (payload) => ({
  type: GET_AUTH_STATUS_POLYGON_ID_REQUEST,
  payload: payload,
});

export const setAuthStatusPolygonId = (authStatus) => ({
  type: SET_AUTH_STATUS_POLYGON_ID,
  payload: authStatus,
});
