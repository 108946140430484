import React from 'react';
import { NumberInput } from './fields/NumberInput';
import { TextInput } from './fields/TextInput';
import { SelectInput } from './fields/SelectInput';
import { CustomInput } from './fields/CustomInput';
import { IdField } from './fields/IdField';
import { QtyField } from './fields/QtyField';
import { DeleteField } from './fields/DeleteField';

const MemoizedNumberInput = React.memo(NumberInput);
const MemoizedTextInput = React.memo(TextInput);
const MemoizedSelectInput = React.memo(SelectInput);
const MemoizedCustomInput = React.memo(CustomInput);
const MemoizedIdField = React.memo(IdField);
const MemoizedQtyField = React.memo(QtyField);
const MemoizedDeleteField = React.memo(DeleteField);

export const GenericInput = (props) => {
  const { type } = props;

  switch (type) {
    case 'number':
      return <MemoizedNumberInput {...props} />;
    case 'text':
      return <MemoizedTextInput {...props} />;
    case 'select':
      return <MemoizedSelectInput {...props} />;
    case 'custom':
      return <MemoizedCustomInput {...props} />;
    case 'id':
      return <MemoizedIdField {...props} />;
    case 'qty':
      return <MemoizedQtyField {...props} />;
    case 'delete':
      return <MemoizedDeleteField {...props} />;
    default:
      return null;
  }
};
