import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button, Menu, MenuItem, Stack, Tooltip } from '@mui/material';

const convertToCorrectStructure = (data, storageName) => {
  switch (storageName) {
    default:
      return data.map(({ name, surname, position, network, amount, wallet, notes }) => ({
        name,
        surname,
        position,
        wallet,
        network,
        amount,
        notes,
      }));
  }
};

export const ExportFile = ({ data, storageKey, sx = { ml: 2 } }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (format) => {
    const filteredData = convertToCorrectStructure(data, storageKey);

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const fileName = `data.${format}`;
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, fileName);
    handleClose();
  };

  const convertToCSV = () => {
    const csvRows = [];
    const filteredData = convertToCorrectStructure(data, storageKey);
    console.log(data);

    const headers = Object.keys(filteredData[0]);
    csvRows.push(headers.join(','));

    for (const row of filteredData) {
      const values = headers.map((header) => {
        const escapedValue = row[header].toString().replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const handleDownloadCSV = () => {
    const csvData = convertToCSV();
    const fileName = 'data.csv';
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack flexDirection="row" justifyContent="space-between" gap={1}>
      <Tooltip arrow title="You can export the list of transactions" placement="top">
        <span>
          <Button
            sx={sx}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disabled={!data || !data.length || data === undefined}
            variant="contained"
          >
            Export
          </Button>
        </span>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleDownload('xlsx')}>XLSX</MenuItem>
        <MenuItem onClick={() => handleDownload('numbers')}>Numbers</MenuItem>
        <MenuItem onClick={handleDownloadCSV}>CSV</MenuItem>
      </Menu>
    </Stack>
  );
};
