import { WalletContext } from '../context/index';
import { useContext } from 'react';

export function useWeb3() {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }

  return context;
}
