import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { getTeams, addTeam, deleteTeam, editTeam } from '../../services/teams';
import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  EDIT_TEAM_REQUEST,
  EDIT_TEAM_SUCCESS,
} from '../../constants/actions';
import { toast } from 'react-toastify';

function* sagaGetTeamsWorker() {
  try {
    const response = yield call(getTeams);
    if (response.status === 200) {
      yield put({ type: GET_TEAMS_SUCCESS, payload: response.data });
    }
  } catch (e) {
    const errorMessage =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : e.message;
    toast.error(errorMessage);
  }
}

function* sagaAddTeamWorker(action) {
  try {
    const response = yield call(addTeam, action.payload);
    if (response.status === 201) {
      yield put({ type: ADD_TEAM_SUCCESS, payload: response.data });
    }
    toast.success('Successfully added!');
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* sagaDeleteTeamWorker(action) {
  try {
    const response = yield call(deleteTeam, action.payload);
    if (response.status === 200) {
      yield call(sagaGetTeamsWorker);
      yield put({ type: DELETE_TEAM_SUCCESS, payload: action.payload });
    }
    toast.success('Successfully deleted!');
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* sagaEditTeamWorker(action) {
  try {
    const response = yield call(editTeam, action.payload);
    if (response.status === 200) {
      yield put({ type: EDIT_TEAM_SUCCESS, payload: response.data });
    }
    toast.success('Successfully uploaded!');
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export function* sagaGetTeamsWatcher() {
  yield takeEvery(GET_TEAMS_REQUEST, sagaGetTeamsWorker);
}

export function* sagaAddTeamWatcher() {
  yield takeEvery(ADD_TEAM_REQUEST, sagaAddTeamWorker);
}

export function* sagaDeleteTeamWatcher() {
  yield takeEvery(DELETE_TEAM_REQUEST, sagaDeleteTeamWorker);
}

export function* sagaEditTeamWatcher() {
  yield takeEvery(EDIT_TEAM_REQUEST, sagaEditTeamWorker);
}

export default function* rootSaga() {
  yield all([
    fork(sagaGetTeamsWatcher),
    fork(sagaAddTeamWatcher),
    fork(sagaDeleteTeamWatcher),
    fork(sagaEditTeamWatcher),
  ]);
}
