import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { CustomTableHead } from './TableHead';
import { CustomTableToolbar } from './TableToolbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array && array.length) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
}

export default function EnhancedTable({
  data,
  setSelectedRows,
  selectedRows,
  setTableData,
  headCells,
  handleSaveEditRow,
  deleteHandler,
  exportFile,
  emptyStatusText,
  RowComponent,
  addRowTemplate,
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const loaderState = useSelector(({ loader }) => loader);

  const someIsEditing = data && data.length && data.some(({ isEdit }) => isEdit);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortById = (data) => {
    if (data && data.length) {
      return data.sort((a, b) => b.name - a.name);
    }
    return data;
  };

  const handleSelectAllClick = (event) => {
    if (data && data.length && data.slice) {
      if (event.target.checked && selectedRows.length === 0) {
        setSelectedRows(data);
        return;
      }
    }

    setSelectedRows([]);
  };

  const handleClick = (event, row, rowId) => {
    const selectedIndex = selectedRows.map((item) => item.id).indexOf(rowId);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelected);
  };

  const isSelected = (rowId) => {
    return selectedRows.map(({ id }) => id).indexOf(rowId) !== -1;
  };

  const emptyRows = data && data.length ? false : true;

  const handleEditRow = (id) => {
    const isSelected = selectedRows.some((item) => item.id === id);
    if (isSelected) {
      const result = selectedRows.filter((item) => item.id !== id);
      setSelectedRows(result);
    }
    const updatedData = data.map((item) => (item.id === id ? { ...item, isEdit: true } : item));
    setTableData(updatedData);
  };

  const handleCancelEditRow = (id) => {
    const currentItem = data.find((item) => item.id === id);

    if (currentItem.isNew) {
      setTableData(data.filter((item) => item.id !== id));
      return;
    }
    const updatedData = data.map((item) => (item.id === id ? { ...item, isEdit: false } : item));
    setTableData(updatedData);
  };

  return (
    <>
      <CustomTableToolbar
        setTableData={setTableData}
        data={data}
        selected={selectedRows}
        numSelected={selectedRows?.length}
        setSelectedRows={setSelectedRows}
        deleteHandler={deleteHandler}
        exportFile={exportFile}
        addRowTemplate={addRowTemplate}
      />

      <TableContainer
        sx={{
          height: '55vh',
          borderRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          width: 'auto',
        }}
      >
        <Table
          sx={{ minWidth: 750, borderRadius: '8px' }}
          aria-labelledby="tableTitle"
          size={'medium'}
          stickyHeader
        >
          <CustomTableHead
            numSelected={selectedRows?.length}
            order={order}
            data={data}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data?.length}
            loader={loaderState}
            someIsEditing={someIsEditing}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(sortById(data), getComparator(order, orderBy)).map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <React.Fragment key={row.id}>
                  <RowComponent
                    loaderState={loaderState}
                    handleClick={handleClick}
                    handleEditRow={handleEditRow}
                    row={row}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    handleCancelEditRow={handleCancelEditRow}
                    handleSaveEditRow={handleSaveEditRow}
                    data={data}
                  />
                </React.Fragment>
              );
            })}
          </TableBody>
          {emptyRows && (
            <TableBody>
              <TableRow sx={{ height: '48vh' }}>
                <TableCell colSpan={8} sx={{ border: 'none' }}>
                  <Stack sx={{ width: '100%' }} textAlign="center" spacing={2}>
                    {emptyStatusText}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
