export const getOrganisationListRequest = (isLoading) => ({
  type: 'GET_ORGANISATION_LIST_REQUEST',
  payload: { isLoading },
});

export const getOrganisationListSuccess = (organisationList, isLoading) => ({
  type: 'GET_ORGANISATION_LIST_SUCCESS',
  payload: {
    organisationList,
    isLoading,
  },
});
export const getOrganisationByIdRequest = (isLoading) => ({
  type: 'GET_ORGANISATION_BY_ID_REQUEST',
  payload: { isLoading },
});

export const getOrganisationByIdSuccess = (organisation, isLoading) => ({
  type: 'GET_ORGANISATION_BY_ID_SUCCESS',
  payload: {
    organisation,
    isLoading,
  },
});
export const getOrganisationEditRequest = (isLoading) => ({
  type: 'GET_ORGANISATION_EDIT_REQUEST',
  payload: { isLoading },
});

export const setIsLoading = (isLoading) => ({
  type: 'SET_LOADING',
  isLoading ,
});

export const getOrganisationEditSuccess = (organisation, isLoading) => ({
  type: 'GET_ORGANISATION_EDIT_SUCCESS',
  payload: {
    organisation,
    isLoading,
  },
});
